import { z } from 'zod';

const textSchema = z
  .union([
    z
      .object({
        type: z.enum(['text']),
        value: z.string(),
        hideContext: z.boolean().optional(),
        hideStatement: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        type: z.enum(['image']),
        value: z.string(),
        hideContext: z.boolean().optional(),
        hideStatement: z.boolean().optional(),
      })
      .strict(),
    z
      .object({
        type: z.enum(['tikz']),
        value: z.string(),
        hideContext: z.boolean().optional(),
        hideStatement: z.boolean().optional(),
        packages: z.string().array().optional(),
      })
      .strict(),
  ])
  .array();

const tableInputSchema = z
  .object({
    type: z.enum(['table']),
    defaultValues: z.union([z.string(), z.null()]).array().array(),
    header: z
      .object({ isPresent: z.boolean(), editable: z.boolean() })
      .strict(),
    lineDescription: z
      .object({ isPresent: z.boolean(), editable: z.boolean() })
      .strict(),
    addColumn: z.boolean(),
    addLine: z.boolean(),
  })
  .strict();
export type TableInput = z.infer<typeof tableInputSchema>;

const formulaInputSchema = z
  .object({
    type: z.enum(['formula']),
    symbols: z.string().array().optional(),
    placeholder: z.string().optional(),
  })
  .strict();
export type FormulaInput = z.infer<typeof formulaInputSchema>;

const defaultSolutionsSchema = z.object({
  solutions: z
    .object({
      steps: z
        .object({ method: z.string(), solution: z.string() })
        .strict()
        .array(),
      answers: z.string().array(),
    })
    .strict()
    .array(),
});

const baseQuestionSchema = z
  .object({
    type: z.literal('question'),
    text: textSchema,
    placeholder: z.string().optional(),
    input: z.union([tableInputSchema, formulaInputSchema]).optional(),
    instructions: z.string().optional(),
    notionLevels: z.record(
      z.string(),
      z.object({ level: z.number(), mustSee: z.boolean().optional() }).strict(),
    ),
    next: z.string().optional(),
  })
  .strict();

const questionSchema = baseQuestionSchema
  .merge(z.object({ solutionType: z.undefined() }))
  .merge(defaultSolutionsSchema)
  .strict();

export type QuestionStatement = z.infer<typeof questionSchema>;

const baseStatementSchema = z.union([
  z
    .object({
      type: z.enum(['statement']),
      text: textSchema,
    })
    .strict(),
  questionSchema,
]);

export type Statement =
  | z.infer<typeof baseStatementSchema>
  | { [key: string]: Statement | undefined };

export const statementSchema: z.ZodType = z.union([
  baseStatementSchema,
  z.record(
    z.string(),
    z.lazy(() => statementSchema.optional()),
  ),
]);
export type ProblemStatement = {
  first: string;
  statement: Record<string, Statement | undefined>;
};

export const problemStatementSchema = z.object({
  first: z.string(),
  statement: z.record(z.string(), statementSchema.optional()),
});
