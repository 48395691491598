import {
  NEXT_PROBLEM_FR,
  NEXT_QUESTION_FR,
  NotionLevel,
} from '@skloover/shared';

export const sessionMessages = {
  session: {
    problem: 'Exercice',
    statement: 'Énoncé',
    hints: 'Indices',
    nextQuestion: NEXT_QUESTION_FR,
    nextProblem: NEXT_PROBLEM_FR,
    difficulty: {
      [NotionLevel.Easy]: 'facile',
      [NotionLevel.Medium]: 'intermédiaire',
      [NotionLevel.Hard]: 'expert',
    },
    clearChat: 'Recommencer',
    validateProblem: 'Valider',
    copyPaste: 'Copier / coller :',
    answerSuccess: {
      label: 'Niveau',
      good: "Super ! Voici les notions du cours que l'on vient d'aborder :",
      medium:
        "Bien joué, tu as réussi avec mon aide. Voici ce qu'il faut retenir :",
      low: "Voici les notions du cours qu'il faut retenir :",
    },
    lessons: 'Cours',
    type: {
      definition: 'Définition',
      property: 'Propriété',
      example: 'Exemple',
      additionalNote: 'À noter',
    },
    showLesson: 'Rappel du cours',
    newLine: 'Nouvelle ligne',
    chat: 'Messagerie',
    formula: 'Formule mathématique',
    table: 'Tableau',
    enableShowAnswer:
      'Tente une réponse ou pose une question avant de voir la solution !',
  },
};
