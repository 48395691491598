/* eslint-disable max-lines */
export const equations_inequations = {
  equation: [],
  inequation: [],
  sign_table: [],
  linear_sign: [],
  inequation_equivalence: [],
  product: [],
  quotient: [],
} as const;
