/* eslint-disable max-lines */
export const FRACTION = '<fraction>';
export const EXPOSANT = '<exposant>';
export const INDEX = '<index>';
export const COSINUS = '<cosinus>';
export const SINUS = '<sinus>';
export const TANGENT = '<tangent>';
export const LOGARITHM = '<logarithm>';
export const SQUARE_ROOT = '<square-root>';
export const EXPONENTIAL = '<exponential>';
export const BRACKETS = '<brackets>';
export const PARENTHESIS = '<parenthesis>';
export const BASIC = '<basic>';
export const OVERLINE = '<overline>';
export const TEXT = '<text>';
export const COORDINATES = '<coordinates>';
export const VECTOR = '<vector>';
export const DETERMINANT = '<determinant>';

export const FUNCTION_TYPES = [
  COSINUS,
  SINUS,
  TANGENT,
  LOGARITHM,
  EXPONENTIAL,
  DETERMINANT,
] as const;

export type FunctionType = (typeof FUNCTION_TYPES)[number];

export type FormulaSymbol = { symbol: string; isText?: boolean };

export type MainFormula = {
  mainFormula: { symbols: FormulaSymbol[] };
};
export type Formula = {
  parentFormula: MainFormula;
  childFormula?: ChildFormula;
};
export type FractionFormula = {
  type: typeof FRACTION;
  upFormula: Formula;
  downFormula: Formula;
};
export type ExposantFormula = {
  type: typeof EXPOSANT;
  leftFormula: Formula;
  rightFormula: Formula;
};
export type IndexFormula = {
  type: typeof INDEX;
  leftFormula: Formula;
  rightFormula: Formula;
};
export type FunctionFormula<T extends FunctionType = FunctionType> = {
  type: T;
  inFormula: Formula;
};
export type IndexFormulaFormula = {
  type: typeof INDEX;
  leftFormula: Formula;
  rightFormula: Formula;
};
export type ParenthesisFormula = {
  type: typeof PARENTHESIS;
  inFormula: Formula;
};
export type SquareRootFormula = {
  type: typeof SQUARE_ROOT;
  inFormula: Formula;
};
export type BracketsFormula = {
  type: typeof BRACKETS;
  inFormula: Formula;
};
export type OverlineFormula = {
  type: typeof OVERLINE;
  inFormula: Formula;
};
export type TextFormula = {
  type: typeof TEXT;
  inFormula: Formula;
};
export type CoordinatesFormula = {
  type: typeof COORDINATES;
  upFormula: Formula;
  downFormula: Formula;
};
export type VectorFormula = {
  type: typeof VECTOR;
  inFormula: Formula;
};
export type DeterminantFormula = {
  type: typeof DETERMINANT;
  inFormula: Formula;
};
export type ChildFormula =
  | FractionFormula
  | ExposantFormula
  | IndexFormula
  | FunctionFormula
  | SquareRootFormula
  | IndexFormulaFormula
  | ParenthesisFormula
  | BracketsFormula
  | OverlineFormula
  | TextFormula
  | CoordinatesFormula
  | VectorFormula
  | DeterminantFormula;
export type FormulaType = Required<Formula>['childFormula']['type'];
export type ObjectFormula = { formulas: Formula[] };

export const EMPTY_SYMBOLS = { symbols: [] };

export const EMPTY_MAIN_FORMULA: MainFormula = {
  mainFormula: EMPTY_SYMBOLS,
};
export const EMPTY_FORMULA: Formula = {
  parentFormula: EMPTY_MAIN_FORMULA,
};
export const EMPTY_FRACTION_FORMULA: FractionFormula = {
  type: FRACTION,
  upFormula: EMPTY_FORMULA,
  downFormula: EMPTY_FORMULA,
};
export const EMPTY_EXPOSANT_FORMULA: ExposantFormula = {
  type: EXPOSANT,
  leftFormula: EMPTY_FORMULA,
  rightFormula: EMPTY_FORMULA,
};
export const EMPTY_INDEX_FORMULA: IndexFormula = {
  type: INDEX,
  leftFormula: EMPTY_FORMULA,
  rightFormula: EMPTY_FORMULA,
};
export const EMPTY_FUNCTION_FORMULA = (
  functionType: FunctionType,
): FunctionFormula => ({
  type: functionType,
  inFormula: EMPTY_FORMULA,
});
export const EMPTY_SQUARE_ROOT_FORMULA: SquareRootFormula = {
  type: SQUARE_ROOT,
  inFormula: EMPTY_FORMULA,
};
export const EMPTY_PARENTHESIS_FORMULA: ParenthesisFormula = {
  type: PARENTHESIS,
  inFormula: EMPTY_FORMULA,
};
export const EMPTY_BRACKETS_FORMULA: BracketsFormula = {
  type: BRACKETS,
  inFormula: EMPTY_FORMULA,
};
export const EMPTY_OVERLINE_FORMULA: OverlineFormula = {
  type: OVERLINE,
  inFormula: EMPTY_FORMULA,
};
export const EMPTY_TEXT_FORMULA: TextFormula = {
  type: TEXT,
  inFormula: EMPTY_FORMULA,
};
export const EMPTY_COORDINATES_FORMULA: CoordinatesFormula = {
  type: COORDINATES,
  upFormula: EMPTY_FORMULA,
  downFormula: EMPTY_FORMULA,
};
export const EMPTY_VECTOR_FORMULA: VectorFormula = {
  type: VECTOR,
  inFormula: EMPTY_FORMULA,
};
export const EMPTY_DETERMINANT_FORMULA: DeterminantFormula = {
  type: DETERMINANT,
  inFormula: EMPTY_FORMULA,
};

export type Path = string[];

export const selectedElementClassName =
  'row-span-1 p-1 flex items-center justify-around border-solid border rounded';

export type SymbolAction = {
  symbol?: string;
  onClick: () => void;
  linesSpread?: number;
};

export type Action = {
  symbolAction: SymbolAction;
  lineSpread?: number;
  className?: string;
  id?: string;
  disabled?: boolean;
};

export const getFunctionSymbol = (functionType: FunctionType): string => {
  switch (functionType) {
    case COSINUS:
      return '\\cos';
    case SINUS:
      return '\\sin';
    case TANGENT:
      return '\\tan';
    case LOGARITHM:
      return '\\ln';
    case EXPONENTIAL:
      return '\\exp';
    case DETERMINANT:
      return '\\det';
  }
};

export const isFunctionType = (type?: string): type is FunctionType =>
  (FUNCTION_TYPES as unknown as (string | undefined)[]).includes(type);
