/* eslint-disable max-lines */
export const numerical_functions = {
  vocabulary: [],
  graphical_representation: [],
  parity: [],
  square_function: [],
  inverse_function: [],
  cubic_function: [],
  square_root_function: [],
  inequality: [],
  relative_position: [],
} as const;
