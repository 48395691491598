import compact from 'lodash/compact';

import { ChapterAsObject, NotionAsObject } from '../types';
import { flatten } from '../utils/flatten';
import { CHAPTERS, NOTIONS } from './chapters';

export const CHAPTERS_AS_OBJECTS: ChapterAsObject[] = flatten(CHAPTERS, [
  'country',
  'program',
  'division',
  'subject',
  'chapter',
] as const);

export const NOTIONS_AS_OBJECTS: NotionAsObject[] = flatten(NOTIONS, [
  'country',
  'program',
  'division',
  'subject',
  'chapter',
  'notion',
] as const);

export const notions = compact(NOTIONS_AS_OBJECTS.map(({ notion }) => notion));

export type ChapterNotion = (typeof notions)[0];

export const getChapterAsObjectNotions = (
  chapterAsObject: ChapterAsObject,
): ChapterNotion[] => {
  const { country, program, subject, chapter } = chapterAsObject;

  return NOTIONS_AS_OBJECTS.filter(
    notionAsObject =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      country === notionAsObject.country &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      program === notionAsObject.program &&
      // division === notionAsObject.division &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      subject === notionAsObject.subject &&
      chapter === notionAsObject.chapter,
  ).map(({ notion }) => notion);
};
