import { cloneElement, ReactElement } from 'react';

import { useBreakpoint } from '~/lib';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
  TooltipProvider,
} from '../ui';

interface SessionLayoutProps {
  problem: ReactElement;
  chat: ReactElement;
}

const DEFAULT_LAYOUT = [50, 50];

export const SessionLayout = ({
  problem,
  chat,
}: SessionLayoutProps): ReactElement => {
  const breakpoint = useBreakpoint();
  const breakpointValue = breakpoint === 'sm' ? 'md' : breakpoint;

  return (
    <>
      <Carousel className="w-full max-w-xs md:hidden grow min-h-0">
        <CarouselContent className="h-full">
          <CarouselItem>
            {cloneElement(problem, {
              breakpoint: 'sm',
            })}
          </CarouselItem>
          <CarouselItem>
            {cloneElement(chat, {
              breakpoint: 'sm',
            })}
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div className="hidden md:block grow min-h-0">
        <TooltipProvider delayDuration={0}>
          <ResizablePanelGroup
            direction="horizontal"
            onLayout={(sizes: number[]) => {
              document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                sizes,
              )}`;
            }}
            className="h-full items-stretch border rounded"
          >
            <ResizablePanel defaultSize={DEFAULT_LAYOUT[0]} minSize={30}>
              {cloneElement(problem, {
                breakpoint: breakpointValue,
              })}
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={DEFAULT_LAYOUT[1]} minSize={30}>
              {cloneElement(chat, { breakpoint: breakpointValue })}
            </ResizablePanel>
          </ResizablePanelGroup>
        </TooltipProvider>
      </div>
    </>
  );
};
