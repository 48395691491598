import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';

import {
  ChapterAsObject,
  ChatHistory,
  getMaxNotionLevel,
  Statement as StatementType,
} from '@skloover/shared';

import { Breakpoint, trpc } from '~/lib';

import { Badge, Separator } from '../ui';
import { AnswersAccordion } from './AnswersAccordion';
import { ChatActions } from './ChatActions';
import { Messages } from './Messages';

interface ChatProps {
  chat?: ChatHistory;
  setChat: (chat: ChatHistory) => void;
  sessionId: string;
  problemId: string;
  chapterAsObject: ChapterAsObject;
  isLastQuestion: boolean;
  questionStatement: Extract<StatementType, { type: 'question' }>;
  onProblemTabChange: (tab: string) => void;
  resetBlurred: () => void;
  breakpoint: Breakpoint;
}

const formSchema = z.object({
  message: z.string(),
});

export const Chat = ({
  chat = [],
  setChat,
  sessionId,
  problemId,
  chapterAsObject,
  isLastQuestion,
  questionStatement,
  onProblemTabChange,
  resetBlurred: resetBlurred,
  breakpoint,
}: ChatProps): ReactElement => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  });

  const { mutate: chatUpdate, isPending: isChatUpdatePending } =
    trpc.chatUpdate.useMutation({
      onSuccess: ({ message }) => {
        setChat(chat.concat([['ai', message]]));
      },
      onError: async () => {
        await utils.problemSolvingGet.invalidate(sessionId);
      },
    });
  const utils = trpc.useUtils();

  const submit = ({ message }: z.infer<typeof formSchema>) => {
    setChat(chat.concat([['human', message]]));
    chatUpdate({ message, sessionId, problemId });
    form.reset();
  };

  const level = getMaxNotionLevel(questionStatement);

  return (
    <div className="flex h-full flex-col relative">
      <Badge className="absolute top-4 right-4">
        <FormattedMessage id={`session.difficulty.${level}`} />
      </Badge>
      <Messages chat={chat} />
      <Separator className="mt-auto" />
      <div className="flex flex-col pt-0 pb-2 px-4 md:px-2 gap-2">
        <AnswersAccordion
          chatHistory={chat}
          chapterAsObject={chapterAsObject}
          questionStatement={questionStatement}
          submit={submit}
          isChatUpdatePending={isChatUpdatePending}
          breakpoint={breakpoint}
        />
        <ChatActions
          isChatUpdatePending={isChatUpdatePending}
          isChatDefined={chat.length > 0}
          sessionId={sessionId}
          problemId={problemId}
          isLastQuestion={isLastQuestion}
          questionStatement={questionStatement}
          submit={submit}
          onProblemTabChange={onProblemTabChange}
          resetBlurred={resetBlurred}
          breakpoint={breakpoint}
        />
      </div>
    </div>
  );
};
