import get from 'lodash/get';
import set from 'lodash/set';
import { CornerDownLeft } from 'lucide-react';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Button } from '~/components/ui';
import { Breakpoint, cn } from '~/lib';

import { EMPTY_FORMULA, ObjectFormula, Path } from './constants';
import { Formula } from './Formula';

type FormulasProps = {
  objectFormula: ObjectFormula;
  path: Path;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  setPath: Dispatch<SetStateAction<Path>>;
  className?: string;
  placeholder?: string;
  breakpoint: Breakpoint;
};

const PLACEHOLDER_FORMULA = 'f(x) = ax + b';

export const Formulas = ({
  objectFormula,
  path,
  setObjectFormula,
  setPath,
  className,
  placeholder,
  breakpoint,
}: FormulasProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    objectFormula.formulas.length > 1 && scrollToBottom();
  }, [objectFormula]);

  const disableNewLine =
    objectFormula.formulas[objectFormula.formulas.length - 1].childFormula !==
      undefined ||
    objectFormula.formulas[objectFormula.formulas.length - 1].parentFormula
      .mainFormula.symbols.length === 0;

  const addNewLine = useCallback((): void => {
    setObjectFormula(prevObjectFormula => {
      if (disableNewLine) {
        return prevObjectFormula;
      }

      const cloneObjectFormula = JSON.parse(
        JSON.stringify(prevObjectFormula),
      ) as ObjectFormula;

      const currentFormulas = get(cloneObjectFormula, 'formulas');

      set(
        cloneObjectFormula,
        'formulas',
        currentFormulas.concat({ ...EMPTY_FORMULA }),
      );

      setPath(prevPath => {
        const clonePath = [...prevPath];

        return [`formulas[${currentFormulas.length}]`].concat(
          ...clonePath.slice(1),
        );
      });

      return cloneObjectFormula;
    });
  }, [setObjectFormula, disableNewLine, setPath]);

  return (
    <div
      className={`grid grid-cols-[auto_min-content] w-full items-center ${cn(className)}`}
    >
      {objectFormula.formulas.map((_formula, index) => (
        <div
          className="grid grid-cols-subgrid col-span-2 items-center"
          key={index}
        >
          <div className="flex items-center justify-start">
            <Formula
              objectFormula={objectFormula}
              path={path}
              setPath={setPath}
              formulaPath={`formulas[${index}]`}
              setObjectFormula={setObjectFormula}
              mathJaxContentPlaceholder={placeholder ?? PLACEHOLDER_FORMULA}
              className="self-stretch"
            />
          </div>
          {path[0] === `formulas[${index}]` ? (
            <Button
              size="icon"
              variant="ghost"
              onClick={addNewLine}
              disabled={disableNewLine}
              id={`joyride-new-line-${breakpoint}`}
            >
              <CornerDownLeft />
            </Button>
          ) : (
            <div />
          )}
        </div>
      ))}
      <div ref={ref} />
    </div>
  );
};
