export const problemsMessages = {
  problems: {
    level: 'Niveau',
    statement: 'Énoncé',
    delete: 'Supprimer',
    create: 'Générer',
    valid: 'Valide',
    followStepsLabel: 'Suivre le raisonnement suivant :',
    answer: 'Réponse :',
    method:
      'Pense à bien détailler toute la logique de ta réflexion dans une seule réponse, par exemple en sautant des lignes.',
    warningNext:
      'Aide-toi de la messagerie et des indices pour répondre correctement à la question avant de passer à la suivante !',
    actions: 'Actions',
  },
};
