/* eslint-disable max-lines */
export const CHAPTERS = {
  france: {
    '2024': {
      '10': {
        mathematics: {
          real_numbers: true,
          integers: true,
          calculation: true,
          geometry: true,
          vectors: true,
          coordinate_system: true,
          plan_line_and_system: true,
          // numerical_functions: true,
          // equations_inequations: true,
          // variations_extremums: true,
          statistics: true,
          probability: true,
          // sampling: true,
          // algorithmic: true,
        },
      },
      // '11': {
      //   mathematics: {
      //     arithmetical_sequences: true,
      //     geometrical_sequences: true,
      //     probability: true,
      //     functions: true,
      //     exponential: true,
      //     derived_number: true,
      //     derived_function: true,
      //   },
      //   mathematics_specialty: { probability: true },
      // },
    },
  },
} as const;

export const NOTIONS = {
  france: {
    '2024': {
      '10': {
        mathematics: {
          real_numbers: {
            line_of_reals: true,
            intervals: true,
            absolute_value: true,
            decimals: true,
            approximate_value: true,
            rationals: true,
            integer_power: true,
            square_root: true,
            reals_addition: true,
            reals_multiplication: true,
            intervals_composition: true,
          },
          integers: {
            whole_and_relative: true,
            multiple_and_divider: true,
            multiple_sum_and_product: true,
            prime: true,
            odd_even_definition: true,
            parity_sum: true,
            parity_square: true,
          },
          calculation: {
            algebric_expressions: true,
            distributivity: true,
            remarkable_identities: true,
            factorisation: true,
            quotient_product: true,
            quotient_operations: true,
            quotient_inverse: true,
            comparison_sign: true,
            comparison_criteria: true,
            equations: true,
            first_degree: true,
          },
          geometry: {
            pythagore: true,
            thales: true,
            angles: true,
            trigonometry_formula: true,
            triangle_angles_sum: true,
            internal_alternate_angle: true,
            quadrilateral: true,
            circles: true,
            inscribed_angle: true,
            tangent: true,
            mediator: true,
            triangle_lines: true,
            orthogonal_projection: true,
          },
          vectors: {
            translations: true,
            equality: true,
            chasles: true,
            opposite: true,
            real_product: true,
            colinearity: true,
            orthonormal: true,
            coordinates: true,
            colinearity_criteria: true,
          },
          coordinate_system: {
            middle: true,
            vector_norm: true,
            determinant: true,
            colinearity_criteria: true,
          },
          plan_line_and_system: {
            director_vector: true,
            cartezian_equation: true,
            y_parallelism: true,
            y_no_parallelism: true,
            linear_system: true,
            graphical_interpretation: true,
            system_resolution: true,
          },
          // numerical_functions: {
          //   vocabulary: true,
          //   graphical_representation: true,
          //   parity: true,
          //   square_function: true,
          //   inverse_function: true,
          //   cubic_function: true,
          //   square_root_function: true,
          //   inequality: true,
          //   relative_position: true,
          // },
          // equations_inequations: {
          //   equation: true,
          //   inequation: true,
          //   sign_table: true,
          //   linear_sign: true,
          //   inequation_equivalence: true,
          //   product: true,
          //   quotient: true,
          // },
          // variations_extremums: {
          //   growth: true,
          //   variation_table: true,
          //   min_max: true,
          //   growth_rate: true,
          //   variation: true,
          //   square_function: true,
          //   inverse_function: true,
          //   cubic_function: true,
          //   square_root_function: true,
          // },
          statistics: {
            frequency_definition: true,
            frequency_calculation: true,
            frequency_frequency: true,
            variations_definition: true,
            growth_rate_product: true,
            successive_evolution: true,
            reciprocal_evolution: true,
            average_definition: true,
            average_operations: true,
            standard_deviation: true,
            median: true,
          },
          probability: {
            universe_output_event_definition: true,
            complementary_probability_definition: true,
            joint_probability_definition: true,
            probability_law: true,
            equiprobability: true,
            incompatibility_calculation: true,
            joint_probability_calculation: true,
            complementary_probability_calculation: true,
          },
          // sampling: {
          //   vocabulary: true,
          //   large_numbers: true,
          //   probability_estimate: true,
          //   proprtion_estimate: true,
          //   accuracy: true,
          // },
          // algorithmic: {
          //   vocabulary: true,
          //   conditional_structures: true,
          //   functions: true,
          //   python_implementation: true,
          //   for_loop: true,
          //   while_loop: true,
          // },
        },
      },
      // '11': {
      //   mathematics: {
      //     arithmetical_sequences: {
      //       vocabulary: true,
      //       definition: true,
      //       recurrence_relation: true,
      //       explicit_form: true,
      //       variations: true,
      //     },
      //     geometrical_sequences: {
      //       explicit_form: true,
      //       recurrence_relation: true,
      //       variations: true,
      //       numerical_sequences: true,
      //     },
      //     probability: {
      //       conditional_probability: true,
      //       complementary_probability: true,
      //       joint_probability_calculation: true,
      //       false_positive: true,
      //       event_dependency: true,
      //       ponderated_tree: true,
      //       independant_succession: true,
      //       incompatibility_definition: true,
      //       incompatibility_calculation: true,
      //       universe_output_event_definition: true,
      //     },
      //     functions: {
      //       growth_rate: true,
      //       linear_growth: true,
      //       definition: true,
      //       variations: true,
      //     },
      //     exponential: {
      //       definition: true,
      //       algebric_addition: true,
      //       algebric_other: true,
      //       variation: true,
      //       graphical_representation: true,
      //       root: true,
      //       average_evolution_rate: true,
      //     },
      //     derived_number: {
      //       intersecting: true,
      //       tangent: true,
      //       definition: true,
      //       reduced_equation_line: true,
      //       reduced_equation_tangent: true,
      //     },
      //     derived_function: {
      //       definition: true,
      //       derived_referenced_functions: true,
      //       algebric_derivation: true,
      //       variation_and_derivation_sign: true,
      //       extremums: true,
      //     },
      //   },
      //   mathematics_specialty: {
      //     probability: {
      //       conditional_probability_definition: true,
      //       weighted_tree: true,
      //       universe_partition_definition: true,
      //       complementary_partition: true,
      //       total_probability: true,
      //       independent_events: true,
      //       independent_complementary_events: true,
      //       independent_events_succession: true,
      //     },
      //   },
      // },
    },
  },
} as const;
