/* eslint-disable max-lines */

export const statistics = {
  frequency_definition: [
    {
      type: 'definition',
      title: 'Proportions',
      content: [
        {
          type: 'text',
          content:
            'On considère un ensemble \\( T \\) (appelé également population) contenant une partie \\( A \\) (ou sous-population). On note \\( n_{T} \\) et \\( n_{A} \\) respectivement le nombre d’individus dans l’ensemble \\( T \\) et dans l’ensemble \\( A \\). La \\( \\textbf{proportion} \\) (ou \\( \\textbf{fréquence} \\)) de \\( A \\) dans \\( T \\) est le nombre \\[ p = \\frac{n_{A}}{n_{T}} \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'On peut l’écrire sous forme décimale, fractionnaire ou en pourcentage.',
        },
      ],
    },
  ],
  frequency_calculation: [
    {
      type: 'simpleText',
      title: 'Proportions',
      subtitle: 'Calculer avec des proportions',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture}\\draw[fill=blue!20, draw=black] (0,-1) rectangle (3,1);\\draw[fill=red!20, draw=red] (1.5,0) ellipse (1 and 0.5);\\node at (1.5,0) {A};\\node at (0.2,-0.8) {T};\\draw[->, thick] (3.5,0) -- (6.5,0) node[midway, above] {\\textit{\\textcolor{blue}{$n_T$}$\\times p = $ \\textcolor{red}{$n_A$}}};\\draw[fill=red!20, draw=red] (8,0) ellipse (1 and 0.5);\\node at (8,0) {A};\\end{tikzpicture}\\end{document}',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( n_{A} \\) et \\( n_{T} \\) sont connus et on cherche \\( p \\): \\[ p = \\frac{n_{A}}{n_{T}} \\]',
            },
            {
              type: 'text',
              content:
                '\\( n_{T} \\) et \\( p \\) sont connus et on cherche \\( n_{A} \\): \\[ n_{A} = n_{T} \\times p \\]',
            },
            {
              type: 'text',
              content:
                '\\( n_{A} \\) et \\( p \\) sont connus et on cherche \\( n_{T} \\): \\[ n_{T} = \\frac{n_{A}}{p} \\]',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Pour les calculs, si la proportion est en pourcentage, il est souvent plus pratique de l’écrire sous forme décimale',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Dans une classe, il y a 12 filles sur les 30 élèves. La proportion de filles dans la classe est :\\[ \\frac{12}{30} = 0,4 \\: ou \\: \\frac{2}{5} \\: ou \\: 40\\% \\]',
            },
            {
              type: 'text',
              content:
                'Dans une classe de 24 élèves, 75% sont des filles. Le nombre de filles dans cette classe est :\\[ 24 \\times 0,75 = 18 \\]',
            },
            {
              type: 'text',
              content:
                "Dans une classe, il y a 21 filles qui représentent 60% des élèves de la classe. Le nombre total d'élèves dans la classe est :\\[ \\frac{21}{0,6} = 35 \\]",
            },
          ],
        },
      ],
    },
  ],
  frequency_frequency: [
    {
      title: 'Proportions',
      subtitle: 'Proportion de proportion',
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'On considère un ensemble \\( T \\) contenant une partie \\( A \\) contenant elle-même une partie \\( B \\).',
        },
        {
          type: 'text',
          content:
            "Si \\( p \\) est la proportion de \\( A \\) dans \\( T \\) et \\( p' \\) est la proportion de \\( B \\) dans \\( A \\) alors la proportion de \\( B \\) dans \\( T \\) est : \\( p \\times p' \\).",
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'tikz',
          content:
            "\\begin{document} \\begin{tikzpicture}\\draw[fill=blue!20, draw=blue, thick] (0,0) rectangle (5,3.5);\\node[blue] at (0.3,3.2) {\\Large $T$};\\draw[fill=red!10, draw=red, thick] (2.5,1.75) ellipse (2 and 1.);\\node[red] at (1.2,1.8) {\\Large $A$};\\draw[fill=green!5, draw=green!80!black, thick] (2.4,1.35) rectangle (3,2.15);\\node[green!80!black] at (2.7,1.75) {\\Large $B$};\\draw[->, thick] (5.5,1.75)--(9.5,1.75);\\node at (7.5,2.05) {\\Large \\textcolor{blue}{$n_T$} $\\times$ $p$ = \\textcolor{red}{$n_A$}};\\draw[fill=red!10, draw=red, thick] (12.5,1.75) ellipse (2 and 1.);\\node[red] at (11.2,1.85) {\\Large $A$};\\draw[fill=green!5, draw=green!80!black, thick] (12.4,1.35) rectangle (13,2.15);\\node[green!80!black] at (12.7,1.75) {\\Large $B$};\\draw[->, thick] (15,1.75)--(19,1.75);\\node at (17,2.10) {\\Large \\textcolor{red}{$n_A$} $\\times$ $p'$ = \\textcolor{green!80!black}{$n_B$}};\\draw[fill=green!5, draw=green!80!black, thick] (19.5,1.35) rectangle (20.5,2.15);\\node[green!80!black] at (20,1.75) {\\Large $B$};\\draw[->, thick] (4,-0.3) arc[start angle=230, end angle=330, x radius=10cm, y radius=6cm];\\node at (11,-1) {\\Large \\textcolor{blue}{$n_T$} $\\times$ $pp'$ = \\textcolor{green!80!black}{$n_B$}};\\end{tikzpicture} \\end{document}",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Soit \\( n_{A} \\), \\( n_{B} \\) et \\( n_{T} \\) le nombre d’individus des ensembles \\( A \\), \\( B \\) et \\( T \\). Par définition :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'la proportion \\( p \\) de \\( A \\) dans \\( T \\) est égale à : \\( \\frac{n_{A}}{n_{T}} \\)',
            },
            {
              type: 'text',
              content:
                "la proportion \\( p' \\) de \\( B \\) dans \\( A \\) est égale à : \\( \\frac{n_{B}}{n_{A}} \\)",
            },
            {
              type: 'text',
              content:
                "la proportion \\( p'' \\) de \\( B \\) dans \\( T \\) est égale à : \\( \\frac{n_{B}}{n_{T}} \\)",
            },
          ],
        },
        {
          type: 'text',
          content:
            "Or \\( p \\times p' = \\frac{n_{A}}{n_{T}} \\times \\frac{n_{A} \\times n_{B}}{n_{T} \\times n_{A}} = \\frac{n_{B}}{n_{T}} = p'' \\)",
        },
        {
          type: 'text',
          content:
            "La proportion de \\( B \\) dans \\( T \\) est bien égale à \\( p \\times p' \\).",
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Dans une classe, 60% des élèves sont des filles et 75 % d’entre elles sont brunes. La proportion de filles brunes dans la classe est égale à :\\[ 0,6 \\times 0,75 = 0,45 = 45\\% \\]',
        },
      ],
    },
  ],
  variations_definition: [
    {
      title: 'Évolutions',
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            'Une valeur de départ \\( (V_{d}) \\) évolue vers une valeur d’arrivée \\( (V_{a}) \\).',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\node[text=green!80!black] at (0,0) {\\huge $V_d$}; \\node[text=red] at (5,0) {\\huge $V_a$}; \\draw[->] (1,0.5) to[out=45,in=135] (4,0.5); \\end{tikzpicture}\\end{document}',
        },
      ],
    },
    {
      type: 'definition',
      subtitle: 'Variation absolue, variation relative',
      content: [
        {
          type: 'text',
          content: 'Pour quantifier cette évolution, on peut calculer :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "la \\( \\textbf{variation absolue} \\), soit l'évolution en \\( \\textbf{nombre} \\) \\( V_{a} - V_{d} \\) ",
            },
            {
              type: 'text',
              content:
                "la \\( \\textbf{variation relative} \\), soit l'évolution en \\( \\textbf{proportion} \\) \\( \\frac{(V_{a} - V_{d})}{V_{d}} \\) ",
            },
          ],
        },
        {
          type: 'text',
          content:
            "La variation relative est aussi appelée \\( \\textbf{taux d'évolution} \\) entre \\( V_{d} \\) et \\( V_{a} \\).",
        },
      ],
    },

    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content: 'Le signe de la variation indique le sens de l’évolution.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Un prix valait 8 € et vaut maintenant 9 €',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'variation absolue : \\( 9 - 8 = 1 \\)\nLe prix a augmenté de 1€.',
            },
            {
              type: 'text',
              content:
                'variation relative : \\( \\frac{9 - 8}{8} = 0,125 = 12,5\\% \\)\nLe prix a augmenté de 12,5%.',
            },
          ],
        },
      ],
    },
  ],
  growth_rate_product: [
    {
      type: 'property',
      title: 'Évolutions',
      subtitle: 'Taux d’évolution et coefficient multiplicateur',
      content: [
        {
          type: 'text',
          content:
            'Augmenter de \\( t\\% \\) revient à multiplier par \\[ CM = (1+\\frac{t}{100}) \\]',
        },
        {
          type: 'text',
          content:
            'Diminuer de \\( t\\% \\) revient à multiplier par \\[ CM = (1-\\frac{t}{100}) \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Après avoir subi une baisse de 20%, un article coûte 41,6 €. Quel était son prix avant la baisse ?\nBaisser de 20% revient à multiplier par :\\[ 1 - \\frac{20}{100} = 0,8 \\]',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\node[text=green!80!black] at (0,0) {\\large $?$}; \\node[text=red] at (5,0) {\\large $41,6$}; \\draw[->] (1,0.5) to[out=45,in=135] (4,0.5); \\node at (2.5,1.4) {\\(\\times \\ 0,8\\)}; \\node at (2.5,-0.5) {\\(\\textcolor{green!80!black}{V_d} = \\frac{\\textcolor{red}{41,6}}{0,8} = 52\\)}; \\end{tikzpicture}\\end{document}',
        },
        {
          type: 'text',
          content: "L'article valait 52€ avant la baisse.",
        },
      ],
    },
  ],
  successive_evolution: [
    {
      type: 'property',
      title: 'Évolutions',
      subtitle: 'Évolutions successives',
      content: [
        {
          type: 'text',
          content:
            'Une valeur de départ \\( V_{d} \\) subit plusieurs évolutions successives : \\( t_{1}\\% \\) de coefficient multiplicateur \\( CM_{1} \\), suivie de \\( t_{2}\\% \\) suivie de …',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture}\\node at (0,0) {\\textcolor{green!80!black}{$V_d$}};\\node at (2,0) {$V_1$};\\node at (4,0) {$V_2$};\\node at (6,0) {\\dots};\\node at (8,0) {\\textcolor{red}{$V_a$}};\\draw[->] (0,0.3) to[bend left=30] node[midway,above]{$\\times CM_1$} (1.8,0.3);\\draw[->] (2,0.3) to[bend left=30] node[midway,above]{$\\times CM_2$} (3.8,0.3);\\draw[->] (6,0.3) to[bend left=30] node[midway,above]{$\\times$\\dots} (7.8,0.3);\\draw[->] (0.3,-0.3) to[bend right=30] node[midway,below]{$\\times CM_g$} (7.8,-0.3);\\end{tikzpicture}\\end{document}',
        },
        {
          type: 'text',
          content:
            'Le coefficient \\( \\textbf{multiplicateur global} \\) est le produit des coefficients multiplicateurs intermédiaires. On a : \\( CM_{g} = CM_{1} \\times CM_{2} \\times \\ldots \\)\n\nLe \\( \\textbf{taux d’évolution global} \\) est donc égal à : \\( t_{g} = CM_{g} - 1 \\).',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Le nombre d’abonnés d’un quotidien augmente de 10% puis augmente de 20%. Quelle est l’évolution globale ?',
        },
        {
          type: 'text',
          content:
            'Augmenter de 10% (resp. 20%) revient à multiplier par 1,1 (resp. 1,2) : \\( CM_{g} = 1,1 \\times 1,2 = 1,32 \\)',
        },
        {
          type: 'text',
          content:
            'Le taux d’évolution global est : \\( CM_{g} -1 = 1,32 - 1 = 0,32 \\)',
        },
        {
          type: 'text',
          content: 'Donc le nombre d’abonnés a augmenté de 32%.',
        },
      ],
    },
  ],
  reciprocal_evolution: [
    {
      type: 'property',
      title: 'Évolutions',
      subtitle: 'Évolutions réciproques',
      content: [
        {
          type: 'text',
          content:
            "Une valeur de départ \\( V_{d} \\) évolue de \\( t\\% \\), on note \\( CM \\) le coefficient multiplicateur correspondant. Le \\( \\textbf{taux réciproque} \\) est le taux d’évolution appliqué à la valeur d'arrivée \\( V_{a} \\) qui permet de revenir à la valeur initiale \\( V_{d} \\).",
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\node at (-2,0) {\\textcolor{green!80!black}{$V_d$}}; \\node at (2,0) {\\textcolor{red}{$V_a$}}; \\draw[->] (-1.5,0.2) to [bend left=30] node[midway,above]{$\\times CM$} (1.5,0.2); \\draw[<-] (-1.5,-0.2) to [bend right=30] node[midway,below]{$\\times \\frac{1}{CM}$} (1.5,-0.2); \\end{tikzpicture} \\end{document}',
        },
        {
          type: 'text',
          content:
            'Son coefficient multiplicateur \\( CM_{rec} \\) est égal à \\( \\frac{1}{CM} \\).',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Un prix augmente de 10%. Quelle évolution permettrait de retrouver le prix initial ?',
        },
        {
          type: 'text',
          content:
            'Le coefficient multiplicateur réciproque est :\\[ CM_{rec} = \\frac{1}{1 + \\frac{10}{100}} \\approx 0,909 \\]',
        },
        {
          type: 'text',
          content:
            'Le taux d’évolution réciproque est :\\[ CM_{rec} - 1 \\approx 0,909 - 1 \\approx -0,091 \\]',
        },
        {
          type: 'text',
          content: 'Il faut baisser le nouveau prix d’environ 9,1 %.',
        },
      ],
    },
  ],
  average_definition: [
    {
      type: 'definition',
      title: 'Résumés statistiques',
      subtitle: 'Moyenne - définition',
      content: [
        {
          type: 'text',
          content:
            'La moyenne d’une série, notée \\( \\bar{x} \\), est le quotient de la somme de toutes les valeurs de la série par l’effectif total.',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            "Si une série est donnée par son tableau d'effectifs :\\[ \\begin{array}{|l|c|c|c|c|} \\hline \\textbf{Valeurs} & x_{1} & x_{1} & \\ldots & x_{p} \\\\ \\hline \\textbf{Effectif} & n_{1} & n_{1} & \\ldots & n_{p} \\\\ \\hline \\end{array} \\]",
        },
        {
          type: 'text',
          content:
            'On a :\\[ \\bar{x} = \\frac{x_{1} \\times n_{1} + x_{2} \\times n_{2} + \\ldots + x_{p} \\times n_{p}}{n_{1} + n_{2} + \\ldots + n_{p}} \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'On donne la série suivante.\\[ \\begin{array}{|l|c|c|c|} \\hline \\textbf{Valeurs} & 5 & 8 & 10 \\\\ \\hline \\textbf{Effectif} & 1 & 2 & 2 \\\\ \\hline \\end{array} \\]La moyenne \\( \\bar{x} \\)est égale à :\\[ \\bar{x} = \\frac{5 \\times 1 + 8 \\times 2 + 10 \\times 2}{1 + 2 + 2} = 8,2 \\]',
        },
      ],
    },
  ],
  average_operations: [
    {
      type: 'property',
      title: 'Résumés statistiques',
      subtitle: 'Opérations sur la moyenne',
      content: [
        {
          type: 'text',
          content: 'On considère une série de moyenne \\( \\bar{x} \\)',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si on multiplie toutes les valeurs d’une série par un nombre \\( a \\) alors la moyenne est multipliée par \\( a \\).',
            },
            {
              type: 'text',
              content:
                'Si on ajoute le même nombre \\( b \\) à toutes les valeurs de la série alors cette valeur \\( b \\) s’ajoute à la moyenne \\( \\bar{x} \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            "On donne la série suivante.\\[ \\begin{array}{|l|c|c|c|} \\hline \\textbf{Valeurs} & 5 & 8 & 10 \\\\ \\hline \\textbf{Effectif} & 1 & 2 & 2 \\\\ \\hline \\end{array} \\]La moyenne \\( \\bar{x} \\) est égale à :\\[ \\bar{x} = \\frac{5 \\times 1 + 8 \\times 2 + 10 \\times 2}{1 + 2 + 2} = 8,2 \\]On multiplie toutes les valeurs de série par 2 et on ajoute 1. La série devient :\\[ \\begin{array}{|l|c|c|c|} \\hline \\textbf{Valeurs} & 11 & 17 & 21 \\\\ \\hline \\textbf{Effectif} & 1 & 2 & 2 \\\\ \\hline \\end{array} \\]La nouvelle moyenne \\( \\bar{x'} \\) vaut:\\[ \\bar{x'} = 2 \\times \\bar{x} + 1 = 17,4 \\]",
        },
      ],
    },
  ],
  standard_deviation: [
    {
      type: 'simpleText',
      title: 'Résumés statistiques',
      subtitle: 'Écart type',
      content: [
        {
          type: 'text',
          content:
            'Pour mesurer la dispersion des valeurs d’une série par rapport à la moyenne, on prend en compte l’écart relatif de chaque valeur à la moyenne.',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture}[font=\\Large,scale=0.75]\\draw [->] (0,0) -- (14,0);\\draw (1,0.1) -- +(0,-0.2);\\draw (3,0.1) -- +(0,-0.2);\\draw (5,0.1) -- +(0,-0.2);\\draw (6.5,0.1) -- +(0,-0.2);\\draw (8,0.1) -- +(0,-0.2);\\draw (12,0.1) -- +(0,-0.2);\\node at (1,-0.4) {$x_{1}$};\\node at (3,-0.4) {$x_{2}$};\\node at (5,-0.4) {$x_{3}$};\\node at (8,-0.4) {$x_{4}$};\\node at (12,-0.4) {$x_{5}$};\\node[red] at (6.5,-0.4) {$\\overline{x}$};\\draw[<-] (1,-1) -- node[below] {$x_1-\\overline{x}$} (6.5,-1);\\draw[<-] (3,-2) -- node[below] {$x_2-\\overline{x}$} (6.5,-2);\\draw[<-] (5,-3) -- node[below] {$x_3-\\overline{x}$} (6.5,-3);\\draw[->] (6.5,-4) -- node[below] {$x_4-\\overline{x}$} (8,-4);\\draw[->] (8,-5) -- node[below] {$x_5-\\overline{x}$} (12,-5);\\end{tikzpicture}\\end{document}',
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'Soit la série de \\( N \\) valeurs \\( x_{1} \\), \\( x_{2} \\), …, \\( x_{N} \\) de moyenne \\( \\bar{x} \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'La \\( \\textbf{variance} \\: V \\) de la série est la moyenne des carrés des écarts des valeurs avec la moyenne :\\[ V = \\frac{(x_{1} - \\bar{x})^2 + (x_{2} - \\bar{x})^2 + \\ldots + (x_{N} - \\bar{x})^2 }{N} \\]',
            },
            {
              type: 'text',
              content:
                "L'\\( \\textbf{écart type} \\: \\sigma \\) de la série est la racine carrée de la variance \\( \\sigma = \\sqrt{V} \\)",
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Le couple \\( (\\bar{x}, \\sigma) \\) permet de résumer une série.\n\nIl a l’avantage d’utiliser toutes les valeurs de la série, en ce sens il est représentatif de la série. Il a l’inconvénient d’être « sensible » aux valeurs extrêmes.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Soit la série 5 ; 8 ; 8 ; 10 ; 10 de moyenne 8,2.',
        },
        {
          type: 'text',
          content:
            'La variance \\( V \\) est égale à :\\[ \\frac{(5 - 8,2)^2 + (8 - 8,2)^2 + \\ldots + (10 - 8,2)^2}{5} = 3,36 \\]',
        },
        {
          type: 'text',
          content:
            "L'écart type est égal à :\\[ \\sigma = \\sqrt{V} = \\sqrt{3,36} \\approx 1,83 \\]",
        },
      ],
    },
  ],
  median: [
    {
      type: 'simpleText',
      title: 'Résumés statistiques',
      subtitle: 'Médiane et écart interquartile',
      content: [
        {
          type: 'text',
          content:
            'La médiane \\( Me \\) permet de partager en deux une série rangée dans l’ordre croissant. Pour la partager en quatre, on utilise les quartiles.',
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'Le premier quartile (resp. le troisième) de la série, noté \\( Q_{1} \\) (resp. \\( Q_{3} \\)), est la plus petite valeur de la série telle qu’au moins 25% (resp. 75%) des valeurs de la série lui soient inférieures ou égales.',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\draw[thick] (0,0) -- (10,0); \\foreach \\x in {0, 2.5, 5, 7.5, 10} \\draw[thick] (\\x cm,3pt) -- (\\x cm,-3pt); \\node[below] at (0,-0.2) {\\Huge \\textit{Min}}; \\node[below] at (10,-0.2) {\\Huge \\textit{Max}}; \\node[below] at (5,-0.2) {\\Huge \\textcolor{red}{\\textit{Me}}}; \\node[below] at (2.5,-0.2) {\\huge \\textcolor{blue}{\\textit{$Q_1$}}}; \\node[below] at (7.5,-0.2) {\\huge \\textcolor{green!80!black}{\\textit{$Q_3$}}}; \\draw[<->, thick, red] (0,0.5) -- node[midway, above, scale=0.5] {\\huge \\textcolor{red}{Au moins 50\\% des valeurs}} +(5,0); \\draw[<->, thick, red] (5,0.5) -- node[midway, above, scale=0.5] {\\huge \\textcolor{red}{Au moins 50\\% des valeurs}} +(5,0); \\draw[<->, thick, blue] (0,-1.5) -- node[midway, below, scale=0.5] {\\huge \\textcolor{blue}{Au moins 25\\% des valeurs}} +(5,0); \\draw[<->, thick, green!80!black] (0,-2) -- node[midway, below, scale=0.5] {\\huge \\textcolor{green!80!black}{Au moins 75\\% des valeurs}} +(7.5,0); \\end{tikzpicture}\\end{document}',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'On appelle écart interquartile, le nombre \\( Q_{3} - Q_{1} \\)',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Le couple \\( (Me, Q3− Q1) \\) permet de résumer une série. Il a l’avantage de posséder une représentation graphique (diagramme en boite) et de ne pas être sensible aux valeurs extrêmes.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Soit la série ordonnée suivante : 0 ; 0 ; 0 ; 1 ; 2 ; 2 ; 2 ; 3 ; 3 ; 3 ; \\( \\textbf{3} \\) ; \\( \\textbf{4} \\) ; 4 ; 4 ; 4 ; 5 ; 6 ; 6 ; 6 ; 6 ; 6 ; 9.\n\n\\( Eff_{total} \\) est égal à 22 et est pair donc la médiane est la moyenne de la 11e et de la 12e valeur de la série.\\[ Me = \\frac{3 + 4}{2} = 3,5 \\]',
        },
        {
          type: 'text',
          content:
            'Position de \\( Q_{1} \\) : \\( 22 \\times 0,25 = 5,5\\)\n\\( Q1 \\) est la 6e valeur de la série soit 2.\n\nPosition de \\( Q_{3} \\) : \\( 22 \\times 0,75 = 16,5\\)\n\\( Q3 \\) est la 17e valeur de la série soit 6.\n\nOn obtient le diagramme suivant.',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document} \\begin{tikzpicture} \\draw[thick] (0,2) -- (0,1); \\draw[thick] (0,1.5) -- (2,1.5); \\draw[thick] (2,2) -- (2,1) -- (6,1) -- (6,2) -- (2,2); \\draw[thick] (6,1.5) -- (9,1.5); \\draw[thick] (3.5,2) -- (3.5,1); \\draw[thick] (9,2) -- (9,1); \\draw[fill=green, opacity=.3](2,1) rectangle (6,2);\\draw[dotted, thick] (3.5,1) -- (3.5,-0.1); \\draw[->,thick](-0.5,0) -- (10,0);\\foreach \\x in {0,...,9} \\draw (\\x,0.1) -- (\\x,-0.1);\\foreach \\x in {0,...,9} \\node[above] at (\\x,0.1) {\\x}; \\node at (0,-0.4) {Min}; \\node at (2,-0.4) {\\color{blue} $Q_1$};\\node at (3.5,-0.4) {\\color{red} Me};\\node at (6,-0.4) {\\color{green!80!black} $Q_3$};\\node at (9,-0.4) {Max};\\draw[<->, thick] (2,-1) -- (6,-1);\\node at (4,-1.4) {$\\textcolor{green!80!black}{Q_3}-\\textcolor{blue}{Q_1}=6-2=4$}; \\end{tikzpicture} \\end{document}',
        },
        {
          type: 'text',
          content:
            'L’écart interquartile est \\[ Q_{3} - Q_{1} = 6 - 2 = 4 \\]',
        },
      ],
    },
  ],
} as const;
