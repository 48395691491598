import { BREAKPOINTS } from '~/lib/hooks';

const problemTrigger = {
  title: "Énoncé de l'exercice en cours",
  content:
    "Dans cet onglet tu trouveras l'énoncé de l'exercice proposé par Skloover.",
};
const problem = {
  title: 'Exercice',
  content:
    "Lis bien l'énoncé de l'exercice à résoudre, et la question en cours qui est en gras.",
};
const chat = {
  title: 'Messagerie',
  content:
    "Tu peux poser des questions ou répondre à l'exercice via le chat. Veille à bien structurer ta réponse en sautant des lignes.",
};
const hintsTrigger = {
  title: "Indices pour t'aider",
  content:
    "Si tu es bloqué, tu trouveras dans cet onglet des indices qui t'aideront à répondre en autonomie.",
};
const hints = {
  title: "Indices pour t'aider",
  content:
    'Chaque question doit être répondue en suivant une certaine logique, dont les étapes sont listées ici.',
};
const formula = {
  title: 'Formule mathématique',
  content:
    "Tu peux aussi t'aider de ce clavier pour entrer des formules mathématiques.",
};
const keyboard = {
  title: 'Clavier',
  content:
    'Clique sur les symboles ou les fonctions pour les ajouter à ta formule.',
};
const newLine = {
  title: 'Saut de ligne',
  content:
    'Structure ta réponse en ajoutant des formules sur plusieurs lignes.',
};
const textKey = {
  title: 'Ajouter du texte',
  content:
    'Tu peux aussi insérer du texte entre tes formules, les connecteurs "donc" ou encore "si" sont appréciés.',
};
const next = {
  title: 'Question ou problème suivant',
  content:
    "Lorsque l'IA t'y incite, passe à la question ou au problème suivant.",
};

export const joyrideMessages = {
  joyride: {
    tutorial: 'Tutoriel',
    back: 'Préc.',
    close: 'Fermer',
    last: 'Terminer',
    next: 'Suivant',
    open: 'Ouvrir',
    skip: 'Fermer le turoriel',
    steps: {
      chapters: {
        title: 'Bienvenue sur Skloover !',
        content:
          "L'objectif est de te faire progresser en te proposant des exercices adaptés à ton niveau, et qui traitent de toutes les notions du chapitre sélectionné.",
      },
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`problem-trigger-${breakpoint}`]: problemTrigger,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`problem-${breakpoint}`]: problem,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`hints-trigger-${breakpoint}`]: hintsTrigger,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`hints-${breakpoint}`]: hints,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`chat-${breakpoint}`]: chat,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`formula-${breakpoint}`]: formula,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`keyboard-${breakpoint}`]: keyboard,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`new-line-${breakpoint}`]: newLine,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`text-key-${breakpoint}`]: textKey,
        }),
        {},
      ),
      ...BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [`next-${breakpoint}`]: next,
        }),
        {},
      ),
      chapter: {
        title: 'Rappel du cours',
        content:
          "Si tu as besoin d'un rappel de la notion du cours abordée dans la question, clique ici.",
      },
    },
  },
};
