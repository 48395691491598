import get from 'lodash/get';
import { Check } from 'lucide-react';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Button } from '~/components/ui';

import {
  ObjectFormula,
  OverlineFormula,
  Path,
  selectedElementClassName,
} from './constants';
import { Formula } from './Formula';
import { validateFormula } from './validateFormula';

interface VectorProps {
  objectFormula: ObjectFormula;
  path: Path;
  vectorPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
}

export const Vector = ({
  objectFormula,
  vectorPath,
  path,
  setPath,
  setObjectFormula,
}: VectorProps): ReactElement => {
  const { inFormula } = get(
    objectFormula,
    `${vectorPath}.childFormula`,
  ) as unknown as OverlineFormula;
  const editing = vectorPath === path.slice(0, -1).join('.');
  const validateVisibility =
    inFormula.parentFormula.mainFormula.symbols.length === 0
      ? 'invisible'
      : 'visible';
  const selected = editing;
  const dynamicClassName = `${
    editing && 'hover:cursor-pointer hover:border-primary bg-gray-100'
  } ${selected ? 'border-primary' : 'border-transparent'}`;

  return (
    <div className="flex items-center gap-1">
      <div className="relative pt-2 border-solid border-primary border-t-2">
        <div
          className={`${selectedElementClassName} ${dynamicClassName}`}
          role="presentation"
        >
          <Formula
            objectFormula={objectFormula}
            path={path}
            setPath={setPath}
            formulaPath={`${vectorPath}.childFormula.inFormula`}
            mathJaxContentPlaceholder="AB"
            setObjectFormula={setObjectFormula}
          />
        </div>
        <div className="absolute right-[-6px] top-[4px] w-0 h-0 mt-[-10px] ml-[-5px] border-[5px] border-solid border-transparent border-l-primary" />
      </div>
      {editing && (
        <Button
          variant="ghost"
          className={`text-green-700 hover:text-green-700 ${validateVisibility}`}
          size="icon"
          onClick={() => validateFormula({ setPath, path, setObjectFormula })}
        >
          <Check className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
