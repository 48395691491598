/* eslint-disable complexity */
import { ArrowRight, Loader2, RotateCcw } from 'lucide-react';
import { ReactElement, useState } from 'react';

import {
  AnswerSuccess,
  ChatHistory,
  NotionAsObject,
  Statement as StatementType,
} from '@skloover/shared';

import { Breakpoint, trpc, useIntl } from '~/lib';

import { LoadingSpinner } from '../icons/LoadingSpinner';
import { Button } from '../ui';
import { CHAT_ACTIONS_ID } from './constants';
import { QuestionSummaryDialog } from './QuestionSummaryDialog';

interface ChatActionsProps {
  isChatDefined: boolean;
  chatHistory?: ChatHistory;
  sessionId: string;
  problemId: string;
  isLastQuestion: boolean;
  questionStatement: Extract<StatementType, { type: 'question' }>;
  submit: ({ message }: { message: string }) => void;
  onProblemTabChange: (tab: string) => void;
  resetBlurred: () => void;
  isChatUpdatePending: boolean;
  breakpoint: Breakpoint;
}

const NextButton = ({
  onClick,
  disabled,
  label,
  pending,
  id,
}: {
  onClick: () => void;
  disabled?: boolean;
  label: string;
  pending: boolean;
  id?: string;
}): ReactElement => (
  <Button
    size="sm"
    variant="outline"
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
    disabled={disabled}
    id={id}
  >
    {label}
    {pending ? (
      <Loader2 className="ml-1 h-4 w-4 animate-spin" />
    ) : (
      <ArrowRight className="ml-1 h-4 w-4" />
    )}
  </Button>
);

export const ChatActions = ({
  sessionId,
  problemId,
  isChatDefined,
  isLastQuestion,
  questionStatement,
  onProblemTabChange,
  resetBlurred,
  isChatUpdatePending,
  breakpoint,
}: ChatActionsProps): ReactElement => {
  const t = useIntl();

  const [questionSummary, setQuestionSummary] = useState<{
    notionAsObjects: NotionAsObject[];
    answerSuccess: AnswerSuccess;
  }>();

  const { mutate: updateProblem, isPending: isUpdatePending } =
    trpc.problemUpdate.useMutation();

  const hasCurrentNotions =
    Object.keys(questionStatement.notionLevels).length > 0;

  const utils = trpc.useUtils();
  const { mutate: questionNext, isPending: isQuestionNextPending } =
    trpc.questionNext.useMutation({
      onSuccess: async data => {
        onProblemTabChange('statement');
        resetBlurred();
        hasCurrentNotions && setQuestionSummary(data.questionSummary);
        await utils.problemSolvingGet.invalidate(sessionId);
        await utils.notionList.invalidate();
      },
    });
  const { mutate: clearChat, isPending: isClearChatPending } =
    trpc.problemSolvingClear.useMutation({
      onSuccess: async () => {
        await utils.problemSolvingGet.refetch(sessionId);
        await utils.notionList.invalidate();
      },
    });

  const isTest = problemId === sessionId;

  const displayNext = !isLastQuestion || !isTest;
  const displayValidateProblem = isLastQuestion && isTest;

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1 flex-wrap">
          {displayNext && (
            <NextButton
              onClick={() => questionNext({ sessionId })}
              disabled={
                isChatUpdatePending || (isChatDefined && isQuestionNextPending)
              }
              pending={isQuestionNextPending}
              label={t.formatMessage({
                id: isLastQuestion
                  ? 'session.nextProblem'
                  : 'session.nextQuestion',
              })}
              id={`joyride-next-${breakpoint}`}
            />
          )}
          {isTest && (
            <>
              <NextButton
                onClick={() => clearChat(sessionId)}
                pending={isClearChatPending}
                label={t.formatMessage({ id: 'session.clearChat' })}
              />
              <NextButton
                onClick={() => questionNext({ sessionId, dryRun: true })}
                pending={isQuestionNextPending}
                label={t.formatMessage({ id: 'session.nextQuestion' })}
              />
            </>
          )}
          {displayValidateProblem && (
            <Button size="sm" onClick={() => updateProblem({ problemId })}>
              {isUpdatePending ? (
                <LoadingSpinner className="animate-spin" />
              ) : (
                <RotateCcw size={16} />
              )}
            </Button>
          )}
        </div>
        <div id={`${breakpoint}:${CHAT_ACTIONS_ID}`} />
      </div>
      <QuestionSummaryDialog
        questionSummary={questionSummary}
        resetQuestionSummary={() => setQuestionSummary(undefined)}
      />
    </>
  );
};
