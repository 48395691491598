import { ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { ChapterAsObject } from '@skloover/shared';

import { Breakpoint } from '~/lib';
import { getStringFormula } from '~/lib/utils/getStringFormula';

import { Button } from '../../ui';
import { EMPTY_FORMULA, ObjectFormula, Path } from '../Formula/constants';
import { Formulas } from '../Formula/Formulas';
import { Symbols } from '../Formula/Symbols';

interface FormulaAnswerProps {
  submit: ({ message }: { message: string }) => void;
  symbols?: string[];
  placeholder?: string;
  chatActionsDomNode: Element | null;
  open: boolean;
  breakpoint: Breakpoint;
  chapterAsObject: ChapterAsObject;
}

export const FormulaAnswer = ({
  submit,
  symbols = [],
  placeholder,
  chatActionsDomNode,
  open,
  breakpoint,
  chapterAsObject,
}: FormulaAnswerProps): ReactElement => {
  const [objectFormula, setObjectFormula] = useState<ObjectFormula>({
    formulas: [EMPTY_FORMULA],
  });
  const [path, setPath] = useState<Path>(['formulas[0]']);

  const sendEnabled = objectFormula.formulas.every(
    formula =>
      formula.childFormula === undefined &&
      formula.parentFormula.mainFormula.symbols.length > 0,
  );

  const submitFormula = () => {
    if (!sendEnabled) {
      return;
    }

    submit({
      message: objectFormula.formulas
        .map(formula =>
          getStringFormula(formula.parentFormula.mainFormula.symbols),
        )
        .join('\n'),
    });
    setObjectFormula({
      formulas: [EMPTY_FORMULA],
    });
    setPath(['formulas[0]']);
  };

  return (
    <div className="flex flex-col gap-2">
      <Formulas
        objectFormula={objectFormula}
        setObjectFormula={setObjectFormula}
        path={path}
        setPath={setPath}
        className="h-[90px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm overflow-auto"
        placeholder={placeholder}
        breakpoint={breakpoint}
      />
      <div className="w-full">
        <Symbols
          setObjectFormula={setObjectFormula}
          setPath={setPath}
          path={path}
          additionalSymbols={symbols}
          objectFormula={objectFormula}
          breakpoint={breakpoint}
          chapterAsObject={chapterAsObject}
        />
      </div>
      {open &&
        chatActionsDomNode !== null &&
        createPortal(
          <Button disabled={!sendEnabled} onClick={submitFormula} size="sm">
            <FormattedMessage id="common.send" />
          </Button>,
          chatActionsDomNode,
        )}
    </div>
  );
};
