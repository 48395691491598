import { MathJax } from 'better-react-mathjax';
import { Eye } from 'lucide-react';
import { ReactElement } from 'react';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui';

export const Answer = ({ content }: { content: string }): ReactElement => (
  <>
    <div className="hidden md:block">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Eye />
          </TooltipTrigger>
          <TooltipContent>
            <div className="whitespace-pre-wrap">
              <MathJax dynamic>{content}</MathJax>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
    <div className="md:hidden">
      <Popover>
        <PopoverTrigger asChild>
          <Eye className="cursor-pointer" />
        </PopoverTrigger>
        <PopoverContent className="w-80">
          <div className="whitespace-pre-wrap">{content}</div>
        </PopoverContent>
      </Popover>
    </div>
  </>
);
