import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ChapterAsObject } from '@skloover/shared';

import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '~/components/ui';
import { AppRoute, trpc } from '~/lib';

type ContinueSessionProps = {
  session: { id: string; created: string } & Pick<
    ChapterAsObject,
    'subject' | 'chapter'
  >;
};

export const ContinueSession = ({
  session,
}: ContinueSessionProps): ReactElement => {
  const utils = trpc.useUtils();
  const { mutate, isPending } = trpc.sessionEnd.useMutation({
    onSuccess: async () => {
      await utils.startedSessionGet.invalidate();
    },
  });
  const endSession = () => {
    mutate({ sessionId: session.id });
  };

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="welcome.continueSession.title" />
        </CardTitle>
        <CardDescription>
          <FormattedMessage id="welcome.continueSession.subtitle" />
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col">
        <div>
          <FormattedMessage
            id={`common.chapterAsObject.chapter.${session.chapter}`}
          />
        </div>
        <div className="text-sm text-secondary-foreground">
          <FormattedDate value={session.created} />
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button variant="outline" onClick={endSession} disabled={isPending}>
          <FormattedMessage id="common.stop" />
        </Button>
        <Button asChild>
          <Link to={`${AppRoute.Session}/${session.id}`}>
            <FormattedMessage id="common.continue" />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};
