import { zodResolver } from '@hookform/resolvers/zod';
import { Lightbulb } from 'lucide-react';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';

import { Layout } from '~/components';
import { LoadingSpinner } from '~/components/icons/LoadingSpinner';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui';
import { trpc, useIntl } from '~/lib';

const formSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  gender: z.enum(['M', 'F']),
});

export const Users = (): ReactElement => {
  const t = useIntl();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      gender: 'M',
    },
  });

  const { mutate: createUser, isPending } = trpc.userCreate.useMutation({
    onSuccess: () => form.reset(),
  });

  const submit = ({
    firstName,
    lastName,
    email,
    gender,
  }: z.infer<typeof formSchema>) => {
    createUser({ firstName, lastName, email, gender });
  };

  return (
    <Layout>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submit)}>
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="flex-grow">
                <FormControl>
                  <Input placeholder="John" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="flex-grow">
                <FormControl>
                  <Input placeholder="Doe" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="flex-grow">
                <FormControl>
                  <Input placeholder="john.doe@gmail.com" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="gender"
            render={({ field }) => (
              <FormItem className="flex items-center space-y-0 space-x-2">
                <FormLabel htmlFor="gender">
                  <FormattedMessage id="common.gender" />
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      id="gender"
                      className="whitespace-normal [&>span]:text-left [&>svg]:shrink-0"
                    >
                      <SelectValue
                        placeholder={t.formatMessage({
                          id: 'common.select',
                        })}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent
                    position="popper"
                    className="max-w-[var(--radix-select-trigger-width)]"
                  >
                    <SelectItem value="M" key="M" className="max-w-100">
                      <FormattedMessage id="common.male" />
                    </SelectItem>
                    <SelectItem value="F" key="F" className="max-w-100">
                      <FormattedMessage id="common.female" />
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <Button type="submit">
            {isPending ? (
              <LoadingSpinner className="h-4 w-4 animate-spin" />
            ) : (
              <Lightbulb className="h-4 w-4" />
            )}
          </Button>
        </form>
      </Form>
    </Layout>
  );
};
