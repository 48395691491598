/* eslint-disable max-lines */
export const vectors = {
  translations: [
    {
      type: 'definition',
      title: 'Notion de vecteur',
      subtitle: 'Translations et vecteurs',
      content: [
        {
          type: 'text',
          content: "Soient $M$ et $M'$ deux points distincts du plan.",
        },
        {
          type: 'text',
          content:
            "La translation qui transforme $M$ en $M'$ associe à tout point $N$ du plan, l’unique point $N'$ tel que $MM'N'N$ soit un parallélogramme.",
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            "La translation qui transforme $M$ en $M'$ est un déplacement caractérisé par :",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: "une direction : celle de la droite $(MM')$",
            },
            {
              type: 'text',
              content: "un sens : celui de $M$ vers $M'$",
            },
            {
              type: 'text',
              content: "une longueur : la longueur $MM'$",
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=left:\\textcolor{red}{M}] (M) at (0,0); \
            \\coordinate[label=above:\\textcolor{red}{M'}] (M') at (2.5,1.5); \
            \\coordinate[label=below:\\textcolor{blue}{N}] (N) at (1.5,-1); \
            \\coordinate[label=right:\\textcolor{blue}{N'}] (N') at (4,0.5); \
            \\draw[->, thick, red] (M) -- (M'); \
            \\draw[->, thick, blue] (N) -- (N'); \
            \\draw[dotted] (M) -- (N); \
            \\draw[dotted] (M') -- (N'); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content:
            "La translation qui transforme $M$ en $M'$ transforme aussi $N$ en $N'$. Le quadrilatère $MM'N'N$ est un parallélogramme.",
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            "La translation qui transforme $M$ en $M'$ est appelée la translation de vecteur $\\overrightarrow{MM'}$.",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Lorsque $M \\neq M'$ , le vecteur $\\overrightarrow{MM'}$ est représenté par une flèche allant de $M$ vers $M'$. Il est défini par sa direction, son sens et sa longueur (aussi appelée sa norme).",
            },
            {
              type: 'text',
              content:
                "Le point $M$ est l’origine du vecteur $\\overrightarrow{MM'}$ ; le point $M'$ est l’extrémité.",
            },
            {
              type: 'text',
              content:
                'Par convention, on appelle vecteur nul, noté $\\vec{0}$ , tout vecteur dont l’origine et l’extrémité sont confondues, par exemple $\\overrightarrow{MM}$.',
            },
            {
              type: 'text',
              content:
                'La translation de vecteur nul transforme tout point en lui-même.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate (A) at (0,0); \
            \\coordinate [label=above:\\textcolor{red}{M}] (M) at (1,0.25); \
            \\coordinate[label=above:\\textcolor{red}{M'}] (M') at (3,0.75); \
            \\coordinate (B) at (4,1); \
            \\draw[dotted] (A) -- (M); \
            \\draw[->, thick, red] (M) -- (M'); \
            \\draw[dotted] (M') -- (B); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content:
            "Le vecteur $\\overrightarrow{MM'}$ a les caractéristiques suivantes :",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: "pour direction, la droite $(MM')$",
            },
            {
              type: 'text',
              content: "pour sens, celui de $M$ vers $M'$ (sens de la flèche)",
            },
            {
              type: 'text',
              content: "pour norme, la longueur $MM'$",
            },
          ],
        },
      ],
    },
  ],
  equality: [
    {
      type: 'definition',
      title: 'Notion de vecteur',
      subtitle: 'Égalité de deux vecteurs',
      content: [
        {
          type: 'text',
          content:
            'Deux vecteurs non nuls $\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ sont égaux s’ils ont la même direction, le même sens et la même norme.',
        },
        {
          type: 'text',
          content: 'On écrit $\\overrightarrow{AB} = \\overrightarrow{CD}$.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Conséquence',
      content: [
        {
          type: 'text',
          content:
            'L’égalité des vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ signifie que $D$ est l’image de $C$ par la translation de vecteur $\\overrightarrow{AB}$.',
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'Deux vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ sont égaux si et seulement si le quadrilatère $ABDC$ est un parallélogramme (éventuellement aplati).',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "L'égalité $\\overrightarrow{AB} = \\overrightarrow{BC}$ équivaut à dire que $B$ est le milieu de $[AC]$.",
            },
            {
              type: 'text',
              content:
                'On considère un vecteur $\\overrightarrow{AB}$ :\n\
On peut tracer à partir de n’importe quel point du plan un vecteur égal à $\\overrightarrow{AB}$, par exemple $\\overrightarrow{CD}$, $\\overrightarrow{EF}$, $\\overrightarrow{GH}$, etc.\n\
Tous ces vecteurs sont donc égaux. On peut alors utiliser une unique lettre pour caractériser ce vecteur, par exemple : $\\vec{u}$.\n\
Ainsi, $\\overrightarrow{AB} = \\overrightarrow{CD} = \\overrightarrow{EF} = \\overrightarrow{GH} = \\vec{u}$.\n\
Les vecteurs $\\overrightarrow{AB}$, $\\overrightarrow{CD}$, $\\overrightarrow{EF}$, $\\overrightarrow{GH}$ sont parfois appelés des représentants du vecteur $\\vec{u}$.',
            },
          ],
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
          \\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=above:\\textcolor{black}{A}] (A) at (0,3); \
            \\coordinate[label=below:\\textcolor{black}{B}] (B) at (1,0); \
            \\coordinate [label=above:\\textcolor{black}{C}] (C) at (1.5,3); \
            \\coordinate[label=below:\\textcolor{black}{D}] (D) at (2.5,0); \
            \\coordinate [label=above:\\textcolor{black}{E}] (E) at (3,3); \
            \\coordinate[label=below:\\textcolor{black}{F}] (F) at (4,0); \
            \\coordinate [label=above:\\textcolor{black}{G}] (G) at (5.5,3); \
            \\coordinate[label=below:\\textcolor{black}{H}] (H) at (6.5,0); \
            \\coordinate (I) at (7,3); \
            \\coordinate (J) at (8,0); \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\fill (C) circle(0.05); \
            \\fill (D) circle(0.05); \
            \\fill (E) circle(0.05); \
            \\fill (F) circle(0.05); \
            \\fill (G) circle(0.05); \
            \\fill (H) circle(0.05); \
            \\draw[->, thick, blue] (A) -- (B); \
            \\draw[->, thick, blue] (C) -- (D); \
            \\draw[->, thick, blue] (E) -- (F); \
            \\draw[->, thick, blue] (G) -- (H); \
            \\draw[->, thick, red] (I) -- (J); \
            \\node[right,red] at ($(A)!0.5!(B)$) {$\\vec{u}$}; \
            \\node[right,red] at ($(C)!0.5!(D)$) {$\\vec{u}$}; \
            \\node[right,red] at ($(E)!0.5!(F)$) {$\\vec{u}$}; \
            \\node[right,red] at ($(G)!0.5!(H)$) {$\\vec{u}$}; \
            \\node[right,red] at ($(I)!0.5!(J)$) {$\\vec{u}$}; \
          \\end{tikzpicture} \
          \\end{document}`,
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray, step=0.5] (-0.7,-0.7) grid (4.7,4.7); \
            \\coordinate [label=left:\\textcolor{black}{E}] (E) at (0,2); \
            \\coordinate[label=below:\\textcolor{black}{F}] (F) at (1.5,1); \
            \\coordinate[label=above left:\\textcolor{black}{G}] (G) at (1,3); \
            \\coordinate[label=above right:\\textcolor{black}{H}] (H) at (2.5,2); \
            \\coordinate[label=above right:\\textcolor{black}{I}] (I) at (4,1); \
            \\fill (E) circle(0.05); \
            \\fill (F) circle(0.05); \
            \\fill (G) circle(0.05); \
            \\fill (H) circle(0.05); \
            \\fill (I) circle(0.05); \
            \\draw[->, thick, red] (E) -- (F); \
            \\draw[->, thick, red] (G) -- (H); \
            \\draw[->, thick, red] (H) -- (I); \
            \\draw[dotted] (E) -- (G); \
            \\draw[dotted] (F) -- (H); \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '$\\overrightarrow{EF} = \\overrightarrow{GH}$ équivaut à $EFHG$ est un parallélogramme.',
            },
            {
              type: 'text',
              content:
                '$\\overrightarrow{GH} = \\overrightarrow{HI}$ ; ainsi $H$ est le milieu du segment $[GI]$.',
            },
          ],
        },
      ],
    },
  ],
  chasles: [
    {
      type: 'definition',
      title: 'Somme de deux vecteurs',
      subtitle: 'Définition et relation de Chasles',
      content: [
        {
          type: 'text',
          content:
            'La somme de deux vecteurs $\\vec{u}$ et $\\vec{v}$ est le vecteur $\\vec{w}$ associé à la translation qui résulte de l’enchaînement des translations de vecteur $\\vec{u}$ et de vecteur $\\vec{v}$.',
        },
        {
          type: 'text',
          content: 'On écrit $\\vec{w} = \\vec{u} + \\vec{v}$.',
        },
      ],
    },
    {
      type: 'property',
      header: 'Relation de Chasles',
      content: [
        {
          type: 'text',
          content:
            'On considère deux vecteurs $\\vec{u}$ et $\\vec{v}$ et trois points $A, B$ et $C$ tels que $\\vec{u} = \\overrightarrow{AB}$ et $\\vec{v} = \\overrightarrow{BC}$. On a alors $\\overrightarrow{AB} + \\overrightarrow{BC} = \\overrightarrow{AC}$.',
        },
        {
          type: 'text',
          content: 'Cette égalité s’appelle la relation de Chasles.',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
          \\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=above left:\\textcolor{black}{A}] (A) at (0,3); \
            \\coordinate[label=below:\\textcolor{black}{B}] (B) at (2,0); \
            \\coordinate [label=above right:\\textcolor{black}{C}] (C) at (3,2); \
            \\draw[->, thick, blue] (A) -- (B); \
            \\draw[->, thick, green!80!black] (B) -- (C); \
            \\draw[->, thick, red] (A) -- (C); \
            \\node[below left,blue] at ($(A)!0.5!(B)$) {$\\vec{u}$}; \
            \\node[below right,green!80!black] at ($(B)!0.5!(C)$) {$\\vec{v}$}; \
            \\node[above right,red] at ($(A)!0.5!(C)$) {$\\vec{w} = \\vec{u} + \\vec{v}$}; \
          \\end{tikzpicture} \
          \\end{document}`,
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Pour tous vecteurs $\\vec{u}, \\vec{v}$ et $\\vec{w}$, on a :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '$\\vec{u} + \\vec{v} = \\vec{v} + \\vec{u}$',
            },
            {
              type: 'text',
              content: '$\\vec{u} + \\vec{0} = \\vec{u}$',
            },
            {
              type: 'text',
              content:
                '$\\vec{u} + (\\vec{v} + \\vec{w}) = (\\vec{u} + \\vec{v}) + \\vec{w}$',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Soient $M$, $N$ et $P$ trois points quelconques.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '$\\overrightarrow{MN} + \\overrightarrow{NP} = \\overrightarrow{MP}$',
            },
            {
              type: 'text',
              content:
                '$\\overrightarrow{MP} + \\overrightarrow{PN} + \\overrightarrow{NM} = \\overrightarrow{MN} + \\overrightarrow{NM} = \\overrightarrow{MM} = \\vec{0}$',
            },
          ],
        },
      ],
    },
  ],
  opposite: [
    {
      type: 'definition',
      title: 'Somme de deux vecteurs',
      subtitle: "Opposé d'un vecteur",
      content: [
        {
          type: 'text',
          content:
            'L’opposé d’un vecteur $\\vec{u}$ du plan est le vecteur, noté $-\\vec{u}$, tel que $\\vec{u} + (-\\vec{u}) = \\vec{0}$.',
        },
        {
          type: 'text',
          content:
            'Un vecteur non nul et son opposé ont la même direction, la même norme, mais ont des sens contraires.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Soient $A$, $B$ et $C$ trois points.',
        },
        {
          type: 'text',
          content:
            'On a $\\overrightarrow{AB} + \\overrightarrow{BA} = \\overrightarrow{AA} = \\overrightarrow{0}$, ainsi les vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{BA}$ sont opposés.',
        },
        {
          type: 'text',
          content: 'On a $\\overrightarrow{BA} = -\\overrightarrow{AB}$.',
        },
      ],
    },
  ],
  real_product: [
    {
      type: 'definition',
      title: 'Produit d’un vecteur par un réel',
      subtitle: 'Définition et propriété',
      content: [
        {
          type: 'text',
          content:
            'Soient $\\overrightarrow{AB}$ un vecteur non nul du plan et $k$ un nombre réel non nul.',
        },
        {
          type: 'text',
          content:
            '$k\\overrightarrow{AB}$ est le vecteur qui a la même direction que $\\overrightarrow{AB}$, le même sens que $\\overrightarrow{AB}$ lorsque $k>0$, un sens contraire lorsque $k<0$ et pour norme $|k| \\times AB$.',
        },
        {
          type: 'text',
          content: 'De plus :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: 'Quel que soit le réel $k$ : $k\\vec{0} = \\vec{0}$',
            },
            {
              type: 'text',
              content:
                'Quel que soit le vecteur $\\vec{u} : 0\\vec{u} = \\vec{0}$',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Soit $M$ le milieu d’un segment $[AB]$.',
        },
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=above:\\textcolor{black}{$A$}] (A) at (0,0); \
            \\coordinate [label=above:\\textcolor{black}{$M$}] (M) at (3,0); \
            \\coordinate [label=above:\\textcolor{black}{$B$}] (B) at (6,0); \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\fill (M) circle(0.05); \
            \\draw[->, thick, red] (A) -- (B); \
            \\draw[->, thick, red] (A) -- (M); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Le vecteur $\\overrightarrow{AM}$ a la même direction que $\\overrightarrow{AB}$, le même sens que $\\overrightarrow{AB}$ et pour norme $AM = \\dfrac{1}{2}AB$.',
        },
        {
          type: 'text',
          content:
            'On a donc $\\overrightarrow{AM} = \\dfrac{1}{2}\\overrightarrow{AB}$.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            "Pour tous vecteurs $\\vec{u}$ et $\\vec{v}$ et tous réels $k$ et $k'$, on a:",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '$k(\\vec{u} + \\vec{v}) = k\\vec{u} + k\\vec{v}$',
            },
            {
              type: 'text',
              content: '$k(\\vec{u} - \\vec{v}) = k\\vec{u} - k\\vec{v}$',
            },
            {
              type: 'text',
              content: "$(k + k')\\vec{u} = k\\vec{u} + k'\\vec{u}$",
            },
            {
              type: 'text',
              content: "$(kk')\\vec{u} = k(k'\\vec{u}) = k'(k\\vec{u})$",
            },
            {
              type: 'text',
              content:
                '$k\\vec{u} = \\vec{0}$ si et seulement si $k = 0$ ou $\\vec{u} = \\vec{0}$',
            },
          ],
        },
      ],
    },
  ],
  colinearity: [
    {
      type: 'definition',
      title: 'Produit d’un vecteur par un réel',
      subtitle: 'Vecteurs colinéaires',
      content: [
        {
          type: 'text',
          content:
            'Deux vecteurs $\\vec{u}$ et $\\vec{v}$ non nuls sont colinéaires lorsqu’il existe un nombre réel $k$ non nul tel que $\\vec{u} = k\\vec{v}$.',
        },
        {
          type: 'text',
          content:
            '$\\vec{u}$ et $\\vec{v}$ sont colinéaires signifie donc qu’ils ont la même direction.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=above:\\textcolor{black}{$A$}] (A) at (0,0); \
            \\coordinate [label=above:\\textcolor{black}{$M$}] (M) at (3,0); \
            \\coordinate [label=above:\\textcolor{black}{$B$}] (B) at (6,0); \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\fill (M) circle(0.05); \
            \\draw[->, thick, red] (A) -- (B); \
            \\draw[->, thick, red] (A) -- (M); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Ci-dessus $\\overrightarrow{AM} = \\frac{1}{2} \\overrightarrow{AB}$, les vecteurs $\\overrightarrow{AM}$ et $\\overrightarrow{AB}$ sont donc colinéaires.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Deux droites $(AB)$ et $(CD)$ sont parallèles si et seulement si les vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ sont colinéaires.',
            },
            {
              type: 'text',
              content:
                'Trois points $A$, $B$ et $C$ sont alignés si et seulement si les vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{AC}$ sont colinéaires.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'On admet que le vecteur nul est colinéaire à tous les vecteurs, en raison de l’égalité : \\[ \\vec{0} = 0\\vec{u} \\]',
        },
      ],
    },
  ],
  orthonormal: [
    {
      type: 'definition',
      title: 'Coordonnées d’un vecteur',
      subtitle: 'Repère orthonormé',
      content: [
        {
          type: 'text',
          content:
            'Un repère du plan, défini par trois points non alignés $O$, $I$ et $J$, est orthonormé lorsque le triangle $OIJ$ est rectangle isocèle en $O$.',
        },
        {
          type: 'text',
          content:
            "Le point $O$ est l'origine du repère, la droite $(OI)$ est l'axe des abscisses et la droite $(OJ)$ est l'axe des ordonnées.",
        },
        {
          type: 'text',
          content: 'On a $OI = OJ = 1$ unité.',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Le repère se note $(O; I; J)$ ou $(O; \\overrightarrow{OI}; \\overrightarrow{OJ})$. En posant $\\overrightarrow{OI} = \\vec{i}$ et $\\overrightarrow{OJ} = \\vec{j}$, le repère $(O; I; J)$ peut aussi s'écrire $(O; \\vec{i}; \\vec{j}).$",
            },
            {
              type: 'text',
              content:
                "Dans un repère, tout point $M$ est repéré par un unique couple de nombres réels $(x_M; y_M)$, appelé coordonnées du point $M$. On écrit : $M(x_M; y_M)$. $x_M$ est l'abscisse de $M$ et $y_M$ est l'ordonnée de $M$.",
            },
          ],
        },
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-1.2,-1.2) grid (3.2,3.2); \
            \\draw[->, very thin] (-1.2,0) -- (3.2,0); \
            \\draw[->, very thin] (0,-1.2) -- (0,3.2); \
            \\node [left] at (0,3.2) {$y$}; \
            \\node [below] at (3.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate [label=left:\\textcolor{green!80!black}{$y_M$}] (Y) at (0,1.5); \
            \\coordinate [label=below:\\textcolor{blue}{$x_M$}] (X) at (2.2, 0); \
            \\coordinate[label=above right:\\textcolor{black}{$M$}] (M) at (2.2, 1.5); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\fill (M) circle(0.05); \
            \\fill (I) circle(0.05); \
            \\fill (J) circle(0.05); \
            \\draw[dotted, blue] (X) -- (M); \
            \\draw[dotted, green!80!black] (Y) -- (M); \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-1.2,-1.2) grid (4.2,3.2); \
            \\draw[->, very thin] (-1.2,0) -- (4.2,0); \
            \\draw[->, very thin] (0,-1.2) -- (0,3.2); \
            \\node [left] at (0,3.2) {$y$}; \
            \\node [below] at (4.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate [label=left:\\textcolor{green!80!black}{$2$}] (Y) at (0,2); \
            \\coordinate [label=below:\\textcolor{blue}{$3$}] (X) at (3, 0); \
            \\coordinate[label=above right:\\textcolor{black}{$M$}] (M) at (3, 2); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\fill (M) circle(0.05); \
            \\draw[thick, blue] (X) -- (M); \
            \\draw[thick, green!80!black] (Y) -- (M); \
            \\draw[->, thick, red] (O) -- (I); \
            \\draw[->, thick, red] (O) -- (J); \
            \\node[left,red] at ($(O)!0.5!(J)$) {$\\vec{j}$}; \
            \\node[below,red] at ($(O)!0.5!(I)$) {$\\vec{i}$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Le triangle $OIJ$ est rectangle et isocèle en $O$ donc le repère $(O; I; J)$ est orthonormé.',
        },
        {
          type: 'text',
          content:
            'En posant $\\vec{i} = \\overrightarrow{OI}$ et $\\vec{j} = \\overrightarrow{OJ}$, le repère se note également $(O; \\vec{i}; \\vec{j})$.',
        },
        {
          type: 'text',
          content: 'Dans ce repère, le point $M$ a pour coordonnées $(3;2)$.',
        },
      ],
    },
  ],
  coordinates: [
    {
      type: 'property',
      title: 'Coordonnées d’un vecteur',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Pour tout point $M(x;y)$ dans un repère $(O;\\vec{i};\\vec{j})$, on a : \\[ \\overrightarrow{OM} = x\\vec{i} + y\\vec{j} \\]\nOn dit que le vecteur $\\overrightarrow{OM}$ a pour coordonnées \\( \\begin{pmatrix} x \\\\ y \\end{pmatrix} \\) dans la base $(\\vec{i};\\vec{j})$.',
            },
            {
              type: 'text',
              content:
                'Pour tout vecteur $\\vec{u}$ dans un repère $(O;\\vec{i};\\vec{j})$, il existe un unique couple de réels $(x;y)$ tel que $\\vec{u} = x\\vec{i} + y\\vec{j}$.\nOn dit que le vecteur $\\vec{u}$ a pour coordonnées \\( \\begin{pmatrix} x \\\\ y \\end{pmatrix} \\) dans la base $(\\vec{i};\\vec{j})$.',
            },
          ],
        },
      ],
    },

    {
      type: 'simpleText',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-4.2,-1.2) grid (4.2,3.2); \
            \\draw[->, very thin] (-4.2,0) -- (4.2,0); \
            \\draw[->, very thin] (0,-1.2) -- (0,3.2); \
            \\node [left] at (0,3.2) {$y$}; \
            \\node [below] at (4.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate [label=left:\\textcolor{green!80!black}{$y$}] (Y) at (0,2); \
            \\coordinate [label=below:\\textcolor{blue}{$x$}] (X) at (3, 0); \
            \\coordinate[label=above right:\\textcolor{black}{$M$}] (M) at (3, 2); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\coordinate (A) at (-4,1); \
            \\coordinate (B) at (-1,3); \
            \\coordinate (C) at (-1,1); \
            \\fill (M) circle(0.05); \
            \\draw[->, dotted, blue] (X) -- (M); \
            \\draw[->, dotted, green!80!black] (Y) -- (M); \
            \\draw[->, dotted, blue] (A) -- (C); \
            \\draw[->, dotted, green!80!black] (C) -- (B); \
            \\draw[->, thick, red] (O) -- (I); \
            \\draw[->, thick, red] (O) -- (J); \
            \\draw[->, thick, red] (O) -- (M); \
            \\draw[->, thick, red] (A) -- (B); \
            \\node[left,green!80!black] at ($(C)!0.5!(B)$) {$y\\vec{j}$}; \
            \\node[below,blue] at ($(A)!0.5!(C)$) {$x\\vec{i}$}; \
            \\node[above left,red] at ($(A)!0.5!(B)$) {$\\vec{u}$}; \
            \\node[left,red] at ($(O)!0.5!(J)$) {$\\vec{j}$}; \
            \\node[below,red] at ($(O)!0.5!(I)$) {$\\vec{i}$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration du second point',
      content: [
        {
          type: 'text',
          content:
            'Soit $\\vec{u}$ un vecteur du plan rapporté à un repère $(O;\\vec{i};\\vec{j})$.',
        },
        {
          type: 'text',
          content:
            'Il existe alors un unique point $M$ dans le repère $(O;\\vec{i};\\vec{j})$ tel que $\\vec{u} = \\overrightarrow{OM}$.',
        },
        {
          type: 'text',
          content:
            'Les coordonnées $(x;y)$ du point $M$ dans le repère $(O;\\vec{i};\\vec{j})$ sont uniques et on a $\\overrightarrow{OM} = x\\vec{i} + y\\vec{j}$.',
        },
        {
          type: 'text',
          content:
            'Ainsi il existe un unique couple de réels $(x;y)$ tel que $\\vec{u} = x\\vec{i} + y\\vec{j}$.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            "Soient $\\vec{u} \\begin{pmatrix} x \\\\ y \\end{pmatrix}$ et $\\vec{v} \\begin{pmatrix} x' \\\\ y' \\end{pmatrix}$ dans un repère $(O; \\vec{i}; \\vec{j})$ et $k$ un réel.",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "$\\vec{u} = \\vec{v}$ si et seulement si $x = x'$ et $y = y'$.",
            },
            {
              type: 'text',
              content:
                "Le vecteur $\\vec{u} + \\vec{v}$ a pour coordonnées \\( \\begin{pmatrix} x+x' \\\\ y+y' \\end{pmatrix} \\).",
            },
            {
              type: 'text',
              content:
                'Le vecteur $k\\vec{u}$ a pour coordonnées \\( \\begin{pmatrix} kx \\\\ ky \\end{pmatrix} \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Soient $A(x_A;y_A)$ et $B(x_B;y_B)$ dans un repère $(O; \\vec{i}; \\vec{j})$.',
        },
        {
          type: 'text',
          content:
            'Le vecteur $\\overrightarrow{AB}$ a pour coordonnées $\\begin{pmatrix} x_B-x_A \\\\ y_B-y_A \\end{pmatrix}$.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray, step=0.5] (-0.6,-0.6) grid (4.1,2.6); \
            \\draw[->, very thin] (-0.6,0) -- (4.1,0); \
            \\draw[->, very thin] (0,-0.6) -- (0,2.6); \
            \\node [left] at (0,2.6) {$y$}; \
            \\node [below] at (4.1, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate [label=below left:\\textcolor{black}{$A$}] (A) at (1.5,0.5); \
            \\coordinate [label=above right:\\textcolor{black}{$B$}] (B) at (4,1.5); \
            \\coordinate [label=above right:\\textcolor{black}{$C$}] (C) at (2,1.5); \
            \\coordinate [label=below left:\\textcolor{black}{$D$}] (D) at (0.5,1); \
            \\coordinate (CD) at (0.5,1.5); \
            \\coordinate (AB) at (4,0.5); \
            \\coordinate (J) at (0,0.5); \
            \\coordinate (I) at (0.5,0); \
            \\draw[->, thick, red] (O) -- (I); \
            \\draw[->, thick, red] (O) -- (J); \
            \\node[left,red] at ($(O)!0.5!(J)$) {$\\vec{j}$}; \
            \\node[below,red] at ($(O)!0.5!(I)$) {$\\vec{i}$}; \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\fill (C) circle(0.05); \
            \\fill (D) circle(0.05); \
            \\draw[->, thick, red] (A) -- (B); \
            \\draw[->, thick, red] (C) -- (D); \
            \\draw[->, densely dotted, green!80!black] (CD) -- (D); \
            \\draw[->, densely dotted, green!80!black] (AB) -- (B); \
            \\draw[->, densely dotted, blue] (A) -- (AB); \
            \\draw[->, densely dotted, blue] (C) -- (CD); \
            \\node[left,green!80!black] at ($(CD)!0.5!(D)$) {$-1$}; \
            \\node[left,green!80!black] at ($(AB)!0.5!(B)$) {$+2$}; \
            \\node[above,blue] at ($(CD)!0.5!(C)$) {$-3$}; \
            \\node[below,blue] at ($(AB)!0.5!(A)$) {$+5$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '$\\overrightarrow{AB}\\begin{pmatrix} x_B-x_A \\\\ y_B-y_A \\end{pmatrix}$, soit $\\overrightarrow{AB}\\begin{pmatrix} 8-3 \\\\ 3-1 \\end{pmatrix}$, soit $\\overrightarrow{AB}\\begin{pmatrix} 5 \\\\ 2 \\end{pmatrix}$',
            },
            {
              type: 'text',
              content:
                '$\\overrightarrow{CD}\\begin{pmatrix} x_D-x_C \\\\ y_D-y_C \\end{pmatrix}$, soit $\\overrightarrow{CD}\\begin{pmatrix} 1-4 \\\\ 2-3 \\end{pmatrix}$, soit $\\overrightarrow{CD}\\begin{pmatrix} -3 \\\\ -1 \\end{pmatrix}$',
            },
          ],
        },
      ],
    },
  ],
  colinearity_criteria: [
    {
      type: 'property',
      title: 'Coordonnées d’un vecteur',
      subtitle: 'Critère de colinéarité de deux vecteurs',
      content: [
        {
          type: 'text',
          content:
            "Soient $\\vec{u}\\begin{pmatrix} x \\\\ y \\end{pmatrix}$ et $\\vec{v}\\begin{pmatrix} x' \\\\ y' \\end{pmatrix}$ deux vecteurs dans un repère $(O; \\vec{i}; \\vec{j})$.",
        },
        {
          type: 'text',
          content:
            "Les vecteurs $\\vec{u}$ et $\\vec{v}$ sont colinéaires si et seulement si leurs coordonnées sont proportionnelles, c'est-à-dire si et seulement s'il existe un réel $k$ tel que $x' = kx$ et $y' = ky$.",
        },
        {
          type: 'text',
          content: 'Dans ce cas on a alors $\\vec{v} = k\\vec{u}$.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray, step=0.5] (-0.6,-0.6) grid (4.1,2.6); \
            \\draw[->, very thin] (-0.6,0) -- (4.1,0); \
            \\draw[->, very thin] (0,-0.6) -- (0,2.6); \
            \\node [left] at (0,2.6) {$y$}; \
            \\node [below] at (4.1, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate [label=below left:\\textcolor{black}{$A$}] (A) at (1.5,0.5); \
            \\coordinate [label=above right:\\textcolor{black}{$B$}] (B) at (4,1.5); \
            \\coordinate [label=above right:\\textcolor{black}{$C$}] (C) at (2,1.5); \
            \\coordinate [label=below left:\\textcolor{black}{$D$}] (D) at (0.5,1); \
            \\coordinate (CD) at (0.5,1.5); \
            \\coordinate (AB) at (4,0.5); \
            \\coordinate (J) at (0,0.5); \
            \\coordinate (I) at (0.5,0); \
            \\draw[->, thick, red] (O) -- (I); \
            \\draw[->, thick, red] (O) -- (J); \
            \\node[left,red] at ($(O)!0.5!(J)$) {$\\vec{j}$}; \
            \\node[below,red] at ($(O)!0.5!(I)$) {$\\vec{i}$}; \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\fill (C) circle(0.05); \
            \\fill (D) circle(0.05); \
            \\draw[->, thick, red] (A) -- (B); \
            \\draw[->, thick, red] (C) -- (D); \
            \\draw[->, densely dotted, green!80!black] (CD) -- (D); \
            \\draw[->, densely dotted, green!80!black] (AB) -- (B); \
            \\draw[->, densely dotted, blue] (A) -- (AB); \
            \\draw[->, densely dotted, blue] (C) -- (CD); \
            \\node[left,green!80!black] at ($(CD)!0.5!(D)$) {$-1$}; \
            \\node[left,green!80!black] at ($(AB)!0.5!(B)$) {$+2$}; \
            \\node[above,blue] at ($(CD)!0.5!(C)$) {$-3$}; \
            \\node[below,blue] at ($(AB)!0.5!(A)$) {$+5$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Soient les vecteurs $\\overrightarrow{AB}\\begin{pmatrix} 5 \\\\ 2 \\end{pmatrix}$ et $\\overrightarrow{CD}\\begin{pmatrix} -3 \\\\ -1 \\end{pmatrix}$.',
        },
        {
          type: 'text',
          content:
            '$\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ ne sont pas colinéaires car les équations $-3 = k \\times 5$ et $-1 = k \\times 2$ ont des solutions différentes $-0,6$ et $-0,5$.',
        },
        {
          type: 'text',
          content:
            'Les coordonnées de $\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ ne sont donc pas proportionnelles : ces deux vecteurs ne sont pas colinéaires.',
        },
      ],
    },
  ],
} as const;
