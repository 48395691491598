/* eslint-disable max-lines */

/* eslint-disable complexity */
import get from 'lodash/get';
import {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
} from 'react';

import {
  LeftBracket,
  LeftParenthesis,
  RightBracket,
  RightParenthesis,
  SquareRoot as SquareRootSVG,
} from '~/components/icons';
import { cn } from '~/lib';

import { BasicFormula } from './BasicFormula';
import {
  BRACKETS,
  COORDINATES,
  EXPOSANT,
  Formula as FormulaType,
  FRACTION,
  getFunctionSymbol,
  INDEX,
  isFunctionType,
  ObjectFormula,
  OVERLINE,
  PARENTHESIS,
  Path,
  SQUARE_ROOT,
  TEXT,
  VECTOR,
} from './constants';
import { Coordinates } from './Coordinates';
import { Exposant } from './Exposant';
import { Fraction } from './Fraction';
import { Function } from './Function';
import { InFormulaSvg } from './InFormulaSvg';
import { Overline } from './Overline';
import { Text } from './Text';
import { Vector } from './Vector';

type FormulaProps = {
  objectFormula: ObjectFormula;
  path: Path;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  formulaPath: string;
  mathJaxContentPlaceholder?: string;
  className?: string;
};

const SquareRootLeftSvg = ({ height }: { height?: number }) => (
  <div className="flex">
    <SquareRootSVG height={height} />
    <LeftParenthesis height={height} />
  </div>
);

export const Formula = ({
  objectFormula,
  path,
  setPath,
  formulaPath,
  mathJaxContentPlaceholder = '',
  setObjectFormula,
  children,
  className,
}: PropsWithChildren<FormulaProps>): ReactElement => {
  const currentFormula = get(
    objectFormula,
    formulaPath,
  ) as unknown as FormulaType;

  const placeholder =
    currentFormula.parentFormula.mainFormula.symbols.length === 0 &&
    currentFormula.childFormula === undefined
      ? mathJaxContentPlaceholder
      : undefined;

  return (
    <div className={`flex items-center gap-2 relative ${cn(className)}`}>
      {![EXPOSANT, INDEX].includes(currentFormula.childFormula?.type ?? '') && (
        <BasicFormula
          symbols={currentFormula.parentFormula.mainFormula.symbols}
          mathJaxContentPlaceholder={placeholder}
        />
      )}
      {currentFormula.childFormula?.type === FRACTION && (
        <Fraction
          path={path}
          setPath={setPath}
          fractionPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
        />
      )}
      {currentFormula.childFormula?.type === EXPOSANT && (
        <Exposant
          path={path}
          setPath={setPath}
          exposantPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
          exposantPlace="up"
          basicFormula={
            <BasicFormula
              symbols={currentFormula.parentFormula.mainFormula.symbols}
              mathJaxContentPlaceholder={placeholder}
            />
          }
        />
      )}
      {currentFormula.childFormula?.type === INDEX && (
        <Exposant
          path={path}
          setPath={setPath}
          exposantPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
          exposantPlace="down"
          basicFormula={
            <BasicFormula
              symbols={currentFormula.parentFormula.mainFormula.symbols}
              mathJaxContentPlaceholder={placeholder}
            />
          }
        />
      )}
      {isFunctionType(currentFormula.childFormula?.type) && (
        <Function
          path={path}
          setPath={setPath}
          functionPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
          functionSymbol={getFunctionSymbol(currentFormula.childFormula.type)}
        />
      )}
      {currentFormula.childFormula?.type === SQUARE_ROOT && (
        <InFormulaSvg
          path={path}
          setPath={setPath}
          inFormulaPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
          leftSvg={<SquareRootLeftSvg />}
          rightSvg={<RightParenthesis />}
          placeholder="x"
        />
      )}
      {currentFormula.childFormula?.type === PARENTHESIS && (
        <InFormulaSvg
          path={path}
          setPath={setPath}
          inFormulaPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
          leftSvg={<LeftParenthesis />}
          rightSvg={<RightParenthesis />}
        />
      )}
      {currentFormula.childFormula?.type === BRACKETS && (
        <InFormulaSvg
          path={path}
          setPath={setPath}
          inFormulaPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
          leftSvg={<LeftBracket />}
          rightSvg={<RightBracket />}
        />
      )}
      {currentFormula.childFormula?.type === OVERLINE && (
        <Overline
          path={path}
          setPath={setPath}
          overlinePath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
        />
      )}
      {currentFormula.childFormula?.type === TEXT && (
        <Text
          path={path}
          setPath={setPath}
          setObjectFormula={setObjectFormula}
        />
      )}
      {currentFormula.childFormula?.type === COORDINATES && (
        <Coordinates
          path={path}
          setPath={setPath}
          coordinatesPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
        />
      )}
      {currentFormula.childFormula?.type === VECTOR && (
        <Vector
          path={path}
          setPath={setPath}
          vectorPath={formulaPath}
          objectFormula={objectFormula}
          setObjectFormula={setObjectFormula}
        />
      )}
      {children}
    </div>
  );
};
