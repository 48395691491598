import last from 'lodash/last';
import { ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import {
  ChapterAsObject,
  ChatHistory,
  NEXT_PROBLEM,
  NEXT_QUESTION,
  Statement as StatementType,
} from '@skloover/shared';

import { Button, Textarea } from '~/components/ui';
import { useIntl } from '~/lib';

interface TextAnswerProps {
  chatHistory: ChatHistory;
  chapterAsObject: ChapterAsObject;
  questionStatement: Extract<StatementType, { type: 'question' }>;
  submit: ({ message }: { message: string }) => void;
  isChatUpdatePending: boolean;
  chatActionsDomNode: Element | null;
  open: boolean;
}

export const TextAnswer = ({
  chatHistory,
  chapterAsObject,
  questionStatement,
  submit,
  isChatUpdatePending,
  chatActionsDomNode,
  open,
}: TextAnswerProps): ReactElement => {
  const t = useIntl();
  const [message, setMessage] = useState<string>('');

  const isTextAreaDisabled = [
    NEXT_QUESTION[chapterAsObject.country],
    NEXT_PROBLEM[chapterAsObject.country],
  ].includes(last(chatHistory)?.[1] ?? '');

  const isTable = questionStatement.input?.type === 'table';

  const disableSubmit = message === '' || isChatUpdatePending;

  return (
    <>
      <Textarea
        disabled={isTextAreaDisabled}
        className="p-4"
        placeholder={
          isTable
            ? t.formatMessage({ id: 'common.askQuestion' })
            : t.formatMessage({ id: 'common.reply' })
        }
        value={message}
        onChange={e => setMessage(e.currentTarget.value)}
      />
      {open &&
        chatActionsDomNode !== null &&
        createPortal(
          <Button
            size="sm"
            disabled={disableSubmit}
            onClick={() => {
              submit({ message });
              setMessage('');
            }}
          >
            <FormattedMessage id="common.send" />
          </Button>,
          chatActionsDomNode,
        )}
    </>
  );
};
