import { MathJax } from 'better-react-mathjax';
import { Eye } from 'lucide-react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Statement as StatementType } from '@skloover/shared';

import { Button, Separator } from '~/components/ui';
import { Breakpoint } from '~/lib';

type HintsProps = {
  currentQuestionStatement: Extract<StatementType, { type: 'question' }>;
  blurredIndex: number;
  incrementBlurredIndex: () => void;
  showAnswer: boolean;
  setShowAnswer: (bool: boolean) => void;
  breakpoint: Breakpoint;
  enableShowAnswer: boolean;
};

export const Hints = ({
  currentQuestionStatement,
  blurredIndex,
  incrementBlurredIndex,
  showAnswer,
  setShowAnswer,
  breakpoint,
  enableShowAnswer,
}: HintsProps): ReactElement => (
  <>
    <div className="container py-4 px-8 text-sm flex flex-col space-y-4">
      <div className="text-sm font-bold">
        <FormattedMessage id="problems.followStepsLabel" />
      </div>
      <ol className="list-decimal ml-6 space-y-4">
        {currentQuestionStatement.solutions[0].steps
          .map(({ method }) => method)
          .map((method, index) => (
            <li
              key={index}
              className="relative"
              id={index === 0 ? `joyride-hints-${breakpoint}` : ''}
            >
              <div
                className={`whitespace-pre-wrap ${index >= blurredIndex ? 'blur ' : ''}${index === blurredIndex ? 'cursor-pointer' : ''}`}
              >
                <MathJax dynamic>{method}</MathJax>
              </div>
              {index === blurredIndex && (
                <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-around">
                  <Button variant="ghost" onClick={incrementBlurredIndex}>
                    <Eye />
                  </Button>
                </div>
              )}
            </li>
          ))}
      </ol>
    </div>
    <Separator />
    <div className="container py-4 px-8 text-sm flex flex-col space-y-4">
      <div className="text-sm font-bold">
        <FormattedMessage id="problems.answer" />
      </div>
      <div className="relative">
        <div
          className={`${!showAnswer ? 'blur ' : ''}${!showAnswer ? 'cursor-pointer' : ''}`}
        >
          <div className="whitespace-pre-wrap">
            <MathJax dynamic>
              {currentQuestionStatement.solutions[0].answers[0]}
            </MathJax>
          </div>
        </div>
        {!showAnswer && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-around">
            {enableShowAnswer ? (
              <Button
                variant="ghost"
                onClick={() => setShowAnswer(true)}
                disabled={!enableShowAnswer}
              >
                <Eye />
              </Button>
            ) : (
              <div className="text-sm font-bold italic text-muted-foreground">
                <FormattedMessage id="session.enableShowAnswer" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </>
);
