/* eslint-disable max-lines */

/* eslint-disable complexity */
import get from 'lodash/get';
import { Check } from 'lucide-react';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';

import { LeftParenthesis, RightParenthesis } from '~/components/icons';
import { Button } from '~/components/ui';

import {
  ExposantFormula,
  ObjectFormula,
  Path,
  selectedElementClassName,
} from './constants';
import { Formula } from './Formula';
import { useRefHeight } from './useRefHeight';
import { validateFormula } from './validateFormula';

type ExposantElementSide = 'leftFormula' | 'rightFormula';

type ExposantElementProps = {
  objectFormula: ObjectFormula;
  elementSide: ExposantElementSide;
  path: Path;
  exposantPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  rowStart: string;
  colStart: string;
  exposantPlace: 'up' | 'down';
};

const getOppositeElementSide = (childFormulaElementSide: string) => {
  switch (childFormulaElementSide) {
    case 'childFormula.leftFormula':
      return 'childFormula.rightFormula';
    case 'childFormula.rightFormula':
      return 'childFormula.leftFormula';
    default:
      return childFormulaElementSide;
  }
};

export const ExposantElement = ({
  objectFormula,
  exposantPath,
  elementSide,
  path,
  setPath,
  setObjectFormula,
  rowStart,
  colStart,
  exposantPlace,
}: ExposantElementProps): ReactElement => {
  const { ref, height } = useRefHeight();

  const editing = exposantPath === path.slice(0, -1).join('.');
  const selected =
    editing && path[path.length - 1] === `childFormula.${elementSide}`;
  const dynamicClassName = `${
    editing && 'hover:cursor-pointer hover:border-primary bg-gray-100'
  } ${selected ? 'border-primary' : 'border-transparent'}`;

  const onClick = () =>
    editing && !selected
      ? setPath(prevPath =>
          prevPath.slice(0, -1).concat(`childFormula.${elementSide}`),
        )
      : undefined;

  const getEmptyLabel = () => {
    switch (elementSide) {
      case 'leftFormula':
        return 'x';
      case 'rightFormula':
        return exposantPlace === 'up' ? 'y' : 'i';
    }
  };

  const gridClassName = `${rowStart} row-span-2 ${colStart}`;
  const className = `${selectedElementClassName} ${dynamicClassName}`;

  return (
    <div className={`${gridClassName} flex items-center justify-around gap-1`}>
      <div className="flex items-center gap-1">
        {elementSide === 'leftFormula' && exposantPlace === 'up' && (
          <LeftParenthesis height={height} />
        )}
        <div
          className={className}
          role="presentation"
          onClick={onClick}
          ref={ref}
        >
          <Formula
            objectFormula={objectFormula}
            path={path}
            setPath={setPath}
            formulaPath={`${exposantPath}.childFormula.${elementSide}`}
            mathJaxContentPlaceholder={getEmptyLabel()}
            setObjectFormula={setObjectFormula}
          />
        </div>
        {elementSide === 'leftFormula' && exposantPlace === 'up' && (
          <RightParenthesis height={height} />
        )}
      </div>
    </div>
  );
};

interface ExposantProps {
  objectFormula: ObjectFormula;
  path: Path;
  exposantPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  exposantPlace: 'up' | 'down';
  basicFormula: ReactElement;
}

export const Exposant = ({
  objectFormula,
  exposantPath,
  path,
  setPath,
  setObjectFormula,
  exposantPlace,
  basicFormula,
}: ExposantProps): ReactElement => {
  const { leftFormula, rightFormula } = get(
    objectFormula,
    `${exposantPath}.childFormula`,
  ) as unknown as ExposantFormula;
  const editing = exposantPath === path.slice(0, -1).join('.');
  const bothElementsHaveSymbols =
    leftFormula.parentFormula.mainFormula.symbols.length > 0 &&
    rightFormula.parentFormula.mainFormula.symbols.length > 0;
  const validateVisibility = bothElementsHaveSymbols ? 'visible' : 'invisible';

  const focusNextElement = useCallback(() => {
    editing &&
      setPath(prevPath =>
        prevPath
          .slice(0, -1)
          .concat(getOppositeElementSide(path[path.length - 1])),
      );
  }, [editing, path, setPath]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (event.key === 'Tab' && event.type === 'keydown') {
        focusNextElement();
      }
      if (event.key === 'Enter' && event.type === 'keydown') {
        validateFormula({ setPath, path, setObjectFormula });
      }
    },
    [focusNextElement, path, setObjectFormula, setPath],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);

    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown]);

  return (
    <div className="flex items-center gap-1">
      <div className="grid grid-cols-[auto_auto_auto] grid-rows-[auto_auto_auto] gap-1">
        <div
          className={`col-start-1 ${exposantPlace === 'up' ? 'row-start-2' : 'row-start-1'} row-span-2 flex items-center justify-around`}
        >
          {basicFormula}
        </div>
        <ExposantElement
          objectFormula={objectFormula}
          exposantPath={exposantPath}
          elementSide="leftFormula"
          path={path}
          setPath={setPath}
          setObjectFormula={setObjectFormula}
          rowStart={exposantPlace === 'up' ? 'row-start-2' : 'row-start-1'}
          colStart="col-start-2"
          exposantPlace={exposantPlace}
        />
        <ExposantElement
          objectFormula={objectFormula}
          exposantPath={exposantPath}
          elementSide="rightFormula"
          path={path}
          setPath={setPath}
          setObjectFormula={setObjectFormula}
          rowStart={exposantPlace === 'up' ? 'row-start-1' : 'row-start-2'}
          colStart="col-start-3"
          exposantPlace={exposantPlace}
        />
      </div>
      {editing && (
        <Button
          variant="ghost"
          className={`text-green-700 hover:text-green-700 ${validateVisibility}`}
          size="icon"
          onClick={() => validateFormula({ setPath, path, setObjectFormula })}
        >
          <Check className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
