import compact from 'lodash/compact';

import { Flattened, General } from '../types/chapters';

export const flatten = <
  T extends General = General,
  S extends string[] = string[],
>(
  obj: T,
  propertyNames: S,
): Flattened<T, S>[] => {
  if (typeof obj === 'boolean' || typeof obj !== 'object') {
    throw new Error();
  }

  if (propertyNames.length === 0) {
    return [];
  }

  const keys = Object.keys(obj);

  const arrays = keys.reduce<Flattened<T, S>[]>(
    (acc, key): Flattened<T, S>[] => {
      const nestedValue: boolean | General = obj[key];
      if (typeof nestedValue !== 'object') {
        return [
          ...acc,
          {
            [propertyNames[0]]: key,
          },
        ] as Flattened<T, S>[];
      }

      const newPropertyNames = propertyNames.slice(1);
      const children = flatten<General, string[] | [string, ...string[]]>(
        nestedValue,
        newPropertyNames,
      );

      return [
        ...acc,
        ...compact(children.length === 0 ? [{}] : children).map(child => ({
          ...child,
          [propertyNames[0]]: key,
        })),
      ] as Flattened<T, S>[];
    },
    [],
  );

  return arrays;
};
