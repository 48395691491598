import { MathJax } from 'better-react-mathjax';
import get from 'lodash/get';
import { Loader2 } from 'lucide-react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Block,
  Content as ContentType,
  lessons,
  NotionAsObject,
} from '@skloover/shared';

import { Tikz } from '../Tikz';

interface LessonProps {
  notionAsObject: NotionAsObject;
}

export const Lesson = ({ notionAsObject }: LessonProps): ReactElement => {
  const { country, program, division, subject, chapter, notion } =
    notionAsObject;
  const lesson = get(
    lessons,
    `${country}.${program}.${division}.${subject}.${chapter}.${notion}`,
  );

  return lesson === undefined || lesson.length === 0 ? (
    <div className="flex items-center justify-around grow">
      <Loader2 className="ml-1 h-4 w-4 animate-spin" />
    </div>
  ) : (
    <div>
      {lesson.map((block, blockIndex) => (
        <div key={blockIndex}>
          {block.title !== undefined && (
            <h4 className="mb-4 text-center text-2xl font-semibold text-muted-foreground tracking-tight">
              {block.title}
            </h4>
          )}
          {block.subtitle !== undefined && (
            <div className="uppercase font-semibold mb-4">{block.subtitle}</div>
          )}
          <BlockWrapper type={block.type} header={block.header}>
            <>
              {block.content.map((content, contentIndex) => (
                <Content content={content} key={contentIndex} />
              ))}
            </>
          </BlockWrapper>
        </div>
      ))}
    </div>
  );
};

const Content = ({ content }: { content: ContentType }): ReactElement => {
  const { type, content: contentValue } = content;
  switch (type) {
    case 'text':
      return (
        <p className="mb-2">
          <MathJax className="inline" dynamic>
            {content.content}
          </MathJax>
        </p>
      );
    case 'tikz':
      return <Tikz tikz={contentValue} packages={content.packages} />;
    case 'image':
      return (
        <div className="mb-4 flex items-center justify-around">
          <img
            className="max-w-3/4 shadow rounded"
            src={content.content}
            alt={content.content}
          />
        </div>
      );
    case 'list':
      return (
        <ul className="list-disc ml-6">
          {content.content.map((childContent, index) =>
            childContent.type === 'image' ? (
              <Content key={index} content={childContent} />
            ) : (
              <li className="mb-2" key={index}>
                <Content content={childContent} />
              </li>
            ),
          )}
        </ul>
      );
  }
};

const BlockWrapper = ({
  children,
  type,
  header,
}: {
  children: ReactElement;
  type: Block['type'];
  header?: Block['header'];
}): ReactElement => (
  <>
    {type !== 'simpleText' && (
      <div className={`text-sm italic font-bold ${getTitleColor(type)}`}>
        {header ?? <FormattedMessage id={`session.type.${type}`} />}
      </div>
    )}

    <div
      className={`whitespace-pre-wrap max-w-full ${getClassNames(type)} mb-4`}
    >
      {children}
    </div>
  </>
);

const getClassNames = (type: Block['type']) => {
  switch (type) {
    case 'simpleText':
      return '';
    case 'definition':
      return 'rounded border-double border-2 p-4 border-green-900 bg-green-50';
    case 'property':
      return 'rounded border-solid border-2 p-4 border-blue-900 bg-blue-50';
    case 'example':
      return 'rounded border-dotted border-2 p-4 border-yellow-900 bg-yellow-50';
    case 'additionalNote':
      return 'py-2';
  }
};

const getTitleColor = (type: Block['type']) => {
  switch (type) {
    case 'simpleText':
      return '';
    case 'definition':
      return 'text-green-900';
    case 'property':
      return 'text-blue-900';
    case 'example':
      return 'text-yellow-900';
    case 'additionalNote':
      return '';
  }
};
