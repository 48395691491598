/* eslint-disable max-lines */
export const real_numbers = {
  line_of_reals: [
    {
      type: 'definition',
      title: "L'ensemble des réels",
      subtitle: 'Droite des réels',
      header: 'Notations',
      content: [
        {
          type: 'text',
          content:
            'Les nombres sont classés dans des ensembles en fonction de leurs propriétés.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( \\mathbb{N} \\) : l’ensemble des entiers naturels.',
            },
            {
              type: 'text',
              content: '\\( \\mathbb{Z} \\) : l’ensemble des entiers relatifs.',
            },
            {
              type: 'text',
              content: '\\( \\mathbb{D} \\) : l’ensemble des nombres décimaux.',
            },
            {
              type: 'text',
              content:
                '\\( \\mathbb{Q} \\) : l’ensemble des nombres rationnels.',
            },
            {
              type: 'text',
              content: '\\( \\mathbb{R} \\) : l’ensemble des nombres réels.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Illustration',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
              \\fill[cyan!10, opacity=0.3] (0,0) rectangle (8,8); \
              \\draw[blue] (0,0) rectangle (8,8); \
              \\node[text=blue] at (4,0.5) {réels \\( \\mathbb{R} \\)}; \
              \\fill[green!10, opacity=0.3] (0.5,1) rectangle (7,7.5); \
              \\draw[green!80!black] (0.5,1) rectangle (7,7.5); \
              \\node[text=green!80!black] at (3.75,1.5) {rationnels \\( \\mathbb{Q} \\)}; \
              \\fill[gray!20, opacity=0.3] (1,2) rectangle (6,7);\\draw[gray!80] (1,2) rectangle (6,7); \
              \\node[text=gray!80] at (3.5,2.5) {décimaux \\( \\mathbb{D} \\)}; \
              \\fill[orange!10, opacity=0.3] (1.5,3) rectangle (5,6.5); \
              \\draw[orange!80] (1.5,3) rectangle (5,6.5); \
              \\node[text=orange!80] at (3.25,3.5) {relatifs \\( \\mathbb{Z} \\)}; \
              \\fill[red!10, opacity=0.3] (2,4) rectangle (4,6); \
              \\draw[red] (2,4) rectangle (4,6); \
              \\node[text=red] at (3,4.5) {naturels \\( \\mathbb{N} \\)}; \
              \\node[text=red] at (2.5,5.5) {0}; \
              \\node[text=red] at (3.5,5.5) {17}; \
              \\node[orange!80] at (4.5,5.5) {-3}; \
              \\node[orange!80] at (4.5,4) {-15}; \
              \\node[text=gray!80] at (5.5,3.5) {3,14}; \
              \\node[text=gray!80] at (5.5,5.5) {-0.25}; \
              \\node[text=green!80!black] at (6.5,3) { - $\\frac{22}{7}$}; \
              \\node[text=green!80!black] at (6.5,5.5) {1/3}; \
              \\node[text=blue] at (7.5,5.5) {$\\pi$}; \
              \\node[text=blue] at (7.5,2.5) {$\\sqrt{2}$}; \
            \\end{tikzpicture} \
            \\end{document}`,
          packages: ['amssymb'],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaire',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Ces ensembles sont inclus les uns dans les autres. On écrit :\\[\\mathbb{N} \\subset \\mathbb{Z} \\subset \\mathbb{D} \\subset \\mathbb{Q} \\subset \\mathbb{R} \\]',
            },
            {
              type: 'text',
              content:
                'L’ensemble des réels est généralement représenté par une droite graduée : « la droite des réels ». À chaque point de cette droite est associé un unique réel représentant son abscisse et à chaque réel est associé un unique point de la droite.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Info',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: 'Le symbole \\( \\in \\) se lit « appartient à ».',
            },
            {
              type: 'text',
              content:
                'Le symbole \\( \\subset \\) se lit « est inclus dans ».',
            },
          ],
        },
      ],
    },
  ],
  intervals: [
    {
      type: 'definition',
      title: "L'ensemble des réels",
      subtitle: 'Intervalles',
      content: [
        {
          type: 'text',
          content:
            'Soient \\( a \\) et \\( b \\) deux nombres réels tels que \\( a < b \\).',
        },

        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'L’intervalle \\( [a; b] \\) est l’ensemble des réels \\( x \\) tels que \\( a \\leq x \\leq b \\).',
            },
          ],
        },
        {
          type: 'text',
          content:
            'Il est représenté sur la droite des réels par un segment dont les extrémités sont les points d’abscisses \\( a \\) et \\( b \\).',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[->] (-3, 0) -- (3, 0); \
            \\draw[thick, red] (-0.9, 0.2) -- (-1, 0.2) -- (-1, -0.2) -- (-0.9, -0.2); \
            \\draw[thick, red] (-1, 0) -- (1, 0); \
            \\draw[thick, red] (0.9, 0.2) -- (1, 0.2) -- (1, -0.2) -- (0.9, -0.2); \
            \\node at (-1, -0.5) {\\textit{a}}; \\node at (1, -0.5) {\\textit{b}}; \
            \\end{tikzpicture} \
            \\end{document}',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'L’intervalle \\( [a; +\\infty[ \\) est l’ensemble des réels \\( x \\) tel que \\( x > a \\).',
            },
          ],
        },
        {
          type: 'text',
          content:
            'Il est représenté sur la droite des réels par une demi-droite dont l’origine est le point d’abscisse \\( a \\).',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\draw[->] (-3, 0) -- (3, 0); \\draw[thick, red] (-1.1, 0.2) -- (-1, 0.2) -- (-1, -0.2) -- (-1.1, -0.2); \\draw[thick, red] (-1, 0) -- (3, 0); \\node at (-1, -0.5) {\\textit{a}}; \\end{tikzpicture}\\end{document}',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaires',
      content: [
        {
          type: 'text',
          content:
            'Les sens des crochets indique si \\( x \\) peut être égal à la valeur de la borne.',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\draw[->] (-3, 0) -- (3, 0); \\draw[thick, red] (-0.9, 0.2) -- (-1, 0.2) -- (-1, -0.2) -- (-0.9, -0.2); \\draw[thick, red] (-1, 0) -- (1, 0); \\draw[thick, red] (1.1, 0.2) -- (1, 0.2) -- (1, -0.2) -- (1.1, -0.2); \\node at (-1, -0.5) {\\textit{a}}; \\node at (1, -0.5) {\\textit{b}}; \\end{tikzpicture}\\end{document}',
        },
        {
          type: 'text',
          content:
            'Cet intervalle est fermé en a et ouvert en b, il représente l’ensemble des réels x tels que \\( a \\leq x < b \\). Il est noté \\( [a; b[ \\).',
        },
        {
          type: 'text',
          content: 'On définie de la même façon les intervalles :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( ]a; b] \\)',
            },
            {
              type: 'text',
              content: '\\( ]a; +\\infty[ \\)',
            },
            {
              type: 'text',
              content: '\\( ]-\\infty; b] \\)',
            },
            {
              type: 'text',
              content: '\\( ]-\\infty; b[ \\)',
            },
          ],
        },
        {
          type: 'text',
          content:
            'Les intervalles sont des parties de \\( \\mathbb{R} \\) « d’un seul tenant », « sans trou ».',
        },
      ],
    },
  ],
  absolute_value: [
    {
      type: 'simpleText',
      title: "L'ensemble des réels",
      subtitle: 'Valeur absolue',
      content: [
        {
          type: 'text',
          content:
            'Soient \\( A \\) et \\( B \\) deux points de la droite des réels d’abscisses respectives \\( a \\) et \\( b \\). La distance entre les réels \\( a \\) et \\( b \\) est la distance \\( AB \\), elle se calcule à l’aide de la fonction valeur absolue. On a \\( AB = |b - a| \\). Si \\( M \\) est un point quelconque d’abscisse \\( x \\) et \\( O \\) est l’origine (c’est-à-dire le point d’abscisse \\( 0 \\)), alors \\( OM = |x - 0| = |x| \\).',
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'Soit un nombre réel \\( x \\) et \\( M \\) le point d’abscisse \\( x \\) de la droite des réels. La valeur absolue de \\( x \\), notée \\( |x| \\), est égale à la distance \\( OM \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: 'Si \\( x \\ge 0 \\), alors \\( |x| = x \\).',
            },
            {
              type: 'text',
              content: 'Si \\( x < 0 \\), alors \\( |x| = -x \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Si \\( r \\) est un réel strictement positif, alors pour tout réel \\( x \\) :',
        },
        {
          type: 'text',
          content:
            '\\[ |x - a| \\le r \\quad \\text{signifie que} \\quad a - r \\le x \\le a + r \\]',
        },
        {
          type: 'text',
          content:
            'La distance entre le point \\( M \\) d’abscisse \\( x \\) et le point \\( A \\) d’abscisse \\( a \\) est inférieure ou égale à \\( r \\).',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Examples',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document} \\begin{tikzpicture} \\draw[->,thick,orange] (-4,0) -- (6,0); \\foreach \\x in {-3,0,5} \\draw (\\x,3pt) -- (\\x,-3pt); \\foreach \\x in {-3,0,5} \\node[below] at (\\x,-0.2) {\\x}; \\draw[thick,->] (0,0.2) arc[start angle=0,end angle=180,radius=1.5]; \\node[below] at (-1.5,1.5) {$ |-3| $}; \\draw[thick,->] (0,0.2) arc[start angle=180,end angle=0,radius=2.5]; \\node[below] at (2.5,2.5) {$ |5| $}; \\node[below] at (1,-1) {$ |-3| = 3 \\ et \\ |5| = 5 $}; \\end{tikzpicture} \\end{document}',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document} \\begin{tikzpicture} \\draw[->,thick,orange] (-5,0) -- (5,0); \\draw (-3,3pt) -- (-3,-3pt); \\draw (0,3pt) -- (0,-3pt); \\draw (3,3pt) -- (3,-3pt); \\node[below] at (-3,-0.2) {$a-r$}; \\node[below] at (0,-0.2) {$a$}; \\node[below] at (3,-0.2) {$a+r$}; \\draw[thick,->] (0,0.2) arc[start angle=0,end angle=180,radius=1.5]; \\node[below] at (-1.5,1.5) {$ r $}; \\draw[thick,->] (0,0.2) arc[start angle=180,end angle=0,radius=1.5]; \\node[below] at (1.5,1.5) {$ r $}; \\end{tikzpicture} \\end{document}',
        },
      ],
    },
  ],
  decimals: [
    {
      type: 'definition',
      title: 'Décimaux',
      content: [
        {
          type: 'text',
          content:
            "\\( d \\) est un nombre décimal s'il existe un entier relatif \\( a \\) et un entier naturel \\( n \\) tel que \\( d = \\frac{a}{10^n} \\).",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Exemples',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "\\( 1,25 \\) peut s'écrire \\( \\frac{125}{100} \\), c'est dont un nombre décimal.",
            },
            {
              type: 'text',
              content:
                "\\( -98 \\) peut s'écrire \\( -\\frac{98}{10^0} \\), c'est dont un nombre décimal.",
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Tous les nombres écrits sous forme de fraction ne sont pas décimaux !',
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            "\\( \\frac{1}{3} \\) n'est pas décimal :\n\nOn raisonne par l’absurde en supposant que \\( \\frac{1}{3} \\) est un nombre décimal.\nDans ce cas, il existe un entier relatif \\( a \\) et un entier naturel \\( n \\) tel que \\( \\frac{1}{3} = \\frac{a}{10^n} \\).\nAlors \\( 10^n \\times \\frac{1}{3} = a \\). Or, une puissance de 10 n’est jamais divisible par 3 : la fraction \\( \\frac{10^n}{3} \\) n’est pas entière. Ainsi, le nombre a ne serait pas entier, ce qui contredit l'hypothèse d’un entier \\( a\\). On en déduit que \\( \\frac{1}{3} \\) n’est pas un nombre décimal.",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Rappel',
      content: [
        {
          type: 'text',
          content:
            'Un entier est un multiple de 3 si la somme de ses chiffres est égale à un multiple de 3. Et la somme des chiffres de \\( 10^n \\) vaut toujours… 1.',
        },
      ],
    },
    {
      type: 'property',
      header: 'Propriété',
      content: [
        {
          type: 'text',
          content:
            'Un nombre décimal admet une partie décimale qui peut s’écrire avec un nombre fini de chiffres.',
        },
      ],
    },
  ],
  approximate_value: [
    {
      type: 'property',
      title: 'Décimaux',
      subtitle: 'Valeur approchée',
      content: [
        {
          type: 'text',
          content:
            'Pour tout réel \\( x \\) et tout entier naturel \\( n \\), il existe un nombre décimal \\( d \\) tel que \\(|x-d| \\leq \\dfrac{1}{10^n}\\).\n\\( d \\) est alors appelé valeur approchée de \\( x \\) à \\( 10^{-n} \\) près.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaire',
      content: [
        {
          type: 'text',
          content:
            "Pour \\( x \\) fixé, le nombre \\( d \\) n'est pas unique (il en existe même une infinité). Souvent, lorsqu'on cherche une valeur approchée de \\( x \\) à \\( 10^{-n} \\) près, on choisit un décimal avec une partie décimale s'écrivant avec \\( n \\) chiffres.",
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Un encadrement de \\( \\pi \\) à \\( 10^{-3} \\) près est : \\[ 3,141 \\leq \\pi \\leq 3,142 \\]',
        },
        {
          type: 'text',
          content:
            'Un encadrement de \\( -\\frac{1}{7} \\) à \\( 10^{-2} \\) près est : \\[ -0,15 \\leq -\\frac{1}{7} \\leq -0,14 \\]',
        },
      ],
    },
  ],
  rationals: [
    {
      type: 'definition',
      title: 'Les rationnels',
      content: [
        {
          type: 'text',
          content:
            "Un nombre \\( q \\) est rationnel s'il existe deux entiers relatifs \\( a \\) et \\( b \\) avec \\( b \\neq 0 \\), tels que \\( q = \\frac{a}{b} \\).",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content: 'Tous les nombres réels ne sont pas des rationnels !',
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content: "\\( \\sqrt{2} \\) n'est pas rationnel",
        },
        {
          type: 'text',
          content:
            'On raisonne par l’absurde en supposant qu’on peut écrire \\( \\sqrt{2} = \\frac{a}{b} \\) sous forme irréductible. Alors \\( \\left( \\sqrt{2} \\right)^2 = \\frac{a^2}{b^2} \\), soit \\( 2 = \\frac{a^2}{b^2} \\). Donc \\( a^2 = 2b^2 \\).',
        },
        {
          type: 'text',
          content:
            'Puisque ces nombres entiers sont égaux, ils ont le même chiffre des unités. À l’aide du tableau ci-dessous, on démontre qu’il est donc nécessaire que le chiffre des unités de \\( a^2 \\) et de \\( 2b^2 \\) soit égal.',
        },
        {
          type: 'text',
          content: 'Tableau représentant le chiffre des unités de :',
        },
        {
          type: 'text',
          content:
            '\\begin{array}{|c|c|c|c|} \\hline a & a^2 & b & 2b^2 \\\\ \\hline 0 & 0 & 0 & 0 \\\\ \\hline 1 & 1 & 1 & 2 \\\\ \\hline 2 & 4 & 2 & 8 \\\\ \\hline 3 & 9 & 3 & 8 \\\\ \\hline 4 & 6 & 4 & 2 \\\\ \\hline 5 & 5 & 5 & 0 \\\\ \\hline 6 & 6 & 6 & 2 \\\\ \\hline 7 & 9 & 7 & 8 \\\\ \\hline 8 & 4 & 8 & 8 \\\\ \\hline 9 & 1 & 9 & 2 \\\\ \\hline \\end{array}',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Le chiffre des unités de \\( a^2 \\) étant 0, celui de \\( a \\) est aussi 0 ; \\( a \\) est donc un multiple de 5.',
            },
            {
              type: 'text',
              content:
                'Le chiffre des unités de \\( 2b^2 \\) étant 0, celui de \\( b \\) est donc 5 ; \\( b \\) est donc un multiple de 5.',
            },
            {
              type: 'text',
              content:
                'On a donc la fraction \\( \\frac{a}{b} \\) qui peut être simplifiée par 5, ce qui n’est pas irréductible, ce qui contredit l’hypothèse ! On en déduit que \\( \\sqrt{2} \\) n’est pas rationnel.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Rappel',
      content: [
        {
          type: 'text',
          content: '\\[ \\left( \\frac{a}{b} \\right)^2 = \\frac{a^2}{b^2} \\]',
        },
      ],
    },
  ],
  integer_power: [
    {
      type: 'definition',
      title: 'Règles de calcul',
      subtitle: 'Puissances entières relatives',
      content: [
        {
          type: 'text',
          content: 'Soit \\(a\\) un nombre réel et \\(n\\) un entier.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si \\( n = 0 \\) et \\( a \\neq 0 \\), alors \\( a^0 = 1 \\); si \\( n = 1 \\), alors \\( a^1 = a \\)',
            },
            {
              type: 'text',
              content:
                'Pour \\(n \\geq 2 \\), \\( a^n = a \\times a \\times \\cdots \\times a \\ \\textit{n fois} \\)',
            },
            {
              type: 'text',
              content:
                'Pour \\(n > 1\\) et \\( a \\neq 0 \\), \\( a^{-n} = \\frac{1}{a^n} \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'property',
      header: 'Règles de calcul',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Soit \\( a \\) et \\( b \\) sont des réels non nuls, et \\( n, p \\) deux entiers relatifs.',
            },
            {
              type: 'list',
              content: [
                {
                  type: 'text',
                  content: '\\( a^n \\times a^p = a^{n+p} \\)',
                },
                {
                  type: 'text',
                  content: '\\( a^{-n} = \\frac{1}{a^n} \\)',
                },
                {
                  type: 'text',
                  content: '\\( \\frac{a^n}{a^p} = a^{n-p} \\)',
                },
                {
                  type: 'text',
                  content: '\\( (a^n)^p = a^{n \\times p} \\)',
                },
                {
                  type: 'text',
                  content: '\\( (a \\times b)^n = a^n \\times b^n \\)',
                },
                {
                  type: 'text',
                  content:
                    '\\( \\left(\\frac{a}{b}\\right)^n = \\frac{a^n}{b^n} \\)',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Rappels',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( 10^0 = 1 \\)',
            },
            {
              type: 'text',
              content: '\\( 10^1 = 10 \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Examples',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( 3^2 \\times 3^{-3} = 3^{2-3} = 3^{-1} = \\frac{1}{3} \\approx 0.33 \\)',
            },
            {
              type: 'text',
              content: '\\( (-5)^3 = -5 \\times -5 \\times -5 = -125 \\)',
            },
            {
              type: 'text',
              content:
                '\\( (2^4)^{-2} = 2^{-8} = \\frac{1}{256} \\approx 0.0039 \\)',
            },
            {
              type: 'text',
              content: '\\( (2.5)^4 \\approx 39.0625 \\)',
            },
            {
              type: 'text',
              content:
                '\\( ( ( 2.5 \\times 4 )^2 )^{\\frac{1}{2}} = ( ( 10 )^2 )^{\\frac{1}{2}} = 10^1 = 10 \\)',
            },
          ],
        },
      ],
    },
  ],
  square_root: [
    {
      type: 'property',
      subtitle: 'Racines carrées',
      title: 'Règles de calcul',
      header: 'Règles de calcul',
      content: [
        {
          type: 'text',
          content:
            'Soient \\( a \\) et \\( b \\) deux réels positifs\n\\[ \\sqrt{a \\times b} = \\sqrt{a} \\times \\sqrt{b} \\quad \\text{et si } b \\neq 0 : \\quad \\sqrt{\\frac{a}{b}} = \\frac{\\sqrt{a}}{\\sqrt{b}} \\]',
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Démonstration de \\( \\sqrt{a \\times b} = \\sqrt{a} \\times \\sqrt{b} \\)',
        },
        {
          type: 'text',
          content:
            "Étape 1 : On démontre d'abord que pour tous réels strictement positifs \\( p \\) et \\( q \\) : \\( p^2 = q^2 \\iff p = q \\).",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( p^2 = q^2 \\iff p^2 - q^2 = (p-q)(p+q) = 0 \\iff p-q = 0 \\ \\text{ou} \\ p+q = 0 \\)',
            },
            {
              type: 'text',
              content:
                'Or, \\( p + q = 0 \\) implique \\( p = -q \\) ce qui est impossible car \\( p \\) et \\( q \\) sont strictement positifs.',
            },
            {
              type: 'text',
              content:
                "Ainsi, pour \\( p \\) et \\( q \\) strictement positifs, \\( p^2 = q^2 \\iff p - q = 0 \\) c'est à dire \\( p = q \\).",
            },
          ],
        },
        {
          type: 'text',
          content: 'Étape 2 :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Si \\( a \\) ou \\( b \\) est nul, l'égalité est vraie : \\( \\sqrt{ab} = 0 \\) et \\( \\sqrt{a} \\times \\sqrt{b} = 0 \\) donc \\( \\sqrt{ab} = \\sqrt{a} \\times \\sqrt{b} = 0 \\).",
            },
            {
              type: 'text',
              content:
                'Pour tous réels \\( a \\) et \\( b \\) strictement positifs, on sait que \\( (\\sqrt{a \\times b})^2 = a \\times b \\) et que \\( (\\sqrt{a} \\times \\sqrt{b})^2 = (\\sqrt{a})^2 \\times (\\sqrt{b})^2 = a \\times b \\).',
            },
            {
              type: 'text',
              content:
                'Les deux nombres strictement positifs \\( \\sqrt{a} \\times \\sqrt{b} \\) et \\( \\sqrt{a \\times b} \\) ont donc des carrés égaux (à \\( a \\times b \\)).',
            },
            {
              type: 'text',
              content:
                "D'après l'étape 1, avec \\( p = \\sqrt{a \\times b} \\) et \\( q = \\sqrt{a} \\times \\sqrt{b} \\), on conclut :\\[ \\sqrt{a \\times b} = \\sqrt{a} \\times \\sqrt{b} \\]",
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Info',
      content: [
        {
          type: 'text',
          content:
            'Le symbole \\(\\iff\\) signifie que l’expression de gauche est équivalente à l’expression de droite. C’est un lien logique ; ce n’est pas une abréviation.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( \\sqrt{12} = \\sqrt{4 \\times 3} = \\sqrt{4} \\times \\sqrt{3} = 2\\sqrt{3} \\)',
            },
            {
              type: 'text',
              content:
                '\\( \\frac{\\sqrt{6}}{\\sqrt{3}} = \\sqrt{\\frac{6}{3}} = \\sqrt{2} \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Rappel',
      content: [
        {
          type: 'text',
          content:
            'Les identités remarquables se lisent dans « les deux sens » ! On peut les utiliser pour factoriser une expression.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( a^2 + 2ab + b^2 = (a+b)^2 \\)',
            },
            {
              type: 'text',
              content: '\\( a^2 - 2ab + b^2 = (a-b)^2 \\)',
            },
            {
              type: 'text',
              content: '\\( a^2 - b^2 = (a-b)(a+b) \\)',
            },
          ],
        },
      ],
    },
  ],
  reals_addition: [
    {
      type: 'property',
      title: 'Inégalités',
      subtitle: "Ordre dans l'ensemble des réels",
      content: [
        {
          type: 'text',
          content:
            'Si \\(a, b \\) et \\( c \\) sont des réels tels que \\( a < b \\) et \\( b < c \\), alors \\( a < c \\).',
        },
      ],
    },
    {
      subtitle: 'Somme',
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            '\\( a \\), \\( b \\), \\( x \\) et \\( y \\) sont des réels.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Ajouter (ou soustraire) le même réel à chaque membre d'une inégalité conserve l'ordre : \\[ a < x \\iff a + b < x + b \\]\\[ a < x \\iff a - b < x - b \\]",
            },
          ],
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            '\\( a \\), \\( b \\), \\( x \\) et \\( y \\) sont des réels.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'On peut additionner les inégalités de même sens membre à membre : \\[ \\text{Si} \\quad a < x < b \\quad \\text{et} \\quad c < y < d \\quad \\text{alors} \\quad a + c < x + y < b+d \\]',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: '\\[ 7 + x > 5 - 2x \\]',
        },
        {
          type: 'text',
          content:
            "En ajoutant \\( 2x \\) à chaque membre de l'inéquation: \\[ 7 + x + 2x > 5 - 2x + 2x \\iff 7 + 3x > 5 \\]",
        },
        {
          type: 'text',
          content:
            "En soustrayant \\( 7 \\) à chaque membre de l'inéquation: \\[ 7 + 3x - 7 > 5 - 7 + 2x \\iff 3x > -2 \\]",
        },
      ],
    },
  ],
  reals_multiplication: [
    {
      type: 'property',
      title: 'Inégalités',
      subtitle: 'Produit',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Multiplier (ou diviser) chaque membre d’une inégalité par le même réel strictement positif, \\( \\textbf{conserve} \\) l’ordre. Si \\( b > 0 \\), alors :\\[ a < x \\iff a \\times x < x \\times b \\]',
            },
            {
              type: 'text',
              content:
                'Multiplier (ou diviser) chaque membre d’une inégalité par le même réel strictement négatif \\( \\textbf{change} \\) l’ordre. Si \\( b < 0 \\), alors :\\[ a < x \\iff a \\times x > x \\times b \\]',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Conséquences',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\(0 < a < b \\iff 0 < \\dfrac{1}{b} < \\dfrac{1}{a} \\)',
            },
            {
              type: 'text',
              content:
                'Si \\(0 \\leq a < x < b \\) et \\(0 \\leq c < y < d \\), alors \\( ac < xy < bd \\)',
            },
            {
              type: 'text',
              content:
                'Pour tout entier naturel \\( n \\), si \\( a \\) et \\( b \\) sont des réels positifs : \\( a < b \\iff a^n < b^n \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'En calcul littéral, on ne connaît pas le signe du nombre que remplace la lettre !',
        },
        {
          type: 'text',
          content:
            'Ainsi, pour \\( b = -7 \\), le nombre \\( -b \\) est positif : \\( -b = -(-7) = 7 \\)',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: '\\[ 3x > -2 \\]',
        },
        {
          type: 'text',
          content:
            'En divisant par 3 chaque membre de l’inéquation : \\[ \\iff \\dfrac{3x}{3} > \\dfrac{-2}{3} \\]',
        },
        {
          type: 'text',
          content:
            '3 est positif : l’ordre est conservé \\[ \\iff x > \\dfrac{-2}{3} \\]',
        },
      ],
    },
  ],
  intervals_composition: [
    {
      type: 'definition',
      title: 'Inégalités',
      subtitle: 'Réunion – intersection d’intervalles',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'L’intersection de \\( I \\) et \\( J \\), notée \\( I \\cap J \\), est l’ensemble des réels \\( x \\) appartenant à \\( I \\) et à \\( J \\) ; le symbole \\( \\cap \\) se lit « inter ».',
            },
            {
              type: 'text',
              content:
                'L’union ou la réunion de \\( I \\) et \\( J \\), notée \\( I \\cup J \\), est l’ensemble des réels \\( x \\) appartenant à \\( I \\) ou \\( J \\) ; le symbole \\( \\cup \\) se lit « union ».',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'En mathématiques, le « ou » est inclusif. Il ne signifie pas « ou bien » mais il inclut la possibilité du « et ».',
            },
            {
              type: 'text',
              content:
                'En notation symbolique, on a :\n\\( x \\) appartient à \\( I \\cap J \\) équivaut à \\( x \\) appartient à \\( I \\) ET \\( x \\) appartient à \\( J \\)\n\\( x \\) appartient à \\( I \\cup J \\) équivaut à \\( x \\) appartient à \\( I \\) OU \\( x \\) appartient à \\( J \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Si \\( I = [-3;6] \\) et \\( J = [1;9] \\), alors \\( I \\cup J = [-3;9] \\) et \\( I \\cap J = [1;6] \\)',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[->] (-4, 0) -- (10, 0); \
            \\draw[thick, red] (-2.9, 0.2) -- (-3, 0.2) -- (-3, -0.2) -- (-2.9, -0.2); \
            \\draw[thick, red] (-3, -0.01) -- (6, -0.01); \
            \\draw[thick, red] (5.9, 0.2) -- (6, 0.2) -- (6, -0.2) -- (5.9, -0.2); \
            \\draw[thick, blue] (1.1, 0.2) -- (1, 0.2) -- (1, -0.2) -- (1.1, -0.2); \
            \\draw[thick, blue] (1, 0.01) -- (9, 0.01); \
            \\draw[thick, blue] (8.9, 0.2) -- (9, 0.2) -- (9, -0.2) -- (8.9, -0.2); \
            \\node[below] at (-3, -0.2) {-3}; \
            \\node[below] at (1, -0.2) {1}; \
            \\node[below] at (6, -0.2) {6}; \
            \\node[below] at (9, -0.2) {9}; \
            \\end{tikzpicture} \
            \\end{document}',
        },
        {
          type: 'text',
          content:
            'Si \\( I = [-5;2] \\) et \\( J = ]2;8] \\), alors \\( I \\cup J = [-5;8] \\) et \\( I \\cap J = \\emptyset \\)',
        },
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture} \\draw[->] (-6, 0) -- (9, 0); \\draw[thick, red] (-4.9, 0.2) -- (-5, 0.2) -- (-5, -0.2) -- (-4.9, -0.2); \\draw[thick, red] (-5, 0) -- (1.99, 0); \\draw[thick, red] (1.9, 0.19) -- (1.99, 0.19) -- (1.99, -0.19) -- (1.9, -0.19); \\draw[thick, blue] (1.9, 0.21) -- (2.01, 0.21) -- (2.01, -0.21) -- (1.9, -0.21); \\draw[thick, blue] (2, 0) -- (8, 0); \\draw[thick, blue] (7.9, 0.2) -- (8, 0.2) -- (8, -0.2) -- (7.9, -0.2); \\node[below] at (-5, -0.2) {-5}; \\node[below] at (2, -0.2) {2}; \\node[below] at (8, -0.2) {8}; \\end{tikzpicture} \\end{document}',
        },
      ],
    },
  ],
} as const;
