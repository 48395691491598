import { zodResolver } from '@hookform/resolvers/zod';
import { Lightbulb, Loader2 } from 'lucide-react';
import { ReactElement, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { ChapterAsObject, CHAPTERS_AS_OBJECTS } from '@skloover/shared';

import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui';
import { AppRoute, isDev, trpc, useIntl } from '~/lib';
import { SessionContext } from '~/navigation';

const FORBIDDEN_CHAPTER = '';

const getFilteredChapters = (
  subject: Pick<
    ChapterAsObject,
    'country' | 'program' | 'division' | 'subject'
  >,
): ChapterAsObject[] =>
  CHAPTERS_AS_OBJECTS.filter(chapterAsObject =>
    Object.entries(subject).every(
      ([key, value]) =>
        chapterAsObject[key as keyof ChapterAsObject] === value &&
        (isDev() || (chapterAsObject.chapter as string) !== FORBIDDEN_CHAPTER),
    ),
  );

const formSchema = z.object({
  chapter: z.string(),
});

export const StartSession = (): ReactElement => {
  const t = useIntl();
  const navigate = useNavigate();
  const utils = trpc.useUtils();
  const { country, division } = useContext(SessionContext);
  const { mutate, isPending } = trpc.sessionCreate.useMutation({
    onSuccess: async ({ id }) => {
      await utils.startedSessionGet.invalidate();
      navigate(`${AppRoute.Session}/${id}`);
    },
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      chapter: undefined,
    },
  });

  const {
    formState: { errors },
  } = form;

  const subject: Pick<
    ChapterAsObject,
    'country' | 'program' | 'division' | 'subject'
  > = {
    country,
    program: '2024',
    division,
    subject: 'mathematics',
  } as const;

  const onSubmit = ({ chapter }: z.infer<typeof formSchema>) => {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    mutate({
      chapterAsObject: {
        ...subject,
        chapter,
      },
    });
  };

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="welcome.startSession.title" />
        </CardTitle>
        <CardDescription>
          <FormattedMessage id="welcome.startSession.subtitle" />
        </CardDescription>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <FormField
                  control={form.control}
                  name="chapter"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel htmlFor="framework">
                        <FormattedMessage id="common.chapter" />
                      </FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            id="framework"
                            className="whitespace-normal [&>span]:text-left [&>svg]:shrink-0"
                          >
                            <SelectValue
                              placeholder={t.formatMessage({
                                id: 'common.select',
                              })}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent
                          position="popper"
                          className="max-w-[var(--radix-select-trigger-width)] overflow-y-auto max-h-[12rem]"
                        >
                          {getFilteredChapters(subject).map(({ chapter }) => (
                            <SelectItem
                              value={chapter}
                              key={chapter}
                              className="max-w-100"
                            >
                              <FormattedMessage
                                id={`common.chapterAsObject.chapter.${chapter}`}
                              />
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button
              type="submit"
              className="gap-2"
              disabled={isPending || Object.keys(errors).length > 0}
            >
              {isPending ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Lightbulb className="h-4 w-4" />
              )}
              <FormattedMessage id="welcome.startSession.start" />
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
};
