import { ReactElement } from 'react';

import { ContinueSession, Layout, StartSession } from '~/components';
import { Skeleton } from '~/components/ui';
import { trpc } from '~/lib';

const SkeletonCard = (): ReactElement => (
  <div className="flex flex-col space-y-3">
    <Skeleton className="h-[125px] w-[250px] rounded-xl" />
    <div className="space-y-2">
      <Skeleton className="h-4 w-[250px]" />
      <Skeleton className="h-4 w-[200px]" />
    </div>
  </div>
);

export const Welcome = (): ReactElement => {
  const { data } = trpc.startedSessionGet.useQuery();

  if (data === undefined) {
    return (
      <Layout>
        <div className="h-full flex items-center justify-around">
          <SkeletonCard />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="h-full flex items-center justify-around">
        {data.sessions?.length === 1 ? (
          <ContinueSession session={data.sessions[0]} />
        ) : (
          <StartSession />
        )}
      </div>
    </Layout>
  );
};
