import { algorithmic } from './algorithmic';
import { calculation } from './calculation';
import { coordinate_system } from './coordinate_system';
import { equations_inequations } from './equations_inequations';
import { geometry } from './geometry';
import { integers } from './integers';
import { numerical_functions } from './numerical_functions';
import { plan_line_and_system } from './plan_line_and_system';
import { probability } from './probability';
import { real_numbers } from './real_numbers';
import { sampling } from './sampling';
import { statistics } from './statistics';
import { variations_extremums } from './variations_extremums';
import { vectors } from './vectors';

export const mathematics = {
  probability,
  statistics,
  real_numbers,
  integers,
  calculation,
  geometry,
  vectors,
  algorithmic,
  coordinate_system,
  equations_inequations,
  numerical_functions,
  plan_line_and_system,
  sampling,
  variations_extremums,
};
