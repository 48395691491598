/* eslint-disable max-lines */
export const plan_line_and_system = {
  director_vector: [
    {
      type: 'definition',
      title: 'Vecteur directeur d’une droite',
      content: [
        {
          type: 'text',
          content:
            'On considère une droite $d$ et deux points distincts $A$ et $B$ de $d$.\nOn appelle vecteur directeur de la droite $d$ tout vecteur $\\vec{u}$ non nul, colinéaire au vecteur $\\overrightarrow{AB}$.',
        },
        {
          type: 'tikz',
          content:
            '\\usetikzlibrary{calc} \
          \\begin{document} \
            \\begin{tikzpicture} \
              \\coordinate [label=below:$A$] (A) at (0,0); \
              \\coordinate [label=below:$B$] (B) at (2,0.5); \
              \\coordinate (C) at (0.5,1); \
              \\coordinate (D) at (3.5,1.75); \
              \\draw[->,blue] (D) -- (C) node [pos=0.5, above] {$\\vec{u}$}; \
              \\draw[red,thick] ($(A)!-0.2!(B)$) -- ($(A)!1.5!(B)$) node [pos=1, below] {$d$}; \
              \\draw[->, thin] (A) -- (B); \
              \\fill (A) circle(0.05); \
              \\fill (B) circle(0.05); \
            \\end{tikzpicture} \
            \\end{document}',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'On dit que le vecteur $\\vec{u}$ « dirige » la droite $d$.',
            },
            {
              type: 'text',
              content:
                'La direction du vecteur directeur $\\vec{u}$ définit la direction de la droite $d$.',
            },
            {
              type: 'text',
              content:
                'Deux vecteurs directeurs de $d$ ont la même direction : ils sont colinéaires.',
            },
            {
              type: 'text',
              content:
                'Deux droites parallèles ont la même direction : tout vecteur directeur de l’une est un vecteur directeur de l’autre.',
            },
          ],
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Une droite $d$ peut être définie par la donnée d’un point $A$ et d’un vecteur directeur $\\vec{u}$. On a alors :\n$M \\in d \\iff \\overrightarrow{AM}$ et $\\vec{u}$ sont colinéaires',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-3.2,-1.2) grid (3.2,4.2); \
            \\draw[->, thin] (-3.2,0) -- (3.2,0); \
            \\draw[->, thin] (0,-1.2) -- (0,4.2); \
            \\node [left] at (0,4.2) {$y$}; \
            \\node [below] at (3.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=above:\\textcolor{black}{$A$}] (A) at (-2,3); \
            \\coordinate[label=above:\\textcolor{black}{$B$}] (B) at (2,1); \
            \\fill (O) circle(0.05); \
            \\fill (I) circle(0.05); \
            \\fill (J) circle(0.05); \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\draw[->, blue] (1,3) -- (3,2) node [pos=0.5, above] {$\\vec{u}$}; \
            \\draw[red] ($(A)!-0.2!(B)$) -- ($(A)!1.2!(B)$) node [pos=1, below] {$d$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'La droite $d$ passe par les points : $A(-2;3)$ et $B(2;1)$',
            },
            {
              type: 'text',
              content:
                'Le vecteur $\\overrightarrow{AB} \\begin{pmatrix} 4 \\\\ -2 \\end{pmatrix}$ est un vecteur directeur de $d$.',
            },
            {
              type: 'text',
              content:
                '$\\vec{u}\\begin{pmatrix} 2 \\\\ -1 \\end{pmatrix}$ est colinéaire à $\\overrightarrow{AB}$ donc $\\vec{u}$ est aussi un vecteur directeur de $d$.',
            },
          ],
        },
      ],
    },
  ],
  cartezian_equation: [
    {
      type: 'property',
      title: 'Équation cartésienne d’une droite',
      content: [
        {
          type: 'text',
          content:
            'Dans un repère du plan, les coordonnées $\\left(x ; y \\right)$ de tous les points $M$ d’une droite vérifient une équation de la forme :\\[ ax + by + c = 0 \\]où $a$, $b$ et $c$ sont des nombres réels tels que $a$ et $b$ ne sont pas simultanément nuls. On notera par la suite $(a ; b) \\neq (0 ; 0)$.',
        },
        {
          type: 'text',
          content:
            'Une telle équation s’appelle une équation cartésienne de la droite $d$.',
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Soit $A(x_A ; y_A )$ un point de la droite $d$ et $\\vec{u} \\begin{pmatrix} \\alpha \\ \\\\ \\beta \\end{pmatrix}$ un vecteur directeur de $d$.',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-3.2,-1.2) grid (3.2,4.2); \
            \\draw[->, thin] (-3.2,0) -- (3.2,0); \
            \\draw[->, thin] (0,-1.2) -- (0,4.2); \
            \\node [left] at (0,4.2) {$y$}; \
            \\node [below] at (3.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=above:\\textcolor{black}{$A$}] (A) at (-2,3); \
            \\coordinate[label=above:\\textcolor{black}{$M$}] (M) at (2,1); \
            \\fill (O) circle(0.05); \
            \\fill (I) circle(0.05); \
            \\fill (J) circle(0.05); \
            \\fill (A) circle(0.05); \
            \\fill (M) circle(0.05); \
            \\draw[->, blue] (1,3) -- (3,2) node [pos=0.5, above] {$\\vec{u}$}; \
            \\draw[red] ($(A)!-0.2!(M)$) -- ($(A)!1.2!(M)$) node [pos=1, below] {$d$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            '$\\begin{aligned} M (x ; y) \\in d &\\iff \\overrightarrow{AM} \\begin{pmatrix} x - x_A \\\\ y - y_A \\end{pmatrix} \\text{ et } \\vec{u} \\begin{pmatrix} \\alpha \\ \\\\ \\beta \\end{pmatrix} \\text{ sont colinéaires.} \\\\ &\\iff \\det(\\overrightarrow{AM}, \\vec{u}) = 0 \\\\ &\\iff \\beta(x-x_A) - \\alpha(y-y_A) = 0 \\\\ &\\iff \\beta x - \\alpha y - (\\beta x_A - \\alpha y_A) = 0 \\end{aligned}$',
        },
        {
          type: 'text',
          content:
            'On obtient bien une équation de la forme $ax + by + c = 0$ avec $a = \\beta, b = -\\alpha, c = - \\beta x_A + \\alpha y_A$ et $(\\beta; -\\alpha) \\neq (0; 0)$ car $\\vec{u} \\neq \\vec{0}$.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Réciproquement, l’ensemble des points $M$ dont les coordonnées $(x ; y)$ vérifient une équation de la forme $ax + by + c = 0$ (avec $(a ; b) \\neq (0 ; 0)$) est une droite.',
            },
            {
              type: 'text',
              content:
                'Une droite $d$ admet une infinité d’équations cartésiennes dont les coefficients sont deux à deux proportionnels.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Soit $d$ la droite passant par $A(1;3)$ et de vecteur directeur $\\vec{u} \\begin{pmatrix} 2 \\\\ 5 \\end{pmatrix}$',
        },
        {
          type: 'text',
          content:
            '$\\begin{aligned} M (x ; y) \\in d &\\iff \\overrightarrow{AM} \\begin{pmatrix} x - 1 \\\\ y - 3 \\end{pmatrix} \\text{ et } \\vec{u} \\begin{pmatrix} 2 \\ \\\\ 5 \\end{pmatrix} \\text{ sont colinéaires.} \\\\ &\\iff \\det(\\overrightarrow{AM}, \\vec{u}) = 0 \\\\ &\\iff 5(x-1) - 2(y-3) = 0 \\\\ &\\iff 5x - 2y + 1 = 0 \\end{aligned}$',
        },
        {
          type: 'text',
          content:
            'Une équation cartésienne de la droite $d$ est donc $5x - 2y + 1 = 0$.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            "Soient $d$ et $d'$ deux droites d’équations cartésiennes respectives :$ax + by + c = 0$ et $a'x + b'y + c' = 0$",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '$ax + by + c = 0$ avec $(a ; b) \\neq (0 ; 0)$',
            },
            {
              type: 'text',
              content: "$a'x + b'y + c' = 0$ avec $(a'; b') \\neq (0 ; 0)$",
            },
          ],
        },
        {
          type: 'text',
          content:
            "Les droites $d$ et $d'$ sont parallèles si et seulement si $ab' - a'b = 0$.",
        },
        {
          type: 'text',
          content:
            "Les coefficients $(a ; b)$ et $(a'; b')$ sont alors proportionnels.",
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'On donne deux droites :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '$d_1 : -3x + 2y + 1 = 0$',
            },
            {
              type: 'text',
              content: '$d_2 : 9x - 6y - 5 = 0$',
            },
          ],
        },
        {
          type: 'text',
          content:
            '$-3 \\times (-6) - 9 \\times 2 = 18 - 18 = 0$ donc $d_1$ et $d_2$ sont parallèles.',
        },
        {
          type: 'text',
          content:
            'Les coefficients $(-3 ; 2)$ et $(9 ; -6)$ sont proportionnels.',
        },
      ],
    },
  ],
  y_parallelism: [
    {
      type: 'property',
      title: "Équation réduite d'une droite",
      subtitle: 'Droite parallèle à l’axe des ordonnées',
      content: [
        {
          type: 'text',
          content:
            'Soit $d$ une droite d’équation cartésienne $ax + by + c = 0$ avec $(a; b) \\ne (0; 0)$.',
        },
        {
          type: 'text',
          content:
            'Si $b = 0$ alors la droite $d$ est parallèle à l’axe des ordonnées et admet une équation, appelée équation réduite, de la forme $x = k$, où $k \\in \\mathbb{R}$.',
        },
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-1.2,-1.2) grid (4.2,4.2); \
            \\draw[->, thin] (-1.2,0) -- (4.2,0); \
            \\draw[->, thin] (0,-1.2) -- (0,4.2); \
            \\node [left] at (0,4.2) {$y$}; \
            \\node [below] at (4.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\fill (O) circle(0.05); \
            \\draw[red] (2, -1.2) -- (2, 4.2) node [pos=0.85, right] {$d : x = 2$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Si $b = 0$ alors $a \\ne 0$, puisque le couple $(a; b)$ est distinct de $(0; 0)$.',
        },
        {
          type: 'text',
          content:
            'La droite $d$ a pour équation cartésienne $ax + c = 0$, soit $x = -\\frac{c}{a}$ $(a \\ne 0)$.',
        },
        {
          type: 'text',
          content:
            'Ainsi les points appartenant à $d$ ont tous la même abscisse $-\\frac{c}{a}$.',
        },
        {
          type: 'text',
          content: 'La droite $d$ est donc parallèle à l’axe des ordonnées.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Soit $d$ la droite d’équation cartésienne $2x - 6 = 0$.',
        },
        {
          type: 'text',
          content: 'On a alors $x = \\frac{6}{2} = 3$.',
        },
        {
          type: 'text',
          content:
            'Tous les points de $d$ ont donc la même abscisse 3. Ainsi $d$ est parallèle à l’axe des ordonnées.',
        },
      ],
    },
  ],
  y_no_parallelism: [
    {
      type: 'property',
      title: "Équation réduite d'une droite",
      subtitle: 'Droite non parallèle à l’axe des ordonnées',
      content: [
        {
          type: 'text',
          content:
            'Soit $d$ une droite d’équation cartésienne $ax + by + c = 0$ avec $b \\neq 0$.',
        },
        {
          type: 'text',
          content:
            'La droite $d$ admet une unique équation de la forme $y = mx + p$.',
        },
        {
          type: 'text',
          content:
            'Cette équation est appelée l’équation réduite de $d$, le réel $m$ est le coefficient directeur de $d$ et $p$ son ordonnée à l’origine.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Illustration',
      content: [
        {
          type: 'text',
          content:
            'Sur le graphique suivant, la droite $d$ admet pour équation réduite :\\[ d : y = 2x + 1 \\]',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "L'ordonnée à l'origine est $1$ : c'est l'ordonnée de l'intersection de $d$ avec l'axe des ordonnées.",
            },
            {
              type: 'text',
              content:
                "Le coefficient directeur est $2$ : c'est l'évolution en ordonnée lorsqu'on se déplace de $1$ unité sur l'axe des abscisses.",
            },
          ],
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-1.2,-1.2) grid (3.2,4.2); \
            \\draw[->, thin] (-1.2,0) -- (3.2,0); \
            \\draw[->, thin] (0,-1.2) -- (0,4.2); \
            \\node [left] at (0,4.2) {$y$}; \
            \\node [below] at (3.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate [label=above left:\\textcolor{black}{$P(0;$}\\textcolor{green!80!black}{$1$}\\textcolor{black}{$)$}] (P) at (0,1); \
            \\coordinate (M) at (1,3); \
            \\fill (O) circle(0.05); \
            \\fill (P) circle(0.05); \
            \\fill (M) circle(0.05); \
            \\draw[red] ($(P)!-1!(M)$) -- ($(P)!1.5!(M)$) node [pos=1, below right] {$d : \\textcolor{black}{y = } \\ \\textcolor{blue}{2}\\textcolor{black}{x} \\ \\textcolor{black}{+} \\ \\textcolor{green!80!black}{1}$}; \
            \\draw[->] (P) -- (1,1) node [pos=0.5, below] {$1$}; \
            \\draw[->, blue] (1,1) -- (1,3) node [pos=0.5, right] {$2$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si $b \\neq 0$ alors $ax + by + c = 0 \\iff y = \\frac{-a}{b}x - \\frac{c}{b}$.\nOn obtient une équation de la forme $y = mx + p$ avec $m = \\frac{-a}{b}$ et $p = \\frac{-c}{b}$.',
            },
            {
              type: 'text',
              content:
                "Soit $a'x + b'y + c' = 0$ une autre équation cartésienne de $d$, avec $b' \\neq 0$.\nOn a alors $y = \\frac{-a'}{b'}x - \\frac{c'}{b'}$. Or, les coefficients $(a; b; c)$ et $(a'; b'; c')$ sont proportionnels, donc il existe un réel $k$ tel que $a' = k \\times a$, $b' = k \\times b$ et $c' = k \\times c$.\nAinsi $\\frac{-a'}{b'} = \\frac{-k \\times a}{k \\times b} = \\frac{-a}{b}$ et $\\frac{-c'}{b'} = \\frac{-k \\times c}{k \\times b} = \\frac{-c}{b}$.\nL’équation réduite de $d$ est donc unique.",
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Soit $d$ la droite d’équation cartésienne $-3x + 2y - 5 = 0$.',
        },
        {
          type: 'text',
          content: 'On a $y = \\frac{3}{2}x + \\frac{5}{2}$.',
        },
        {
          type: 'text',
          content:
            'La droite $d$ a alors pour coefficient directeur $m = \\frac{3}{2}$ et pour ordonnée à l’origine $p = \\frac{5}{2}$.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content: 'Soit $d$ une droite d’équation réduite $y=mx+p$.',
        },
        {
          type: 'text',
          content:
            '$A(x_A; y_A)$ et $B(x_B; y_B)$ sont deux points distincts de $d$.',
        },
        {
          type: 'text',
          content:
            'Le coefficient directeur de la droite $d$ est le réel :\\[ m = \\frac{y_B - y_A}{x_B - x_A}\\]',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            'Graphiquement, $y_B-y_A$ est l’accroissement des ordonnées, il est noté $\\Delta y$. De même, $\\Delta x = x_B-x_A$.',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
                      \\begin{document} \
                      \\begin{tikzpicture} \
                      \\draw[very thin, style=gray] (-0.2,-0.2) grid (5.2,4.2); \
                      \\coordinate[label=above:\\textcolor{black}{$A$}] (A) at (1,1); \
                      \\coordinate[label=above:\\textcolor{black}{$B$}] (B) at (4,3); \
                      \\fill (A) circle(0.05); \
                      \\fill (B) circle(0.05); \
                      \\draw[red] ($(A)!-0.2!(B)$) -- ($(A)!1.3!(B)$); \
                      \\draw[->, blue] (1,1) -- (4,1) node [pos=0.5, below] {$\\Delta x$}; \
                      \\draw[->, green!80!black] (4,1) -- (B) node [pos=0.5, right] {$\\Delta y$}; \
                      \\end{tikzpicture} \
                      \\end{document}`,
        },
        {
          type: 'text',
          content: 'Ainsi, on note $m = \\frac{\\Delta y}{\\Delta x}$.',
        },
        {
          type: 'text',
          content:
            'Cas particulier : lorsque $\\Delta x = 1$ alors $m = \\Delta y$.',
        },
        {
          type: 'text',
          content:
            'Ainsi les vecteurs $\\vec{u} \\begin{pmatrix} \\Delta x \\\\ \\Delta y \\end{pmatrix}$ et $\\vec{u} \\begin{pmatrix} 1 \\\\ m \\end{pmatrix}$ sont des vecteurs directeurs de $d$.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Une droite d’équation réduite $y=mx+p$ est la représentation graphique de la fonction affine définie par $f(x)=mx+p$.',
            },

            {
              type: 'text',
              content:
                'Deux droites $d : y=mx+p$ et $d’ : y = m’x+p’$ sont parallèles si et seulement si les coefficients directeurs $m$ et $m’$ sont égaux.',
            },
            {
              type: 'text',
              content:
                'Trois points $A$, $B$ et $C$ sont alignés lorsque les droites $(AB)$ et $(AC)$ sont confondues : elles ont alors le même coefficient directeur.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Exemple',
      content: [
        {
          type: 'text',
          content:
            'Soit $d$ la droite passant par les points $A(-2;1)$ et $B(2;3)$.',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
                \\begin{document} \
                \\begin{tikzpicture} \
                \\draw[very thin, style=gray] (-3.2,-1.2) grid (3.2,4.2); \
                \\draw[->, thin] (-3.2,0) -- (3.2,0); \
                \\draw[->, thin] (0,-1.2) -- (0,4.2); \
                \\node [left] at (0,4.2) {$y$}; \
                \\node [below] at (3.2, 0) {$x$}; \
                \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
                \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
                \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
                \\coordinate[label=above:\\textcolor{black}{$A$}] (A) at (-2,1); \
                \\coordinate[label=above:\\textcolor{black}{$B$}] (B) at (2,3); \
                \\fill (O) circle(0.05); \
                \\fill (I) circle(0.05); \
                \\fill (J) circle(0.05); \
                \\fill (A) circle(0.05); \
                \\fill (B) circle(0.05); \
                \\draw[red] ($(A)!-0.2!(B)$) -- ($(A)!1.3!(B)$) node [pos=1, below right] {$d$}; \
                \\end{tikzpicture} \
                \\end{document}`,
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Le coefficient directeur de $d$ est égal à : \\[ m = \\frac{y_B - y_A}{x_B - x_A} = \\frac{3-1}{2+2} = \\frac{1}{2} = 0,5 \\]',
            },
            {
              type: 'text',
              content:
                'Les vecteurs $\\vec{u} \\begin{pmatrix} 4 \\\\ 2 \\end{pmatrix}$ et $\\vec{u} \\begin{pmatrix} 1 \\\\ 0,5 \\end{pmatrix}$ sont deux vecteurs directeurs de $d$.',
            },
          ],
        },
      ],
    },
  ],
  linear_system: [
    {
      type: 'definition',
      title: 'Système de deux équations à deux inconnues',
      subtitle: 'Définitions',
      content: [
        {
          type: 'text',
          content:
            "Un système linéaire de deux équations à deux inconnues $x$ et $y$ est un système qui peut s'écrire sous la forme :\\[ \\begin{cases} ax + by = c \\\\ a'x + b'y = c' \\end{cases} \\]où $a$, $b$, $c$, $a'$, $b'$ et $c'$ sont des nombres réels fixés avec $(a; b) \\neq (0; 0)$ et $(a'; b') \\neq (0; 0)$.",
        },
        {
          type: 'text',
          content:
            'Une solution de ce système est un couple $(x; y)$ de nombres réels tel que $x$ et $y$ vérifient simultanément les deux équations.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Résoudre un système linéaire de deux équations à deux inconnues, c’est déterminer tous les couples $(x; y)$ solutions de ce système.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Soit le système :\\[ \\begin{cases} x + 5y = 19 \\\\ -2x + y = -5 \\end{cases} \\]',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Le couple $(4; 3)$ est solution car il vérifie les deux équations en même temps. En effet, $4 + 5 \\times 3 = 19$ et $-2 \\times 4 + 3 = -5$.',
            },
            {
              type: 'text',
              content:
                'Le couple $(9; 2)$ n’est pas solution car il ne vérifie que la première équation et pas la seconde.\nEn effet, $9 + 5 \\times 2 = 19$ mais $-2 \\times 9 + 2 = -16 \\neq -5$.',
            },
          ],
        },
      ],
    },
  ],
  graphical_interpretation: [
    {
      type: 'simpleText',
      title: 'Système de deux équations à deux inconnues',
      subtitle: 'Interprétation graphique d’un couple solution',
      content: [
        {
          type: 'text',
          content:
            "Un système linéaire de deux équations à deux inconnues peut s’écrire :\\[ \\begin{cases} ax+by-c=0 \\\\ a'x+b'y-c’=0 \\end{cases} \\]",
        },
        {
          type: 'text',
          content:
            "Du fait que, $(a ; b) \\ne (0 ; 0)$ et $(a' ; b') \\ne (0 ; 0)$, ces deux équations correspondent à des équations cartésiennes de deux droites $d$ et $d’$.",
        },
        {
          type: 'text',
          content:
            'Les droites $d$ et $d’$ sont sécantes si et seulement si :\\[ ab’ − a’b \\ne 0 \\]',
        },
        {
          type: 'text',
          content: 'Dans ce cas, elles ont un unique point d’intersection.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            "On considère un système linéaire :\\[ \\begin{cases} ax+by=c \\\\ a'x+b'y=c’ \\end{cases} \\]avec $(a ; b) \\ne (0 ; 0)$ et $(a' ; b') \\ne (0 ; 0)$.",
        },
        {
          type: 'text',
          content:
            'Ce système admet un unique couple solution si et seulement si on a :\\[ ab’ − a’b \\ne 0 \\]',
        },
        {
          type: 'text',
          content:
            "Ce couple $(x ; y)$ correspond aux coordonnées du point d'intersection des deux droites associées aux équations du système.",
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Soit le système :\\[ \\begin{cases} 5x − y = 11 \\\\ 12x − 4y = 15 \\end{cases} \\]',
        },
        {
          type: 'text',
          content:
            'Les équations du système correspondent à deux équations cartésiennes des deux droites $d_1$ et $d_2$.',
        },
        {
          type: 'text',
          content:
            'Or $5 \\times (−4) − 12 \\times (−1)= −8 \\ne 0$, donc les droites $d_1$ et $d_2$ sont sécantes. Le système admet alors un unique couple solution qui correspond aux coordonnées du point d’intersection de $d_1$ et $d_2$.',
        },
      ],
    },
  ],
  system_resolution: [
    {
      type: 'simpleText',
      title: 'Système de deux équations à deux inconnues',
      subtitle: 'Méthodes de résolution d’un système',
      content: [
        {
          type: 'text',
          content:
            "Lorsqu’un système $\\begin{cases} ax+by=c \\\\ a'x+b'y=c' \\end{cases}$ possède un unique couple solution, on décrit ci-dessous deux méthodes (dites par substitution et par combinaison linéaire) pour le déterminer.",
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Le système $\\begin{aligned} \\begin{cases} x+y=6 \\\\ 2x-4y=0 \\end{cases} \\end{aligned}$ possède un unique couple solution car $1 \\times (-4) - 2 \\times 1 = -6 \\neq 0$.',
        },
      ],
    },
    {
      type: 'property',
      header: 'Méthode par substitution',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'On exprime une des inconnues en fonction de l’autre à l’aide d’une équation.',
            },
            {
              type: 'text',
              content: 'On reporte l’expression obtenue dans l’autre équation.',
            },
            {
              type: 'text',
              content:
                'On résout cette dernière équation, à une seule inconnue.',
            },
            {
              type: 'text',
              content: 'On en déduit l’autre inconnue.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Méthode par substitution',
      content: [
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} \\begin{cases} x+y=6 \\\\ 2x-4y=0 \\end{cases} &\\iff \\begin{cases} x=6-y \\\\ 2x-4y=0 \\end{cases} \\\\ &\\iff \\begin{cases} x=6-y \\\\ 2 \\times (6-y) - 4y = 0 \\end{cases} \\\\ &\\iff \\begin{cases} x=6-y \\\\ 12-6y=0 \\end{cases} \\\\ &\\iff \\begin{cases} x=6-y \\\\ y=2 \\end{cases} \\\\ &\\iff \\begin{cases} x=6-2 \\\\ y=2 \\end{cases} \\\\ &\\iff \\begin{cases} x=4 \\\\ y=2 \\end{cases} \\end{aligned} \\]',
        },
      ],
    },
    {
      type: 'property',
      header: 'Méthode par combinaison linéaire',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'On multiplie les deux membres de chaque équation par un nombre de façon à aboutir à deux coefficients opposés devant l’une des inconnues.',
            },
            {
              type: 'text',
              content:
                'On ajoute membre à membre les deux équations et on résout l’équation ainsi obtenue.',
            },
            {
              type: 'text',
              content: 'On en déduit l’autre inconnue.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Méthode par combinaison linéaire',
      content: [
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} \\begin{cases} x+y=6 \\\\ 2x-4y=0 \\end{cases} &\\iff \\begin{cases} 4 \\times (x+y) = 4 \\times 6 \\\\ 2x-4y=0 \\end{cases} \\\\ &\\iff \\begin{cases} 4x + 4y = 24 \\\\ 2x-4y=0 \\end{cases} \\\\ &\\iff \\begin{cases} (4x + 4y) + (2x - 4y) = 24 + 0 \\\\ 2x-4y=0 \\end{cases} \\\\ &\\iff \\begin{cases} 6x=24 \\\\ 2x-4y=0 \\end{cases} \\\\ &\\iff \\begin{cases} x=4 \\\\ 2x-4y=0 \\end{cases} \\\\ &\\iff \\begin{cases} x=4 \\\\ 2 \\times 4 - 4y = 0 \\end{cases} \\\\ &\\iff \\begin{cases} x=4 \\\\ 4y = 8 \\end{cases} \\\\ &\\iff \\begin{cases} x=4 \\\\ y = 2 \\end{cases} \\end{aligned} \\]',
        },
      ],
    },
  ],
} as const;
