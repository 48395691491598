import get from 'lodash/get';
import { MoveRight } from 'lucide-react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  AnswerSuccess,
  getCurrentQuestionStatement,
  NotionLevel,
  NOTIONS,
  ProblemSolving,
} from '@skloover/shared';

import { Lesson } from '~/components/Session/Lesson';
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from '~/components/ui';
import { trpc } from '~/lib';

export const NotionBadges = ({
  problemSolving,
}: {
  problemSolving: ProblemSolving;
}): ReactElement => {
  const { data: notions } = trpc.notionList.useQuery(
    problemSolving.chapterAsObject,
  );
  const { country, program, division, subject, chapter } =
    problemSolving.chapterAsObject;

  const chapterNotions = get(
    NOTIONS,
    `${country}.${program}.${division}.${subject}.${chapter}`,
  );

  const currentQuestionStatement = getCurrentQuestionStatement(problemSolving);

  const getBadgeVariant = (
    chapterNotion: string,
  ): 'empty' | `${AnswerSuccess}-${NotionLevel}` => {
    const notion = notions?.[chapterNotion];
    if (notion?.answerSuccess === undefined) {
      return 'empty';
    }

    return `${notion.answerSuccess}-${notion.level}`;
  };

  const isCurrentNotion = (notion: string) =>
    Object.keys(currentQuestionStatement.notionLevels).includes(notion);

  const hasCurrentNotions =
    Object.keys(currentQuestionStatement.notionLevels).length > 0;

  return (
    <div>
      <div
        className="flex items-center space-x-1 w-full max-w-full overflow-x-auto no-scrollbar shrink-0 mt-2 md:mt-0 mx-2 md:mx-0"
        id="joyride-chapters"
      >
        {hasCurrentNotions && (
          <>
            <div className="whitespace-nowrap text-sm italic font-bold">
              <FormattedMessage id="session.showLesson" />
            </div>
            <div className="text-foreground">
              <MoveRight />
            </div>
          </>
        )}

        {Object.keys(chapterNotions)
          .sort((notion1, notion2) =>
            isCurrentNotion(notion1) && !isCurrentNotion(notion2) ? -1 : 1,
          )
          .map(chapterNotion => (
            <div
              key={chapterNotion}
              id={isCurrentNotion(chapterNotion) ? 'joyride-chapter' : ''}
            >
              {isCurrentNotion(chapterNotion) ? (
                <Dialog>
                  <DialogTrigger>
                    <Badge
                      variant={getBadgeVariant(chapterNotion)}
                      className="whitespace-nowrap border-solid border-4 cursor-pointer"
                    >
                      <FormattedMessage
                        id={`common.chapterAsObject.notion.${chapterNotion}`}
                      />
                    </Badge>
                  </DialogTrigger>
                  <DialogContent className="max-w-95/100 w-95/100 h-95/100 max-h-95/100 md:max-w-9/10 md:w-9/10 md:h-9/10 md:max-h-9/10 overflow-auto flex flex-col">
                    <div className="grow flex flex-col justify-between">
                      <Lesson
                        notionAsObject={{
                          ...problemSolving.chapterAsObject,
                          notion: chapterNotion as keyof typeof chapterNotions,
                        }}
                      />
                      <DialogFooter>
                        <DialogTrigger asChild>
                          <Button>
                            <FormattedMessage id="common.close" />
                          </Button>
                        </DialogTrigger>
                      </DialogFooter>
                    </div>
                  </DialogContent>
                </Dialog>
              ) : (
                <Badge
                  className="whitespace-nowrap"
                  variant={getBadgeVariant(chapterNotion)}
                >
                  <FormattedMessage
                    id={`common.chapterAsObject.notion.${chapterNotion}`}
                  />
                </Badge>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
