import { useCallback, useState } from 'react';

export const useRefHeight = (): {
  ref: (node: HTMLDivElement | null) => void;
  height: number;
} => {
  const [height, setHeight] = useState<number>(0);
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      setHeight(node.getBoundingClientRect().height);
    });
    resizeObserver.observe(node);
  }, []);

  return { ref, height };
};
