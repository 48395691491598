import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ChapterAsObject,
  ChatHistory,
  Statement as StatementType,
} from '@skloover/shared';

import { Breakpoint } from '~/lib';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui';
import { FormulaAnswer } from './Answer/FormulaAnswer';
import { TableAnswer } from './Answer/Table';
import { TextAnswer } from './Answer/Text';
import { CHAT_ACTIONS_ID } from './constants';

interface AnswersAccordionProps {
  chatHistory?: ChatHistory;
  chapterAsObject: ChapterAsObject;
  questionStatement: Extract<StatementType, { type: 'question' }>;
  submit: ({ message }: { message: string }) => void;
  isChatUpdatePending: boolean;
  breakpoint: Breakpoint;
}

export const AnswersAccordion = ({
  chatHistory = [],
  chapterAsObject,
  questionStatement,
  submit,
  breakpoint,
  isChatUpdatePending,
}: AnswersAccordionProps): ReactElement => {
  const [chatActionsDomNode, setChatActionsDomNode] = useState<Element | null>(
    null,
  );
  useEffect(() => {
    setChatActionsDomNode(
      document.getElementById(`${breakpoint}:${CHAT_ACTIONS_ID}`),
    );
  }, [setChatActionsDomNode, breakpoint]);

  const input = questionStatement.input;
  const [accordionValue, setAccordionValue] = useState<string>(
    input?.type ?? 'chat',
  );
  useEffect(() => {
    setAccordionValue(questionStatement.input?.type ?? 'chat');
  }, [setAccordionValue, questionStatement.input]);

  return (
    <Accordion type="single" value={accordionValue} className="w-full">
      {input?.type === 'formula' && (
        <AccordionItem
          value="formula"
          onClick={() => setAccordionValue('formula')}
        >
          <AccordionTrigger
            className="py-1"
            id={`joyride-formula-${breakpoint}`}
          >
            <FormattedMessage id="session.formula" />
          </AccordionTrigger>
          <AccordionContent className="p-1 pb-2">
            <FormulaAnswer
              symbols={input.symbols}
              placeholder={input.placeholder}
              submit={submit}
              chatActionsDomNode={chatActionsDomNode}
              open={accordionValue === 'formula'}
              breakpoint={breakpoint}
              chapterAsObject={chapterAsObject}
            />
          </AccordionContent>
        </AccordionItem>
      )}
      {input?.type === 'table' && (
        <AccordionItem value="table" onClick={() => setAccordionValue('table')}>
          <AccordionTrigger className="py-1">
            <FormattedMessage id="session.table" />
          </AccordionTrigger>
          <AccordionContent className="p-1 pb-2 max-h-[210px] overflow-auto">
            <TableAnswer
              tableInput={input}
              submit={submit}
              chatActionsDomNode={chatActionsDomNode}
              open={accordionValue === 'table'}
            />
          </AccordionContent>
        </AccordionItem>
      )}
      <AccordionItem value="chat" onClick={() => setAccordionValue('chat')}>
        <AccordionTrigger className="py-1" id={`joyride-chat-${breakpoint}`}>
          <FormattedMessage id="session.chat" />
        </AccordionTrigger>
        <AccordionContent className="p-1 pb-2">
          <TextAnswer
            chatHistory={chatHistory}
            chapterAsObject={chapterAsObject}
            questionStatement={questionStatement}
            submit={submit}
            isChatUpdatePending={isChatUpdatePending}
            chatActionsDomNode={chatActionsDomNode}
            open={accordionValue === 'chat'}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
