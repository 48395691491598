/* eslint-disable complexity */

/* eslint-disable max-lines */
import set from 'lodash/set';
import { CirclePlus, Trash2 } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { TableInput } from '@skloover/shared';

import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/components/ui';

interface TableAnswerProps {
  tableInput: TableInput;
  submit: ({ message }: { message: string }) => void;
  chatActionsDomNode: Element | null;
  open: boolean;
}

export const TableAnswer = ({
  tableInput,
  submit,
  chatActionsDomNode,
  open,
}: TableAnswerProps): ReactElement => {
  const [tableValues, setTableValues] = useState<string[][]>(
    tableInput.defaultValues.map(row => row.map(value => value ?? '')),
  );

  const updateTableValue = ({
    rowIndex,
    columnIndex,
    value,
  }: {
    rowIndex: number;
    columnIndex: number;
    value: string;
  }) => {
    const updatedTableValues = tableValues.slice();

    set(updatedTableValues, `${rowIndex}.${columnIndex}`, value);

    setTableValues(updatedTableValues);
  };

  const submitTable = () => {
    const tablePrefix = `\\[ \\begin{array}{|${tableValues[0].map((_, index) => (index === 0 ? 'l|' : 'c|')).join()}} \\hline`;
    const tableContent = `${tableValues.map(row => row.map(value => `\\text{${value}}`).join(' & ')).join(' \\\\ \\hline ')}`;
    const tableSuffix = '\\\\ \\hline \\end{array} \\]';

    submit({ message: `${tablePrefix} ${tableContent} ${tableSuffix}` });
  };

  const addColumn = () => {
    const updatedTableValues = tableValues.slice();
    updatedTableValues.map(row => row.push(''));
    setTableValues(updatedTableValues);
  };
  const removeColumn = () => {
    const updatedTableValues = tableValues.slice();
    updatedTableValues.map(row => row.pop());
    setTableValues(updatedTableValues);
  };
  const addLine = () => {
    const updatedTableValues = tableValues.slice();
    updatedTableValues.push(updatedTableValues[0].map(() => ''));
    setTableValues(updatedTableValues);
  };
  const removeLine = () => {
    const updatedTableValues = tableValues.slice();
    updatedTableValues.pop();
    setTableValues(updatedTableValues);
  };

  return (
    <>
      <div className="grid grid-cols-[auto_auto]">
        <Table>
          {tableInput.header.isPresent && (
            <TableHeader>
              <TableRow className="hover:bg-muted/0">
                {tableValues[0].map((header, valueIndex) => (
                  <TableHead
                    key={valueIndex}
                    className="p-1 text-center border bg-secondary h-auto"
                  >
                    {tableInput.header.editable ? (
                      <Input
                        type="text"
                        className="min-w-[70px]"
                        value={header}
                        onChange={event =>
                          updateTableValue({
                            rowIndex: 0,
                            columnIndex: valueIndex,
                            value: event.target.value,
                          })
                        }
                      />
                    ) : (
                      header
                    )}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {tableValues
              .slice(tableInput.header.isPresent ? 1 : 0)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex} className="hover:bg-muted/0">
                  {row.map((value, columnIndex) => (
                    <TableCell
                      key={columnIndex}
                      className={`p-1 border ${
                        columnIndex === 0 &&
                        tableInput.lineDescription.isPresent
                          ? 'bg-secondary'
                          : ''
                      }`}
                    >
                      {columnIndex === 0 &&
                      tableInput.lineDescription.isPresent &&
                      !tableInput.lineDescription.editable ? (
                        value
                      ) : (
                        <Input
                          type="text"
                          value={value}
                          className="p-1 text-center min-w-[70px]"
                          onChange={event =>
                            updateTableValue({
                              rowIndex: tableInput.header.isPresent
                                ? rowIndex + 1
                                : rowIndex,
                              columnIndex,
                              value: event.target.value,
                            })
                          }
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {tableInput.addColumn ? (
          <div className="flex items-center p-2">
            <div className="flex flex-col">
              <Button variant="ghost" size="icon" onClick={addColumn}>
                <CirclePlus />
              </Button>
              {tableValues[0].length > tableInput.defaultValues[0].length && (
                <Button
                  variant="ghost"
                  className="text-destructive hover:text-destructive"
                  size="icon"
                  onClick={removeColumn}
                >
                  <Trash2 />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {tableInput.addLine ? (
          <div className="flex items-center justify-around p-2">
            <div className="flex">
              <Button variant="ghost" size="icon" onClick={addLine}>
                <CirclePlus />
              </Button>
              {tableValues.length > tableInput.defaultValues.length && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-destructive hover:text-destructive"
                  onClick={removeLine}
                >
                  <Trash2 />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {open &&
        chatActionsDomNode !== null &&
        createPortal(
          <Button size="sm" onClick={submitTable}>
            <FormattedMessage id="common.send" />
          </Button>,
          chatActionsDomNode,
        )}
    </>
  );
};
