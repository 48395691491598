/* eslint-disable max-lines */
export const calculation = {
  algebric_expressions: [
    {
      type: 'simpleText',
      title: 'Manipulations algébriques',
      subtitle: 'Différentes formes d’une expression algébrique',
      content: [
        {
          type: 'text',
          content:
            "On utilise les lettres pour désigner une grandeur qui varie (on parle alors de variable) ou une grandeur inconnue. Le calcul littéral permet de transformer une expression algébrique pour résoudre des problèmes (étudier l’évolution d’une quantité dépendant d'une variable, chercher la ou les valeurs d'une inconnue, démontrer une propriété…). Une expression algébrique peut s’écrire sous différentes formes :",
        },
      ],
    },
    {
      type: 'example',
      header: 'Forme développée (somme algébrique de termes)',
      content: [
        {
          type: 'list',
          content: [
            { type: 'text', content: '\\( 2x^2 - 11x + 12 \\)' },
            {
              type: 'text',
              content:
                'Pour tout réel \\( x \\neq 1 \\) : \\( 2 - \\frac{3}{x-1} \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Forme factorisée (produit ou quotient de facteurs)',
      content: [
        {
          type: 'list',
          content: [
            { type: 'text', content: '\\( (x-4)(2x-3) \\)' },
            {
              type: 'text',
              content:
                'Pour tout réel \\( x \\neq 1 \\) : \\( \\frac{2x-5}{x-1} \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      content: [
        {
          type: 'text',
          content:
            "L'expression \\( A = (2x)^2 - 2x(x+1) \\) est une somme algébrique.",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Sa forme développée est :\\[ A = 4x^2 - 2x^2 - 2x = 2x^2 - 2x \\]',
            },
            {
              type: 'text',
              content: 'Sa forme factorisée est \\[ A = 2x(x-1) \\]',
            },
          ],
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'On appelle \\( \\textbf{identité} \\) une égalité qui est vraie quelles que soient les valeurs données aux différentes lettres qui interviennent.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            "L'expression \\( A \\) s'écrit ainsi : \\[ A = 2x^2 - 2x = 2x(x-1) \\]",
        },
        {
          type: 'text',
          content:
            'Ces égalités sont valables pour \\( \\textbf{toute} \\) valeur de \\( x \\), ce sont des \\( \\textbf{identités} \\).',
        },
      ],
    },
  ],
  distributivity: [
    {
      type: 'property',
      title: 'Manipulations algébriques',
      subtitle: 'Formules de distributivité',
      content: [
        {
          type: 'text',
          content:
            'Pour tous nombres \\( a, b, c, d \\text{ et } k \\), on a les identités suivantes.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( k(a + b) = ka + kb \\)',
            },
            {
              type: 'text',
              content: '\\( k(a - b) = ka - kb \\)',
            },
            {
              type: 'text',
              content: '\\( (a + b)(c + d) = ac + ad + bc + bd \\)',
            },
          ],
        },
        {
          type: 'text',
          content:
            "On dit qu'on \\( \\textbf{développe} \\) les expressions lorsqu'on va de la gauche vers la droite.\nOn dit qu'on \\( \\textbf{factorise} \\) les expressions lorsqu'on va de la droite vers la gauche.",
        },
      ],
    },
  ],
  remarkable_identities: [
    {
      type: 'property',
      title: 'Manipulations algébriques',
      subtitle: 'Identités remarquables',
      content: [
        {
          type: 'text',
          content:
            'Pour tous nombres \\( a \\) et \\( b \\), on a les identités suivantes :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( (a + b)^2 = a^2 + 2ab + b^2 \\)',
            },
            {
              type: 'text',
              content: '\\( (a - b)^2 = a^2 - 2ab + b^2 \\)',
            },
            {
              type: 'text',
              content: '\\( (a + b)(a - b) = a^2 - b^2 \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Exemples',
      content: [
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} (3x + 4)^2 &= (3x)^2 + 2 \\times 3x \\times 4 + 4^2 \\\\ &= 9x^2 + 24x + 16 \\end{aligned} \\)',
        },
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} (5x - 1)(5x + 1) &= (5x)^2 - 1^2 \\\\ &= 25x^2 - 1 \\end{aligned} \\)',
        },
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} (3 - 2x)^2 &= 3^2 - 2 \\times 3 \\times 2x + (2x)^2 \\\\ &= 9 - 12x + 4x^2 \\\\ &= 4x^2 - 12x + 9 \\end{aligned} \\)',
        },
      ],
    },
  ],
  factorisation: [
    {
      type: 'simpleText',
      title: 'Manipulations algébriques',
      subtitle: 'Techniques de factorisation',
      content: [
        {
          type: 'text',
          content:
            'Pour factoriser une expression algébrique, on utilise essentiellement deux techniques :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: 'En repérant un \\( \\textbf{facteur commun} \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} (3-x)(5x+4)-2(3-x)(2x+1) &= (3-x)[(5x+4)-2(2x+1)] \\\\ &= (3-x)(5x+4-4x-2) \\\\ &= (3-x)(x+2) \\end{aligned} \\)',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'en reconnaissant une \\( \\textbf{identité remarquable} \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Exemples',
      content: [
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} 49x^2-28x+4 &= (7x)^2 - 2 \\times 7x \\times 2 + 2^2 \\\\ &= (7x-2)^2 \\end{aligned} \\)',
        },
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} 36x^2-100 &= (6x)^2-10^2 \\\\ &= (6x-10)(6x+10) \\end{aligned} \\)',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Pour factoriser une expression, il peut être parfois nécessaire de combiner ces deux techniques pour faire apparaître un facteur commun.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            '\\( \\begin{aligned} x(3x+6)+x^2-4 &= x \\times 3(x+2) + (x+2)(x-2) \\\\ &= 3x(x+2)+(x+2)(x-2) \\\\ &= (x+2)(4x-2) \\end{aligned} \\)',
        },
      ],
    },
  ],
  quotient_product: [
    {
      type: 'definition',
      title: 'Manipulations algébriques',
      subtitle: 'Calculs algébriques sur les quotients',
      content: [
        {
          type: 'text',
          content:
            'Pour tous réels \\( a, b \\) et \\( k \\) avec \\( b \\neq 0 \\) et \\( k \\neq 0 \\), on a :\\[ \\frac{a}{b} = \\frac{ka}{kb} \\quad \\text{et} \\quad \\frac{a}{b} = \\frac{a + k}{b + k} \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Pour tous réel \\( x \\neq 0 \\), on a :',
        },
        {
          type: 'text',
          content:
            '\\[ \\frac{4}{5} = \\frac{2 \\times 4}{2 \\times 5} = \\frac{8}{10} \\]',
        },
        {
          type: 'text',
          content:
            '\\[ \\frac{9x}{6x^2} = \\frac{3 \\times 3 \\times x}{2 \\times 3 \\times x \\times x} = \\frac{3}{2x} \\]',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} \\frac{7}{10} + \\frac{5}{6} &= \\frac{3 \\times 7}{3 \\times 10} + \\frac{5 \\times 5}{5 \\times 6} \\\\ &= \\frac{21 + 25}{30} = \\frac{46}{30} = \\frac{23}{15} \\end{aligned} \\]',
        },
      ],
    },
  ],
  quotient_operations: [
    {
      type: 'property',
      title: 'Manipulations algébriques',
      subtitle: 'Calculs algébriques sur les quotients',
      header: 'Propriétés opératoires des quotients',
      content: [
        {
          type: 'text',
          content:
            'Pour tous réels \\( a, b, c \\) et \\( d \\) avec \\( c \\neq 0 \\) et \\( d \\neq 0 \\), on a :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\[ \\frac{a}{d} + \\frac{b}{d} = \\frac{a+b}{d} \\]',
            },
            {
              type: 'text',
              content: '\\[ \\frac{a}{d} - \\frac{b}{d} = \\frac{a-b}{d} \\]',
            },
            {
              type: 'text',
              content:
                '\\[ \\frac{a}{c} \\times \\frac{b}{d} = \\frac{a \\times b}{c \\times d} \\]',
            },
          ],
        },
        {
          type: 'text',
          content:
            'Pour additionner ou soustraire deux quotients, on les réduit préalablement au même dénominateur.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Pour tout réel \\( x \\) distinct de \\( 0 \\) et \\( -0,5 \\) on a les égalités :',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} \\frac{5}{x} - \\frac{3 - x}{2x + 1} &= \\frac{5 \\times (2x + 1)}{x \\times (2x + 1)} - \\frac{(3 - x) \\times x}{(2x + 1) \\times x} \\\\ &= \\frac{(10x + 5) - (3x - x^2)}{x(2x + 1)} \\\\ &= \\frac{x^2 + 7x + 5}{x(2x + 1)} \\end{aligned} \\]',
        },
        {
          type: 'text',
          content:
            "Le quotient \\( \\frac{5}{x} \\) n'est pas défini pour \\( x = 0 \\). On dit que 0 est valeur interdite pour ce quotient. De même -0,5 est valeur interdite de \\( \\frac{3 - x}{2x + 1} \\).",
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Pour tous réel \\( x \\neq 0 \\), on a :',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} \\frac{5}{x^2} - 3 &= \\frac{5}{x^2} - \\frac{3}{1} \\\\ &= \\frac{5}{x^2} - \\frac{3 \\times x^2}{1 \\times x^2} \\\\ &= \\frac{5-3x^2}{x^2} \\end{aligned} \\]',
        },
      ],
    },
  ],
  quotient_inverse: [
    {
      type: 'property',
      title: 'Manipulations algébriques',
      subtitle: 'Calculs algébriques sur les quotients',
      content: [
        {
          type: 'text',
          content:
            'Pour tous réels \\( a, b, c \\) et \\( d \\) avec \\( b, c, d \\) non nuls, on a :',
        },
        {
          type: 'text',
          content:
            "L'inverse de \\( \\frac{b}{d} \\) est \\( \\frac{d}{b} \\), autrement dit :",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\[ \\frac{1}{\\frac{b}{d}} = \\frac{d}{b} \\]',
            },
            {
              type: 'text',
              content:
                '\\[ \\frac{a}{c} \\div \\frac{b}{d}  = \\frac{a \\times d}{c \\times b} \\]',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Pour tous réel \\( a \\neq 0 \\), on a :',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} \\frac{5}{4a^2} \\div \\frac{15}{6a} &= \\frac{5}{4a^2} \\times \\frac{6a}{15} \\\\ &= \\frac{2 \\times 3 \\times 5 \\times a}{2 \\times 2 \\times 3 \\times 5 \\times a \\times a} \\\\ &= \\frac{1}{2a} \\end{aligned} \\]',
        },
      ],
    },
  ],
  comparison_sign: [
    {
      type: 'definition',
      title: 'Comparaison de deux quantités',
      subtitle: 'Signe et comparaison de deux nombres',
      content: [
        {
          type: 'text',
          content:
            'Étant donnés \\( a \\) et \\( b \\) deux nombres réels. On dit que \\( a \\) est \\( \\textbf{strictement supérieur} \\) (resp. strictement inférieur) à \\( b \\) et on note \\( a > b \\) lorsque \\( a - b > 0 \\) (resp. \\( a - b < 0 \\)). Ainsi \\( a > b \\iff a - b > 0 \\).',
        },
      ],
    },
    {
      type: 'additionalNote',
      content: [
        {
          type: 'text',
          content:
            'Comparer deux nombres, c’est déterminer s’ils sont égaux et sinon, c’est déterminer lequel est le plus grand des deux.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Pour comparer les nombres :\\[ A = 2\\pi \\text{ et } B = 4\\sqrt{3} \\]On obtient par la calculatrice les valeurs approchées :\\[ A = 6,3 \\text{ et } B = 6,9 \\]D'où \\( A < B \\).",
            },
            {
              type: 'text',
              content:
                "Pour comparer les fractions :\\[ C = \\frac{7}{9} \\text { et } D = \\frac{12}{11} \\]On remarque que \\( C < 1 < D \\) ou en réduisant au même dénominateur :\\[ C = \\frac{77}{99} \\text{ et } D = \\frac{108}{99} \\]D'où \\( C < D \\).",
            },
          ],
        },
      ],
    },
  ],
  comparison_criteria: [
    {
      type: 'property',
      title: 'Comparaison de deux quantités',
      subtitle: 'Critères de comparaison de deux nombres',
      content: [
        {
          type: 'text',
          content:
            'Pour comparer les nombres \\( a \\) et \\( b \\), on peut utiliser deux critères :',
        },
        {
          type: 'text',
          content:
            '\\( \\boxed{\\text{Critère de la différence}} \\) On étudie le \\( \\textbf{signe} \\) de la différence \\( a - b \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( \\text{ Si } a - b < 0 \\text{ alors } a < b . \\)',
            },
            {
              type: 'text',
              content: '\\( \\text{ Si } a - b > 0 \\text{ alors } a > b . \\)',
            },
          ],
        },
        {
          type: 'text',
          content:
            '\\( \\boxed{\\text{Critère du quotient}} \\) Si \\( a, b \\) sont \\( \\textbf{strictement positifs} \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( \\text{ Si } \\frac{a}{b} < 1 \\text{ alors } a < b . \\)',
            },
            {
              type: 'text',
              content:
                '\\( \\text{ Si } \\frac{a}{b} > 1 \\text{ alors } a > b . \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration du critère du quotient',
      content: [
        {
          type: 'text',
          content:
            'Soient \\( a \\) et \\( b \\) deux réels strictement positifs tels que \\( \\frac{a}{b} < 1 \\). En multipliant chaque membre par \\( b \\), le sens de l’inégalité est conservé d’où \\( \\frac{a}{b} \\times b < 1 \\times b \\) soit \\( a < b \\).',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            'Enfin, pour comparer deux nombres, on peut également exploiter les propriétés des inégalités ou les variations d’une fonction.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Étant donné un nombre \\( a < 0 \\), on souhaite comparer : \\[ E = (2a+3)^2 \\text{ et } F = 4a^2+9 \\]\nOn étudie pour cela le signe de la différence :\\[ E - F = (2a+3)^2 - (4a^2+9) = 12a \\]\nPuisque \\( a < 0 \\), alors \\( 12a < 0 \\) d'où \\( E < F \\).",
            },
            {
              type: 'text',
              content:
                "Soit \\( x \\) un réel vérifiant \\( 0 < x < 1 \\). Pour comparer \\( G = x^2 \\) et \\( H = x^3 \\) qui sont strictement positifs, on peut comparer le quotient \\( \\frac{H}{G} \\) avec 1 :\nOn a \\( \\frac{H}{G} = \\frac{x^3}{x^2} = x \\) or \\( x < 1 \\) donc \\( \\frac{H}{G} < 1 \\).\nD'où \\( H < G \\) soit \\( x^3 < x^2 \\).",
            },
          ],
        },
      ],
    },
  ],
  equations: [
    {
      type: 'definition',
      title: 'Résolution algébrique d’équations',
      subtitle: 'Équations équivalentes et opérations',
      content: [
        {
          type: 'text',
          content:
            'Une \\( \\textbf{équation d’inconnue } x \\) est une égalité dans laquelle intervient un nombre inconnu \\( x \\).',
        },
        {
          type: 'text',
          content:
            'Résoudre dans un ensemble \\( E \\) une telle équation, c’est déterminer toutes les valeurs de \\( x \\) appartenant à \\( E \\) qui rendent l’égalité vraie.',
        },
        {
          type: 'text',
          content:
            'Ces valeurs sont les \\( \\textbf{solutions dans } E \\) de l’équation.',
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'On dit que deux équations sont \\( \\textbf{équivalentes} \\) lorsqu’elles ont les mêmes solutions.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Les manipulations algébriques suivantes transforment une équation en une équation équivalente.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Ajouter (ou soustraire) un même nombre aux deux membres d’une équation.',
            },
            {
              type: 'text',
              content:
                'Multiplier (ou diviser) les deux membres d’une équation par un même nombre \\( \\textbf{non nul} \\).',
            },
            {
              type: 'text',
              content:
                'Développer, factoriser, réduire l’un des deux membres de l’équation.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Exemples',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "L’équation \\(2x + x - 2 = 0 \\ (E) \\) admet 1 et -2 comme solutions dans \\( \\mathbb{R} \\). En effet :\n\\( 1^2 + 1 - 2 = 2-2 = 0 \\text{ et } (-2)^2 + (-2) - 2 = 0 \\)\nL'équation \\(2x + 3 = -x + 6 \\ (E')\\) admet 1 comme unique solution.\nAinsi, les équations \\( (E) \\) et \\( (E') \\) ne sont pas équivalentes.",
            },
            {
              type: 'text',
              content:
                "L’équation \\( (E') \\) est équivalente à l’équation :\\[ \\begin{aligned} 2x + 3 - 3 &= x + 6 - 3 \\\\ 2x &= -x + 3 \\end{aligned} \\]On note alors : \\[ 2x + 3 = x + 6 \\iff 2x = -x + 3 \\]Le symbole \\( \\iff \\) signifiant que les deux équations sont équivalentes.",
            },
          ],
        },
      ],
    },
    {
      type: 'simpleText',
      subtitle: 'Application : équations du premier degré',
      content: [
        {
          type: 'text',
          content:
            'Pour résoudre une équation du 1er degré, on isole l’inconnue dans un membre de l’égalité en utilisant les propriétés d’équivalences.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} 2x + 3 = -x + 6 &\\iff 2x = -x + 3 \\\\ &\\iff 2x + x = -x + 3 + x \\\\ &\\iff 3x = 3 \\\\ &\\iff \\frac{3}{3}x = \\frac{3}{3} \\\\ &\\iff x = 1 \\end{aligned} \\]',
        },
        {
          type: 'text',
          content:
            "L'ensemble \\( S \\) des solutions dans \\( \\mathbb{R} \\) de cette équation est \\( S = \\{ 1 \\} \\).",
        },
      ],
    },
  ],
  first_degree: [
    {
      type: 'simpleText',
      title: 'Résolution algébrique d’équations',
      subtitle: 'Équations se ramenant au premier degré',
      content: [
        {
          type: 'text',
          content:
            'La résolution de certaines équations se ramène parfois à celle d’équations du premier degré.',
        },
        {
          type: 'text',
          content:
            'Pour cela, on peut regrouper tous les termes dans le même membre et tenter de factoriser ce membre.',
        },
        {
          type: 'text',
          content:
            'On est ramené ainsi à résoudre des équations du type \\( \\textbf{équation produit nul} \\) ou \\( \\textbf{équation quotient nul} \\).',
        },
        {
          type: 'text',
          content: 'On exploite alors les propriétés suivantes :',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( \\boxed{\\text{Règle du produit nul}} \\) : un produit de deux nombres réels est nul si et seulement si au moins l’un de ses facteurs est nul. Autrement dit, on a l’équivalence : \\[ A(x) \\times B(x) = 0 \\Leftrightarrow A(x) = 0 \\text{ ou } B(x) = 0 \\]',
            },
            {
              type: 'text',
              content:
                '\\( \\boxed{\\text{Règle du quotient nul}} \\) : un quotient de deux nombres réels est nul si et seulement si le numérateur est nul et le dénominateur est non nul. Autrement dit, on a l’équivalence : \\[ \\frac{A(x)}{B(x)} = 0 \\Leftrightarrow A(x) = 0 \\textbf{ ET } B(x) \\neq 0 \\]',
            },

            {
              type: 'text',
              content:
                'Les valeurs de \\( x \\) qui annulent le dénominateur sont appelées \\( \\textbf{valeurs interdites} \\) du quotient.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Pour résoudre des équations s’écrivant sous la forme \\( \\frac{a}{b} = \\frac{c}{d} \\) avec \\( a, b, c \\) et \\( d \\) réels tels que \\( b \\) et \\( d \\) sont \\( \\textbf{non nuls} \\), on peut exploiter le \\( \\textbf{produit en croix} \\) : \\[ \\frac{a}{b} = \\frac{c}{d} \\text{ si et seulement si } a \\times d = b \\times c \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "L'équation \\( (3x + 1)(x-1) = 0 \\) est équivalente à :\\[ \\begin{aligned} 3x+1 = 0 \\text{ ou } x-1 = 0 \\\\ \\iff 3x = -1 \\text{ ou } x = 1 \\end{aligned} \\]Cette équation admet \\( -\\frac{1}{3} \\) et \\( 1 \\) comme uniques solutions.",
            },
            {
              type: 'text',
              content:
                "L'équation \\( (5x - 1)(x+1) = 0 \\) admet pour valeur interdite \\( -1 \\). Elle est équivalente à :\\[ \\begin{aligned} 5x-1 = 0 \\text{ et } x \\neq -1 \\\\ \\iff x = \\frac{1}{5} \\text{ et } x \\neq -1 \\end{aligned} \\]\\( \\frac{1}{5} \\) est différent de \\( -1 \\), c'est donc l'unique solution de l'équation.",
            },
            {
              type: 'text',
              content:
                'Pour tout réel \\( x \\notin \\{ 3; -1 \\} \\), les équations \\( \\frac{x}{x-3} = \\frac{x-2}{x+1} \\) et \\( x(x+1) = (x-2)(x-3) \\) sont équivalentes. Ces équations admettent 1 comme unique solution.',
            },
          ],
        },
      ],
    },
  ],
} as const;
