import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import Joyride from 'react-joyride';
import { Link } from 'react-router-dom';

import { AppRoute, useBreakpoint, useIntl, useJoyride, useSteps } from '~/lib';

import { Button, Separator } from '../ui';
import { Actions } from './Actions';

interface LayoutProps {
  children?: ReactElement;
  showTutorial?: boolean;
}

export const Layout = ({
  children,
  showTutorial = false,
}: LayoutProps): ReactElement => {
  const t = useIntl();
  const { startTutorial, runTutorial, handleJoyrideCallback } = useJoyride();
  const breakpoint = useBreakpoint();

  const steps = useSteps();

  return (
    <div className="h-full">
      <div className="h-full flex flex-col">
        <div className="container flex flex-row flex-none items-center space-y-0 justify-between py-4">
          <div className="flex items-center gap-4">
            <Link to={AppRoute.Home}>
              <h2 className="text-xl font-bold">
                <FormattedMessage id="common.title" />
              </h2>
            </Link>
            {showTutorial && breakpoint !== 'sm' && (
              <Button
                className="text-sm bg-orange-50 border-orange-900 text-orange-900 hover:border-input"
                variant="outline"
                onClick={startTutorial}
              >
                <FormattedMessage id="joyride.tutorial" />
              </Button>
            )}
            <Joyride
              continuous
              callback={handleJoyrideCallback}
              hideCloseButton
              showSkipButton
              disableScrollParentFix
              run={runTutorial}
              steps={steps}
              locale={{
                back: t.formatMessage({ id: 'joyride.back' }),
                close: t.formatMessage({ id: 'joyride.close' }),
                last: t.formatMessage({ id: 'joyride.last' }),
                next: t.formatMessage({ id: 'joyride.next' }),
                open: t.formatMessage({ id: 'joyride.open' }),
                skip: t.formatMessage({ id: 'joyride.skip' }),
              }}
              styles={{
                options: {
                  primaryColor: '#000',
                },
              }}
            />
          </div>
          <div className="ml-auto flex w-full space-x-2 justify-end">
            <Actions />
          </div>
        </div>
        <Separator />
        <div className="md:container grow min-h-0 md:py-6">{children}</div>
      </div>
    </div>
  );
};
