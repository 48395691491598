import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { AnswerSuccess, NotionAsObject } from '@skloover/shared';

import { Button, Dialog, DialogContent, DialogFooter, Separator } from '../ui';
import { Lesson } from './Lesson';

interface ChatActionsProps {
  questionSummary?: {
    notionAsObjects: NotionAsObject[];
    answerSuccess: AnswerSuccess;
  };
  resetQuestionSummary: () => void;
}

export const QuestionSummaryDialog = ({
  questionSummary,
  resetQuestionSummary,
}: ChatActionsProps): ReactElement => (
  <>
    {questionSummary !== undefined &&
      questionSummary.answerSuccess === 'low' && (
        <Dialog open onOpenChange={resetQuestionSummary}>
          <FormattedMessage
            id={`session.answerSuccess.${questionSummary.answerSuccess}`}
          />
          <DialogContent className="sm:max-w-3/4 max-h-9/10 overflow-auto flex flex-col">
            {questionSummary.notionAsObjects.map((notionAsObject, index) => (
              <div key={index}>
                {index > 0 ? <Separator className="mb-4" /> : <></>}
                <Lesson notionAsObject={notionAsObject} />
              </div>
            ))}
            <DialogFooter>
              <Button onClick={resetQuestionSummary}>
                <FormattedMessage id="common.close" />
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
  </>
);
