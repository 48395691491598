import { MathJax } from 'better-react-mathjax';
import { ReactElement } from 'react';
import RenderIfVisible from 'react-render-if-visible';

import { ChatHistory } from '@skloover/shared';

interface MessageProps {
  speaker: 'ai' | 'human';
  content: string;
}

const Message = ({ speaker, content }: MessageProps): ReactElement => {
  const place = speaker === 'ai' ? 'self-start' : 'self-end';
  const className =
    speaker === 'ai'
      ? 'bg-gray-200 rounded-ee-xl'
      : 'bg-gray-50  rounded-es-xl';

  return (
    <div className={`${place} max-w-3/4 my-1`}>
      <RenderIfVisible>
        <div className={`${className} rounded-se-xl rounded-ss-xl p-2`}>
          <MathJax
            dynamic
            className="overflow-x-auto [&_mjx-container]:inline-block [&_mjx-container]:overflow-visible [&_mjx-container]:no-scrollbar"
          >
            {content}
          </MathJax>
        </div>
      </RenderIfVisible>
    </div>
  );
};

interface MessagesProps {
  chat?: ChatHistory;
}

export const Messages = ({ chat = [] }: MessagesProps): ReactElement => (
  <div className="flex-1 whitespace-pre-wrap p-4 md:p-2 text-sm min-h-0 overflow-hidden hover:overflow-y-auto scroll-smooth flex flex-col-reverse">
    {chat
      .slice()
      .reverse()
      .map(([speaker, content], index) => (
        <Message key={index} speaker={speaker} content={content} />
      ))}
  </div>
);
