import { fetchAuthSession, signOut } from '@aws-amplify/auth';
import { QueryClientContext, useQuery } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { ReactElement, useContext, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { Country, Division } from '@skloover/shared';

import { Layout } from '~/components';
import { LoadingSpinner } from '~/components/icons/LoadingSpinner';
import { AppRoute } from '~/lib';

import { SessionContext } from './sessionContext';

export const AuthenticationRequired = (): ReactElement => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const queryClient = useContext(QueryClientContext);
  const {
    data: authSession,
    isError: isAuthSessionError,
    isLoading: isAuthSessionLoading,
  } = useQuery({
    queryKey: ['authSession'],
    queryFn: () => fetchAuthSession(),
    retry: false,
  });

  useEffect(() => {
    const signoutAndNavigate = async () => {
      await signOut({ global: true });
      await queryClient?.invalidateQueries({ queryKey: ['authSession'] });
      navigate(AppRoute.Login);
    };
    if (
      isAuthSessionError ||
      (authSession?.tokens === undefined && !isAuthSessionLoading)
    ) {
      void signoutAndNavigate();
    }
  }, [
    authSession?.tokens,
    navigate,
    queryClient,
    isAuthSessionError,
    isAuthSessionLoading,
  ]);

  if (isAuthSessionLoading || authSession?.tokens === undefined) {
    return (
      <Layout>
        <div className="h-full flex items-center justify-around">
          <LoadingSpinner />
        </div>
      </Layout>
    );
  }

  const payload = authSession.tokens.idToken?.payload;

  if (!validatePayload(payload)) {
    void signOut({ global: true });
    void queryClient?.invalidateQueries({ queryKey: ['authSession'] });

    return <Navigate to={AppRoute.Error} />;
  }

  posthog.identify(payload.sub, {
    email: payload.email,
  });

  return (
    <SessionContext.Provider
      value={{
        email: payload.email,
        id: payload.sub,
        firstName: payload.given_name,
        lastName: payload.family_name,
        admin: payload.admin === 'true',
        country: payload.country,
        program: '2024',
        division: payload.division,
      }}
    >
      <Outlet />
    </SessionContext.Provider>
  );
};

const validatePayload = (
  payload: { [key: string]: unknown } | undefined,
): payload is {
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  division: Division;
  country: Country;
  admin: string;
} => {
  if (payload === undefined) {
    return false;
  }

  const isValidPayload =
    typeof payload.sub === 'string' &&
    typeof payload.given_name === 'string' &&
    typeof payload.family_name === 'string' &&
    typeof payload.email === 'string' &&
    typeof payload.division === 'string' &&
    typeof payload.country === 'string' &&
    typeof payload.admin === 'string';

  return isValidPayload;
};
