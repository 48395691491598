import { createContext } from 'react';

import { DivisionAsObject } from '@skloover/shared';

export type Session = {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  admin: boolean;
} & DivisionAsObject;

// Should never occur on authenticated routes
const defaultSession = {
  email: 'placeholder@email.com',
  id: 'userId',
  firstName: 'firstName',
  lastName: 'lastName',
  admin: false,
  country: 'france',
  program: '2024',
  division: '10',
} as const;

export const SessionContext = createContext<Session>(defaultSession);
