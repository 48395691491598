import { ReactElement, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AppRoute } from '~/lib';

import { SessionContext } from './sessionContext';

export const AdminRequired = (): ReactElement => {
  const session = useContext(SessionContext);
  const navigate = useNavigate();

  if (!session.admin) {
    navigate(AppRoute.NotFound);
  }

  return <Outlet />;
};
