import get from 'lodash/get';
import set from 'lodash/set';
import { Check } from 'lucide-react';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { Button, Input } from '~/components/ui';

import { ObjectFormula, Path } from './constants';
import { validateFormula } from './validateFormula';

interface TextProps {
  path: Path;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
}

export const Text = ({
  path,
  setPath,
  setObjectFormula,
}: TextProps): ReactElement => {
  const [input, setInput] = useState('');

  const addInputToFormula = () =>
    setObjectFormula(prevObjectFormula => {
      const cloneObjectFormula = JSON.parse(
        JSON.stringify(prevObjectFormula),
      ) as ObjectFormula;

      const symbols = get(
        prevObjectFormula,
        `${path.join('.')}.parentFormula.mainFormula.symbols`,
      ) as unknown as string[];

      set(
        cloneObjectFormula,
        `${path.join('.')}.parentFormula.mainFormula.symbols`,
        symbols.concat(input),
      );

      return cloneObjectFormula;
    });

  return (
    <div className="flex items-center gap-1">
      <Input
        type="text"
        value={input}
        className="w-auto"
        onChange={e => setInput(e.target.value)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <Button
        variant="ghost"
        className="text-green-700 hover:text-green-700"
        disabled={input === ''}
        size="icon"
        onClick={() => {
          addInputToFormula();
          validateFormula({ setPath, path, setObjectFormula });
        }}
      >
        <Check className="h-4 w-4" />
      </Button>
    </div>
  );
};
