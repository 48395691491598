export const welcomeMessages = {
  welcome: {
    title: 'Bienvenue',
    startedSession: 'Une session est en cours.',
    continueSession: {
      title: 'Une session est en cours',
      subtitle: 'Continuer ou terminer la session pour en commencer une autre',
    },
    startSession: {
      title: 'Démarrer une session de révision',
      subtitle: 'Essaye de résoudre les problèmes en une réponse !',
      start: 'Démarrer',
    },
  },
};
