import { zodResolver } from '@hookform/resolvers/zod';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { ReactElement, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';

import { ChapterAsObject, CHAPTERS_AS_OBJECTS } from '@skloover/shared';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui';
import { useIntl } from '~/lib';

export const SELECTED_CHAPTER = 'selected-chapter';

type Filters = { chapter: ChapterAsObject['chapter'] | undefined };

const formSchema = z.object({
  chapter: z.string(),
});

const chapters = uniq(map(CHAPTERS_AS_OBJECTS, 'chapter'));

export const ProblemFilters = ({
  filters,
  setFilters,
}: {
  filters?: {
    chapter: string | undefined;
  };
  setFilters: (filters: Filters) => void;
}): ReactElement => {
  const t = useIntl();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      chapter: filters?.chapter,
    },
  });

  const { watch, handleSubmit } = form;

  const onSubmit = useCallback(
    (newFilters: z.infer<typeof formSchema>) => {
      // @ts-expect-error Ok because admin section
      setFilters(newFilters);
      localStorage.setItem(SELECTED_CHAPTER, newFilters.chapter);
    },
    [setFilters],
  );

  useEffect(() => {
    // TypeScript users
    // const subscription = watch(() => handleSubmit(onSubmit)())
    const subscription = watch(() => {
      void handleSubmit(onSubmit)();
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, setFilters, onSubmit]);

  return (
    <Form {...form}>
      <form
        onChange={() => {
          form.handleSubmit(onSubmit);
        }}
      >
        <div className="flex">
          <FormField
            control={form.control}
            name="chapter"
            render={({ field }) => (
              <FormItem className="flex items-center space-y-0 space-x-2">
                <FormLabel htmlFor="chapter">
                  <FormattedMessage id="common.chapter" />
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      id="chapter"
                      className="whitespace-normal [&>span]:text-left [&>svg]:shrink-0"
                    >
                      <SelectValue
                        placeholder={t.formatMessage({
                          id: 'common.select',
                        })}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent
                    position="popper"
                    className="max-w-[var(--radix-select-trigger-width)]"
                  >
                    {chapters.map(chapter => (
                      <SelectItem
                        value={chapter}
                        key={chapter}
                        className="max-w-100"
                      >
                        <FormattedMessage
                          id={`common.chapterAsObject.chapter.${chapter}`}
                        />
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
};
