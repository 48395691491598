import { Lightbulb, TriangleAlert } from 'lucide-react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProblemStatement, Statement as StatementType } from '@skloover/shared';

import { Statement } from '~/components';
import {
  Separator,
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '~/components/ui';
import { Breakpoint } from '~/lib';

import { Hints } from './Hints';

type ProblemProps = {
  problemStatement: ProblemStatement;
  statement?: StatementType;
  currentQuestionStatement?: Extract<StatementType, { type: 'question' }>;
  currentQuestionPath: string;
  tab: string;
  onTabChange: (tab: string) => void;
  blurredIndex: number;
  incrementBlurredIndex: () => void;
  showAnswer: boolean;
  setShowAnswer: (bool: boolean) => void;
  enableShowAnswer: boolean;
  breakpoint: Breakpoint;
};

export const Problem = ({
  problemStatement,
  statement,
  currentQuestionStatement,
  currentQuestionPath,
  tab,
  onTabChange,
  blurredIndex,
  incrementBlurredIndex,
  showAnswer,
  setShowAnswer,
  enableShowAnswer,
  breakpoint,
}: ProblemProps): ReactElement => (
  <Tabs
    value={tab}
    onValueChange={onTabChange}
    className="h-full flex flex-col"
  >
    <div className="flex items-center px-8 md:px-4 py-2">
      <h1 className="text-lg font-semibold">
        <FormattedMessage id="session.problem" />
      </h1>
      <TabsList className="ml-auto">
        <TabsTrigger
          value="statement"
          className="text-zinc-600 dark:text-zinc-200"
          id={`joyride-problem-trigger-${breakpoint}`}
        >
          <FormattedMessage id="session.statement" />
        </TabsTrigger>
        <TabsTrigger
          value="hints"
          className="text-zinc-600 dark:text-zinc-200"
          id={`joyride-hints-trigger-${breakpoint}`}
        >
          <FormattedMessage id="session.hints" />
        </TabsTrigger>
      </TabsList>
    </div>
    <Separator />
    <TabsContent
      value="statement"
      className="m-0 grow min-h-0 overflow-hidden hover:overflow-y-auto"
    >
      <div className="container p-4 text-sm flex flex-col space-y-2">
        <div className="flex items-center gap-2 rounded bg-orange-50 border-orange-900 border-dotted border-2 text-orange-900 p-2">
          <Lightbulb className="shrink-0" />
          <FormattedMessage id="problems.method" />
        </div>
        <div className="flex items-center gap-2 rounded bg-orange-50 border-orange-900 border-dotted border-2 text-orange-900 p-2">
          <TriangleAlert className="shrink-0" />
          <FormattedMessage id="problems.warningNext" />
        </div>
        {statement === undefined ? (
          <>
            <Skeleton className="h-[30px] w-2/3 rounded-xl mb-4" />
            <Skeleton className="h-[20px] w-full rounded-xl" />
            <Skeleton className="h-[20px] w-full rounded-xl" />
            <Skeleton className="h-[100px] w-5/6 rounded-xl" />
          </>
        ) : (
          <Statement
            problemStatement={problemStatement}
            statement={problemStatement.statement}
            parentStep=""
            step=""
            currentQuestionPath={currentQuestionPath}
            breakpoint={breakpoint}
          />
        )}
      </div>
    </TabsContent>
    <TabsContent
      value="hints"
      className="m-0 grow min-h-0 overflow-hidden hover:overflow-y-auto"
    >
      {currentQuestionStatement !== undefined && (
        <Hints
          currentQuestionStatement={currentQuestionStatement}
          blurredIndex={blurredIndex}
          incrementBlurredIndex={incrementBlurredIndex}
          showAnswer={showAnswer}
          setShowAnswer={setShowAnswer}
          breakpoint={breakpoint}
          enableShowAnswer={enableShowAnswer}
        />
      )}
    </TabsContent>
  </Tabs>
);
