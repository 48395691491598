import get from 'lodash/get';
import { Check } from 'lucide-react';
import { cloneElement, Dispatch, ReactElement, SetStateAction } from 'react';

import { Button } from '~/components/ui';

import {
  ObjectFormula,
  ParenthesisFormula,
  Path,
  selectedElementClassName,
} from './constants';
import { Formula } from './Formula';
import { useRefHeight } from './useRefHeight';
import { validateFormula } from './validateFormula';

interface BracketsProps {
  objectFormula: ObjectFormula;
  path: Path;
  inFormulaPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  leftSvg: ReactElement;
  rightSvg: ReactElement;
  placeholder?: string;
}

export const InFormulaSvg = ({
  objectFormula,
  inFormulaPath,
  path,
  setPath,
  setObjectFormula,
  leftSvg,
  rightSvg,
  placeholder,
}: BracketsProps): ReactElement => {
  const { ref, height } = useRefHeight();

  const { inFormula } = get(
    objectFormula,
    `${inFormulaPath}.childFormula`,
  ) as unknown as ParenthesisFormula;
  const editing = inFormulaPath === path.slice(0, -1).join('.');
  const validateVisibility =
    inFormula.parentFormula.mainFormula.symbols.length === 0
      ? 'invisible'
      : 'visible';
  const selected = editing;
  const dynamicClassName = `${
    editing && 'hover:cursor-pointer hover:border-primary bg-gray-100'
  } ${selected ? 'border-primary' : 'border-transparent'}`;

  return (
    <div className="flex items-center gap-1">
      <div className="flex items-center gap-1">
        {cloneElement(leftSvg, { height })}
        <div
          className={`${selectedElementClassName} ${dynamicClassName}`}
          role="presentation"
          ref={ref}
        >
          <Formula
            objectFormula={objectFormula}
            path={path}
            setPath={setPath}
            formulaPath={`${inFormulaPath}.childFormula.inFormula`}
            mathJaxContentPlaceholder={placeholder ?? 'abc'}
            setObjectFormula={setObjectFormula}
          />
        </div>
        {cloneElement(rightSvg, { height })}
      </div>
      {editing && (
        <Button
          variant="ghost"
          className={`text-green-700 hover:text-green-700 ${validateVisibility}`}
          size="icon"
          onClick={() => validateFormula({ setPath, path, setObjectFormula })}
        >
          <Check className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
