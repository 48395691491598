/* eslint-disable max-lines */
export const integers = {
  whole_and_relative: [
    {
      type: 'definition',
      title: 'Entiers naturels et relatifs',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "L'ensemble des nombres entiers \\( \\textbf{naturels} \\ \\{0; 1; 2; \\ldots \\} \\) est noté \\( \\mathbb{N} \\).",
            },
            {
              type: 'text',
              content:
                "L'ensemble des nombres entiers \\( \\textbf{relatifs} \\ \\{ \\ldots; -3; -2; -1; 0; 1; 2; \\ldots \\} \\) est noté \\( \\mathbb{Z} \\).",
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaires',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'La somme, la différence et le produit de deux entiers relatifs sont des entiers relatifs.',
            },
            {
              type: 'text',
              content:
                'Pour la division dans \\( \\mathbb{Z} \\), on utilise la \\( \\textbf{division euclidienne} \\).\nPour tous entiers relatifs \\( a \\) et \\( b \\), avec \\( b \\) non nul, on peut écrire \\( a \\) de façon unique sous la forme : \\( a = b \\times q + r \\), où \\( q \\) est un entier relatif et \\( r \\) un entier naturel tel que \\( 0 \\leq r < |b| \\). \\( q \\) est appelé \\( \\textbf{quotient} \\) et \\( r \\) \\( \\textbf{reste} \\) de la division.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            "On peut écrire \\( 27 = 4 \\times 5 + 7 \\), mais \\( 7 \\) n'est pas le reste dans la division euclidienne de \\( 27 \\) par \\( 4 \\), car \\( 7 > 4 \\).",
        },
        {
          type: 'text',
          content:
            "L'écriture de la division euclidienne de \\( 27 \\) par \\( 4 \\) est :\\[ 27 = 4 \\times 6 + 3 \\]",
        },
        {
          type: 'text',
          content:
            '\\( 6 \\) est le quotient et \\( 3 \\) est le reste (on a bien \\( 0 \\leq 3 < 4 \\)).',
        },
      ],
    },
  ],
  multiple_and_divider: [
    {
      type: 'definition',
      title: 'Entiers naturels et relatifs',
      subtitle: 'Multiples et diviseurs des entiers relatifs',
      content: [
        {
          type: 'text',
          content: 'Soient deux entiers relatifs \\(n\\) et \\(p\\).',
        },
        {
          type: 'text',
          content:
            "Si le reste dans la division euclidienne de \\(n\\) par \\(p\\) est égal à 0, c'est-à-dire s'il existe un entier relatif \\(k\\) tel que  \\(n = p \\times k\\), on dit que :",
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\(p\\) est un \\( \\textbf{diviseur} \\) de \\(n\\) ou que \\(n\\) est \\( \\textbf{divisible} \\) par \\(p\\).',
            },
            {
              type: 'text',
              content: '\\(n\\) est un \\( \\textbf{multiple} \\) de \\(p\\).',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaire',
      content: [
        {
          type: 'text',
          content:
            'Tout nombre entier relatif non nul \\(n\\) est toujours divisible au moins par 1 et par lui-même (car \\(n = 1 \\times n\\)) et admet une infinité de multiples : \\(n, 2n, 3n, -n, -2n,\\) etc. Les multiples de \\(n\\) sont de la forme \\(k \\times n\\), où \\(k \\in \\mathbb{Z}.\\)',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( 12 = 4 \\times 3 \\). Donc :\n4 est un diviseur de 12,\n12 est un multiple de 4.',
            },
            {
              type: 'text',
              content:
                "0 est divisible par n'importe quel nombre entier relatif, car pour tout entier relatif \\(n\\), on a \\(0 = 0 \\times n\\).",
            },
            {
              type: 'text',
              content: "0 n'admet qu'un seul multiple : 0.",
            },
          ],
        },
      ],
    },
  ],
  multiple_sum_and_product: [
    {
      type: 'property',
      title: 'Entiers naturels et relatifs',
      subtitle: 'Multiples et diviseurs des entiers relatifs',
      content: [
        {
          type: 'text',
          content:
            'On considère trois entiers relatifs \\( a \\), \\( n \\) et \\( m \\).',
        },
        {
          type: 'text',
          content:
            'Si les entiers \\( n \\) et \\( m \\) sont deux multiples de \\( a \\), alors la somme \\( (n + m) \\), la différence \\( (n - m) \\) et le produit \\( n \\times m \\) sont aussi des multiples de \\( a \\).',
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration pour la somme',
      content: [
        {
          type: 'text',
          content:
            'Comme \\( n \\) est un multiple de \\( a \\), on peut écrire : \\( n = k_1 \\times a \\), où \\( k_1 \\) est un entier relatif. De même, on peut écrire \\( m = k_2 \\times a \\), où \\( k_2 \\) est un entier relatif; car \\( m \\) est un multiple de \\( a \\).',
        },
        {
          type: 'text',
          content:
            'On en déduit que \\( n + m = k_1 \\times a + k_2 \\times a = (k_1 + k_2) \\times a \\).',
        },
        {
          type: 'text',
          content:
            "Or \\( (k_1 + k_2) \\) est la somme de deux entiers relatifs, c'est donc un entier relatif.",
        },
        {
          type: 'text',
          content:
            'Ainsi, par définition, \\( a \\) est un diviseur de \\( (n + m) \\).',
        },
        {
          type: 'text',
          content:
            'Autrement dit, la somme \\( (n + m) \\) est un multiple de \\( a \\).',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            '$36$ et $90$ sont des multiples de $9$.\nDonc $126 = 36 + 90$ est un multiple de $9$.\nEn effet, $126 = 9 \\times 14$.',
        },
      ],
    },
  ],
  prime: [
    {
      type: 'definition',
      title: 'Entiers naturels',
      subtitle: 'Nombres premiers',
      content: [
        {
          type: 'text',
          content:
            "Un nombre entier naturel est \\( \\textbf{premier} \\) s'il n'admet que deux diviseurs positifs distincts : 1 et lui-même.",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaires',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '1 n’est pas un nombre premier.',
            },
            {
              type: 'text',
              content:
                'Les nombres premiers peuvent être considérés comme les « briques élémentaires » des ensembles \\( \\mathbb{N} \\) et \\( \\mathbb{Z} \\). En effet, chaque entier naturel s’écrit de façon unique, à l’ordre près, sous la forme d’un produit dont chaque facteur est un nombre premier : c’est le \\( \\textbf{théorème fondamental de l’arithmétique} \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '2, 3, 5, 7 et 11 sont des nombres premiers.',
            },
            {
              type: 'text',
              content:
                '15 n’est pas un nombre premier, car : \\( 15 = 3 \\times 5 \\)\n3 et 5 sont des diviseurs de 15, qui admet donc des diviseurs positifs autres que 1 et lui-même.',
            },
          ],
        },
      ],
    },
  ],
  odd_even_definition: [
    {
      type: 'property',
      title: 'Nombres pairs, nombres impairs',
      header: 'Définitions et propriétés',
      content: [
        {
          type: 'text',
          content: 'On considère un entier relatif \\( n \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si \\( n \\) est divisible par 2 (ou si \\( n \\) est un multiple de 2), on dit que \\( n \\) est \\( \\textbf{pair} \\).\nIl existe alors un entier relatif \\( k \\) tel que \\( n = 2 \\times k \\).',
            },
            {
              type: 'text',
              content:
                'Sinon, on dit que \\( n \\) est \\( \\textbf{impair} \\).\nIl existe alors un entier relatif \\( k \\) tel que \\( n = 2 \\times k + 1 \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content: 'On considère un entier relatif \\( n \\).',
        },
        {
          type: 'text',
          content:
            'On effectue la division euclidienne de \\( n \\) par 2 : on obtient \\( n = 2 \\times k + r \\) avec \\( k \\) un entier relatif et \\( r \\) un entier naturel tel que \\( 0 \\leq r < 2 \\).',
        },
        {
          type: 'text',
          content: 'étant entier, \\( r \\) a soit r = 0, soit r = 1.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si \\( n \\) est pair : par définition, 2 divise \\( n \\) et \\( r = 0 \\). Donc \\( n = 2k \\).',
            },
            {
              type: 'text',
              content:
                'Sinon, \\( n \\) est impair et 2 ne divise pas \\( n \\). Donc \\( r \\neq 0 \\). Ainsi, \\( r = 1 \\) et \\(n = 2k + 1 \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: '38 est un nombre pair : \\( 38 = 2 \\times 19 \\)',
        },
        {
          type: 'text',
          content: '17 est un nombre impair : \\( 17 = 2 \\times 8 + 1\\)',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Rappel du critère de parité',
      content: [
        {
          type: 'text',
          content:
            "Un entier relatif est pair si, et seulement si, son chiffre des unités est pair, c'est-à-dire égal à 0, 2, 4, 6 ou 8.",
        },
      ],
    },
  ],
  parity_sum: [
    {
      type: 'property',
      subtitle: 'Parité et somme d’entiers',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: 'La somme de deux nombres pairs est un nombre pair.',
            },
            {
              type: 'text',
              content: 'La somme de deux nombres impairs est un nombre pair.',
            },
            {
              type: 'text',
              content:
                'La somme d’un nombre pair et d’un nombre impair est un nombre impair.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration pour la somme de deux entiers pairs',
      content: [
        {
          type: 'text',
          content:
            'La somme de deux entiers multiples de 2 est un multiple de 2.\nDonc la somme de deux entiers pairs est un entier pair.',
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration pour la somme de deux entiers impairs',
      content: [
        {
          type: 'text',
          content:
            'Soient \\( n \\) et \\( p \\) deux entiers impairs.\nOn peut alors poser \\( n = 2k + 1 \\) et \\( p = 2q + 1 \\) avec \\( k \\) et \\( q \\) entiers relatifs.\nAlors \\( n + p = (2k + 1) + (2q + 1) = 2 × (k + q + 1) \\), avec \\( (k + q + 1) \\in ℤ \\).\nLa somme \\( n + p \\) est divisible par 2, et est donc un entier pair.',
        },
      ],
    },
  ],
  parity_square: [
    {
      type: 'property',
      subtitle: 'Parité d’un carré',
      content: [
        {
          type: 'text',
          content: 'On considère un entier relatif \\( n \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si \\( n \\) est pair, alors le carré \\( n^2 \\) est pair.',
            },
            {
              type: 'text',
              content:
                'Si \\( n \\) est impair, alors le carré \\( n^2 \\) est impair.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content: 'On considère un entier relatif \\( n \\).',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "Si \\( n \\) est pair, on peut écrire \\( n=2k \\) avec \\( k \\) un entier relatif.\nAlors \\( n^2 = (2k) \\times (2k) = 2 \\times (2k)^2 \\).\nDonc 2 divise \\( n^2 \\), d'où \\( n^2 \\) est pair.",
            },
            {
              type: 'text',
              content:
                'Si \\( n \\) est impair : on peut écrire \\( n=2k+1 \\) avec \\( k \\) un entier relatif.\nAlors \\( n^2 = (2k+1)^2 = (2k+1) = (2k)^2 + 2 \\times 2k \\times 1 + 1^2 = 4k^2 + 4k + 1 \\).\nAinsi \\(n^2 = 2(2k^2 + 2k) +1\\).\nOn en deduit que \\( n^2 \\) est impair.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Conséquences',
      content: [
        {
          type: 'text',
          content: 'La réciproque de ce théorème est vraie : ',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'si le carré \\( n^2 \\) d’un entier \\( n \\) est pair, alors \\( n \\) est pair.',
            },
            {
              type: 'text',
              content:
                'si le carré \\( n^2 \\) d’un entier est impair, alors \\( n \\) est impair.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Exemples',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '12 est pair et \\( 12^2 = 144 \\) est pair.',
            },
            {
              type: 'text',
              content: '13 est impair et \\( 13^2 = 169 \\) est impair.',
            },
          ],
        },
      ],
    },
  ],
} as const;
