import { ReactElement } from 'react';

import { NOTIONS_AS_OBJECTS } from '@skloover/shared';

import { Layout } from '~/components';
import { Lesson } from '~/components/Session/Lesson';
import { Separator } from '~/components/ui';

export const Lessons = (): ReactElement => (
  <Layout>
    <div className="h-full overflow-auto">
      {NOTIONS_AS_OBJECTS.filter(
        ({ chapter, notion }) =>
          chapter === 'plan_line_and_system' && notion === 'y_no_parallelism',
      ).map((notionAsObject, index) => (
        <div key={index}>
          {index > 0 ? <Separator className="mb-4" /> : <></>}
          <Lesson notionAsObject={notionAsObject} />
        </div>
      ))}
    </div>
  </Layout>
);
