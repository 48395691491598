/* eslint-disable max-lines */

/* eslint-disable complexity */
import get from 'lodash/get';
import set from 'lodash/set';
import { Dispatch, SetStateAction } from 'react';

import {
  BRACKETS,
  COORDINATES,
  COSINUS,
  DETERMINANT,
  EXPONENTIAL,
  EXPOSANT,
  Formula,
  FRACTION,
  INDEX,
  LOGARITHM,
  ObjectFormula,
  OVERLINE,
  PARENTHESIS,
  Path,
  SINUS,
  SQUARE_ROOT,
  TANGENT,
  TEXT,
  VECTOR,
} from './constants';

type ValidateFormulaArguments = {
  path: Path;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
};

export const validateFormula = ({
  setObjectFormula,
  path,
  setPath,
}: ValidateFormulaArguments): void => {
  setObjectFormula(prevObjectFormula => {
    const cloneObjectFormula = JSON.parse(
      JSON.stringify(prevObjectFormula),
    ) as ObjectFormula;

    const parentPath = path.slice(0, -1).join('.');

    const parentFormula = get(
      cloneObjectFormula,
      parentPath,
    ) as unknown as Formula;

    const newParentSymbols = getSymbols({
      currentFormula: parentFormula,
      isNested: path.length > 2,
    });

    set(
      cloneObjectFormula,
      `${parentPath}.parentFormula.mainFormula.symbols`,
      newParentSymbols,
    );

    set(cloneObjectFormula, `${parentPath}.childFormula`, undefined);

    return cloneObjectFormula;
  });
  setPath(prevPath => prevPath.slice(0, -1));
};

const getSymbols = ({
  currentFormula,
  isNested,
}: {
  currentFormula: Formula;
  isNested: boolean;
}) => {
  switch (currentFormula.childFormula?.type) {
    case FRACTION: {
      const upFormula = currentFormula.childFormula.upFormula;
      const downFormula = currentFormula.childFormula.downFormula;

      const fracSymbol = isNested ? '\\frac' : '\\dfrac';

      const newParentSymbols =
        upFormula.parentFormula.mainFormula.symbols.length === 0 ||
        downFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `${fracSymbol}{${upFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}}{${downFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}}`,
            });

      return newParentSymbols;
    }
    case EXPOSANT: {
      const leftFormula = currentFormula.childFormula.leftFormula;
      const rightFormula = currentFormula.childFormula.rightFormula;

      const newParentSymbols =
        leftFormula.parentFormula.mainFormula.symbols.length === 0 ||
        rightFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\left(${leftFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}\\right)^{${rightFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}}`,
            });

      return newParentSymbols;
    }
    case INDEX: {
      const leftFormula = currentFormula.childFormula.leftFormula;
      const rightFormula = currentFormula.childFormula.rightFormula;

      const newParentSymbols =
        leftFormula.parentFormula.mainFormula.symbols.length === 0 ||
        rightFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `${leftFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}_{${rightFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}}`,
            });

      return newParentSymbols;
    }
    case SINUS: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\sin( ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} )`,
            });

      return newParentSymbols;
    }
    case COSINUS: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\cos( ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} )`,
            });

      return newParentSymbols;
    }
    case TANGENT: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\tan( ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} )`,
            });

      return newParentSymbols;
    }
    case LOGARITHM: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\ln( ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} )`,
            });

      return newParentSymbols;
    }
    case EXPONENTIAL: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\exp( ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} )`,
            });

      return newParentSymbols;
    }
    case SQUARE_ROOT: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\sqrt{ ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} }`,
            });

      return newParentSymbols;
    }
    case PARENTHESIS: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\left(${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}\\right)`,
            });

      return newParentSymbols;
    }
    case BRACKETS: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\left\\{ ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} \\right\\}`,
            });

      return newParentSymbols;
    }
    case OVERLINE: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\overline{${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')}}`,
            });

      return newParentSymbols;
    }
    case TEXT: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: inFormula.parentFormula.mainFormula.symbols.join(''),
              isText: true,
            });

      return newParentSymbols;
    }
    case COORDINATES: {
      const upFormula = currentFormula.childFormula.upFormula;
      const downFormula = currentFormula.childFormula.downFormula;

      const newParentSymbols =
        upFormula.parentFormula.mainFormula.symbols.length === 0 ||
        downFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\begin{pmatrix} ${upFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} \\\\ ${downFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} \\end{pmatrix}`,
            });

      return newParentSymbols;
    }
    case VECTOR: {
      const inFormula = currentFormula.childFormula.inFormula;
      const inFormulaStringContent = inFormula.parentFormula.mainFormula.symbols
        .map(({ symbol }) => symbol)
        .join('');

      const vectorSymbol =
        inFormulaStringContent.length === 1 ? '\\vec' : '\\overrightarrow';

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `${vectorSymbol}{${inFormulaStringContent}}`,
            });

      return newParentSymbols;
    }
    case DETERMINANT: {
      const inFormula = currentFormula.childFormula.inFormula;

      const newParentSymbols =
        inFormula.parentFormula.mainFormula.symbols.length === 0
          ? currentFormula.parentFormula.mainFormula.symbols
          : currentFormula.parentFormula.mainFormula.symbols.concat({
              symbol: `\\det( ${inFormula.parentFormula.mainFormula.symbols.map(({ symbol }) => symbol).join('')} )`,
            });

      return newParentSymbols;
    }
    case undefined:
      throw new Error();
  }
};
