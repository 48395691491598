import get from 'lodash/get';
import { Check } from 'lucide-react';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { LeftParenthesis, RightParenthesis } from '~/components/icons';
import { Button } from '~/components/ui';

import {
  CoordinatesFormula,
  ObjectFormula,
  Path,
  selectedElementClassName,
} from './constants';
import { Formula } from './Formula';
import { useRefHeight } from './useRefHeight';
import { validateFormula } from './validateFormula';

type CoordinatesElementSide = 'upFormula' | 'downFormula';

type CoordinatesElementProps = {
  objectFormula: ObjectFormula;
  elementSide: CoordinatesElementSide;
  path: Path;
  coordinatesPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
};

export const CoordinatesElement = ({
  objectFormula,
  coordinatesPath,
  elementSide,
  path,
  setPath,
  setObjectFormula,
}: CoordinatesElementProps): ReactElement => {
  const editing = coordinatesPath === path.slice(0, -1).join('.');
  const selected =
    editing && path[path.length - 1] === `childFormula.${elementSide}`;
  const dynamicClassName = `${
    editing && 'hover:cursor-pointer hover:border-primary bg-gray-100'
  } ${selected ? 'border-primary' : 'border-transparent'}`;

  const onClick = () =>
    editing && !selected
      ? setPath(prevPath =>
          prevPath.slice(0, -1).concat(`childFormula.${elementSide}`),
        )
      : undefined;

  const getEmptyLabel = () => {
    switch (elementSide) {
      case 'upFormula':
        return 'x';
      case 'downFormula':
        return 'y';
    }
  };

  return (
    <div
      className={`${selectedElementClassName} ${dynamicClassName}`}
      role="presentation"
      onClick={onClick}
    >
      <Formula
        objectFormula={objectFormula}
        path={path}
        setPath={setPath}
        formulaPath={`${coordinatesPath}.childFormula.${elementSide}`}
        mathJaxContentPlaceholder={getEmptyLabel()}
        setObjectFormula={setObjectFormula}
      />
    </div>
  );
};

interface CoordinatesProps {
  objectFormula: ObjectFormula;
  path: Path;
  coordinatesPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
}

export const Coordinates = ({
  objectFormula,
  coordinatesPath,
  path,
  setPath,
  setObjectFormula,
}: CoordinatesProps): ReactElement => {
  const { ref, height } = useRefHeight();
  const { upFormula, downFormula } = get(
    objectFormula,
    `${coordinatesPath}.childFormula`,
  ) as unknown as CoordinatesFormula;
  const editing = coordinatesPath === path.slice(0, -1).join('.');
  const validateVisibility =
    upFormula.parentFormula.mainFormula.symbols.length === 0 ||
    downFormula.parentFormula.mainFormula.symbols.length === 0
      ? 'invisible'
      : 'visible';

  return (
    <div className="flex items-center gap-1">
      <div className="flex items-center gap-1">
        <LeftParenthesis height={height} />
        <div className="flex flex-col gap-1" ref={ref}>
          <div className="flex items-center justify-around">
            <div className="flex gap-1">
              <CoordinatesElement
                objectFormula={objectFormula}
                coordinatesPath={coordinatesPath}
                elementSide="upFormula"
                path={path}
                setPath={setPath}
                setObjectFormula={setObjectFormula}
              />
            </div>
          </div>
          <div className="flex items-center justify-around">
            <div className="flex gap-1">
              <CoordinatesElement
                objectFormula={objectFormula}
                coordinatesPath={coordinatesPath}
                elementSide="downFormula"
                path={path}
                setPath={setPath}
                setObjectFormula={setObjectFormula}
              />
            </div>
          </div>
        </div>
        <RightParenthesis height={height} />
      </div>
      {editing && (
        <Button
          variant="ghost"
          className={`text-green-700 hover:text-green-700 ${validateVisibility}`}
          size="icon"
          onClick={() => validateFormula({ setPath, path, setObjectFormula })}
        >
          <Check className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
