/* eslint-disable max-lines */

export const probability = {
  universe_output_event_definition: [
    {
      type: 'definition',
      title: 'Vocabulaire des événements',
      subtitle: 'Univers, issues et événements',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Une \\( \\textbf{expérience aléatoire} \\) est une expérience dont on connaît tous les résultats possibles sans savoir à l’avance celui que l’on obtiendra.',
            },
            {
              type: 'text',
              content:
                'Un résultat possible d’une expérience aléatoire s’appelle une \\( \\textbf{issue} \\).',
            },
            {
              type: 'text',
              content:
                'L’ensemble de toutes les issues possibles d’une expérience aléatoire s’appelle l’\\( \\textbf{univers} \\). On le note souvent \\( \\Omega \\) (oméga).',
            },
            {
              type: 'text',
              content:
                'Un \\( \\textbf{événement} \\) est une partie (ou sous-ensemble) de l’univers.',
            },
            {
              type: 'text',
              content:
                'Un \\( \\textbf{événement élémentaire} \\) est un événement formé d’une seule issue.',
            },
            {
              type: 'text',
              content:
                'Lorsque le résultat de l’expérience aléatoire appartient à un événement, on dit que cet \\( \\textbf{événement} \\) est \\( \\textbf{réalisé} \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Un \\( \\textbf{événement impossible} \\) est un événement qui ne peut pas se réaliser. Il ne contient aucune issue. On le note \\( \\emptyset \\).',
            },
            {
              type: 'text',
              content:
                'Un \\( \\textbf{événement certain} \\) est un événement qui est toujours réalisé. Il contient tous les éléments de l’univers.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Le lancer d’un dé à six faces est une expérience aléatoire.',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'L’univers est l’ensemble :\\[ \\Omega = \\left\\{ 1; 2; 3; 4; 5; 6 \\right\\} \\]',
            },
            {
              type: 'text',
              content:
                '\\( A \\) « obtenir un résultat pair » est un événement, et on écrit :\\[ A = \\left\\{ 2; 4; 6 \\right\\} \\]',
            },
          ],
        },
        {
          type: 'text',
          content:
            'Si on effectue le lancer et que le résultat est 4, l’événement \\( A \\) est réalisé ; si le résultat est 1, A n’est pas réalisé.',
        },
      ],
    },
  ],
  complementary_probability_definition: [
    {
      type: 'definition',
      title: 'Vocabulaire des événements',
      subtitle: 'Univers, issues et événements',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: 'Soient \\( A \\) et \\( B \\) deux événements.',
            },
            {
              type: 'list',
              content: [
                {
                  type: 'text',
                  content:
                    '\\( A \\) et \\( B \\) sont \\( \\textbf{complémentaires} \\) lorsque \\( A \\) est formé de tous les éléments de l’univers qui ne sont pas dans \\( B \\).\nOn dit aussi que \\( B \\) est l’événement \\( \\textbf{contraire} \\) de \\( A \\), on le note \\( \\bar{A} \\).',
                },
                {
                  type: 'text',
                  content:
                    '\\( A \\) et \\( B \\) sont \\( \\textbf{incompatibles} \\) (ou \\( \\textbf{disjoints} \\)) lorsqu’ils n’ont aucune issue en commun : ils ne peuvent pas se réaliser simultanément.',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Illlustration',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture}\\begin{scope}\\draw[fill=blue!10, thick] (0,0) circle (2.5);\\node[blue] at (2.2,2.0) {\\Large $\\Omega$};\\draw[fill=red!30, draw=red, thick] (0,0) ellipse (1.5 and 1);\\node[red] at (0,0) {\\large $A$};\\node at (-1.8,-0.8) {\\large $\\overline{A}$};\\node[below] at (0,-3) {\\large $A$ et son contraire $\\overline{A}$};\\end{scope}\\begin{scope}[xshift=7cm]\\draw[fill=blue!10, thick] (0,0) circle (2.5);\\node[blue] at (2.2,2.0) {\\Large $\\Omega$};\\draw[fill=red!30, draw=red, thick] (-0.8,0.8) circle (1);\\node[red] at (-0.8,0.8) {\\large $A$};\\draw[fill=green!10, draw=green!70!black, thick] (0.8,-0.8) circle (1);\\node[green!70!black] at (0.8,-0.8) {\\large $B$};\\node[below] at (0,-3) {\\large $A$ et $B$ sont incompatibles};\\end{scope}\\end{tikzpicture}\\end{document}',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Dans le lancer d’un dé à six faces :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'les événements \\( C = \\left\\{ 1; 2 \\right\\} \\) et \\( D = \\left\\{ 3; 4; 5; 6 \\right\\} \\) sont complémentaires. On dit aussi que \\( D \\) est le contraire de \\( C \\). On écrite :\\[ D = \\bar{C} \\]',
            },
            {
              type: 'text',
              content:
                'les événements \\( E = \\left\\{ 3; 5 \\right\\} \\) et \\( F  = \\left\\{ 1; 6; \\right\\} \\)  sont incompatibles : ils n’ont aucune issue commune. Ils ne peuvent pas être réalisés simultanément lors d’un même lancer.',
            },
          ],
        },
      ],
    },
  ],
  joint_probability_definition: [
    {
      type: 'definition',
      title: 'Vocabulaire des événements',
      subtitle: 'Intersection et réunion de deux événements',
      content: [
        { type: 'text', content: 'Soient A et B deux événements.' },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                '\\( \\textbf{L’intersection} \\) de \\( A \\) et de \\( B \\), notée \\( A∩B \\), est l’événement formé des issues qui appartiennent à la fois à \\( A \\) et à \\( B \\).',
            },
            {
              type: 'text',
              content:
                '\\( \\textbf{La réunion} \\) de \\( A \\) et \\( B \\), notée \\( A∪B \\), est l’événement formé des issues qui appartiennent à la fois à \\( A \\) ou à \\( B \\).',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document}\\begin{tikzpicture}\\begin{scope}[shift={(0,0)}]\\clip(-1.5,0) circle(1.6cm);\\fill[green!30] (1.5,0) circle(1.6cm);\\end{scope}\\draw (-1.5,0) circle (1.6cm) node[left] {A};\\draw (1.5,0) circle (1.6cm) node[right] {B};\\draw[->, ] (0,-0.1)--(0,-1.6);\\node[below] at (0,-1.6) {\\textcolor{green!50!black}{$A \\cap B$}};\\begin{scope}[xshift=7cm]\\fill[red!30] (1.5,0) circle (1.6cm);\\fill[red!30] (-1.5,0) circle (1.6cm);\\draw (1.5,0) circle (1.6cm) node[right] {B};\\draw (-1.5,0) circle (1.6cm) node[left] {A};\\draw[->,] (0,-1.6)--(0,-2.1);\\node[below] at (0,-2.1) {\\textcolor{red}{$A \\cup B$}};\\end{scope}\\end{tikzpicture}\\end{document}',
        },
        {
          type: 'text',
          content:
            'Si A et B sont incompatibles alors \\( A∩B = \\emptyset \\)',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Dans le lancer d’un dé à six faces, soient \\( G = \\left\\{ 1; 2; 3 \\right\\} \\) et \\( H = \\left\\{ 3; 4 \\right\\} \\) :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '\\( F∩G = \\left\\{ 3 \\right\\} \\)',
            },
            {
              type: 'text',
              content: '\\( F∪G = \\left\\{ 1; 2; 3; 4 \\right\\} \\)',
            },
          ],
        },
      ],
    },
  ],
  probability_law: [
    {
      type: 'definition',
      title: 'Probabilités sur un ensemble fini',
      subtitle: 'Loi de probabilité sur un ensemble fini',
      content: [
        {
          type: 'text',
          content:
            'On considère une expérience aléatoire dont l’univers \\( \\Omega \\) est fini et est formé de \\( n \\) issues : \\( \\Omega = \\left\\{ e_{1}; e_{2}; e_{3}; ...; e_{n} \\right\\} \\).',
        },
        {
          type: 'text',
          content:
            'Définir une \\( \\textbf{loi de probabilité} \\) sur \\( \\Omega \\) c’est associer à chaque événement élémentaire \\( \\left\\{e_{i} \\right\\} \\) sa probabilité \\( p_{i} \\).',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{array}{|c|c|c|c|c|} \\hline \\text{Issue} e_i & e_1 & e_2 & \\cdots & e_n \\\\ \\hline \\text{Probabilité} p_i & p_1 & p_2 & \\cdots & p_n \\\\ \\hline \\end{array} \\]',
        },
        {
          type: 'text',
          content:
            'On a \\( 0 \\le p_{i} \\le 1 \\) et \\( p_{1} + p_{2} + \\ldots + p_{n} = 1 \\).',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'La probabilité d’un événement \\( A \\), notée \\( P(A) \\), est égale à la somme des probabilités des événements élémentaires qui constituent \\( A \\).',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Un sac contient dix jetons indiscernables au toucher. Trois jetons portent le n° 1, deux portent le n° 2 et les autres portent le n° 3. On tire un jeton au hasard et on note son numéro. La loi de probabilité est :',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{array}{|c|c|c|c|} \\hline \\text{Issue} & 1 & 2 & 3 \\\\ \\hline \\text{Probabilité} & \\frac{3}{10} & \\frac{2}{10} & \\frac{5}{10} \\\\ \\hline \\end{array} \\]',
        },
        {
          type: 'text',
          content:
            'Soit \\( A \\) l’événement : « l’issue est impaire ». La probabilité de \\( A \\) est : \\[ \\begin{aligned} &P(A) = P(\\left\\{ 1 \\right\\}) + P(\\left\\{ 3 \\right\\}) \\\\ &P(A) = \\frac{3}{10} + \\frac{5}{10} \\\\ &P(A) = \\frac{8}{10} \\\\ &P(A) = 0,8 \\end{aligned} \\]',
        },
      ],
    },
  ],
  equiprobability: [
    {
      type: 'simpleText',
      title: 'Probabilités sur un ensemble fini',
      subtitle: "Situation d'équiprobabilité",
      content: [
        {
          type: 'text',
          content:
            'Lorsque tous les événements élémentaires formant l’univers \\( \\Omega \\) ont la \\( \\textbf{même probabilité} \\), on dit qu’on est en \\( \\textbf{situation d’équiprobabilité} \\) sur \\( \\Omega \\).',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'En situation d’équiprobabilité sur un univers \\( \\Omega \\), la probabilité d’un événement \\( A \\) est donnée par \\[ P(A) = \\frac{nombre\\ d’issues\\ réalisant\\ A}{nombre\\ total\\ d’issues\\ de\\ \\Omega} \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Si A contient une seule issue, c’est un événement élémentaire ; en notant sa probabilité \\( p \\), comme il y a \\( n \\) événements élémentaires de même probabilité \\( p \\) et que la somme des probabilités de tous les événements élémentaires vaut 1, on a : \\( n \\times p = 1 \\) et donc \\( p = \\frac{1}{n} \\).',
            },
            {
              type: 'text',
              content:
                'Si \\( A \\) contient \\( k \\) issues \\( ( k \\ge 2 ) \\), on a \\( P(A) = \\underbrace{\\frac{1}{n} + \\frac{1}{n} + \\ldots + \\frac{1}{n}}_{k\\ fois} = \\frac{k}{n} \\)',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Un sac contient dix jetons indiscernables au toucher. Trois jetons portent le n° 1, deux portent le n° 2 et les autres portent le n° 3. On tire un jeton au hasard et on note son numéro.',
        },
        {
          type: 'text',
          content:
            'Puisque les jetons sont « indiscernables au toucher », tous ont la même probabilité d’être tirés : \\( \\frac{1}{10} \\).\nOn est en situation d’équiprobabilité.\nLes expressions comme « indiscernables au toucher », « dé équilibré », « pièce équilibrée » sont des indications de situations d’équiprobabilité.',
        },
      ],
    },
  ],
  incompatibility_calculation: [
    {
      type: 'property',
      title: 'Probabilités sur un ensemble fini',
      subtitle: 'Calcul de probabilités',
      content: [
        {
          type: 'text',
          content:
            'Si A et B sont deux événements \\( \\textbf{incompatibles} \\), on a alors :\\[ P(A∪B) = P(A) + P(B) \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            '\\( A \\) et \\( B \\) sont incompatibles, ils n’ont donc aucune issue commune et \\( A∪B \\) est formé de toutes les issues de \\( A \\) réunies à toutes les issues de \\( B \\).\nDonc \\( P(A∪B) \\) est égal à la somme des probabilités des événements élé-mentaires contenus dans \\( A \\), c’est-à-dire , avec les probabilités des événements élémentaires contenus dans B, c’est-à-dire \\( P(B) \\). Ainsi, \\( P(A∪B) = P(A) + P(B) \\).',
        },
      ],
    },
  ],
  joint_probability_calculation: [
    {
      type: 'property',
      title: 'Probabilités sur un ensemble fini',
      subtitle: 'Calcul de probabilités',
      content: [
        {
          type: 'text',
          content:
            'Pour tous événements A et B, on a :\\[ P(A∪B) + P(A∩B) = P(A) + P(B) \\]',
        },
        {
          type: 'text',
          content: 'On a donc :\\[ P(A∪B) = P(A) + P(B) - P(A∩B) \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'On lance un dé équilibré numéroté de 1 à 6. Soient les événements \\( A \\) « obtenir un résultat pair » et \\( B \\) « obtenir un résultat inférieur ou égal à 4 ».',
        },
        {
          type: 'text',
          content:
            '\\( A \\) et \\( B \\) ne sont pas incompatibles et \\( A∩B = \\left\\{ 2 \\right\\} \\), donc :\\[ \\begin{aligned} &P(A∪B) = P(A) + P(B) - P(A∩B) \\\\&P(A∪B) = \\frac{1}{2} + \\frac{4}{6} - \\frac{1}{2} \\\\ &P(A∪B) = \\frac{4}{6} \\\\ &P(A∪B) = \\frac{2}{3} \\end{aligned} \\]',
        },
      ],
    },
  ],
  complementary_probability_calculation: [
    {
      type: 'property',
      title: 'Probabilités sur un ensemble fini',
      subtitle: 'Calcul de probabilités',
      content: [
        {
          type: 'text',
          content:
            'Soit \\( A \\) un événement quelconque et \\( \\bar{A} \\) son \\( \\textbf{événement complémentaire} \\) (ou événement \\( \\textbf{contraire} \\)).',
        },
        {
          type: 'text',
          content:
            'On a :\\[ P(A) + P(\\bar{A}) = 1 \\]ou encore :\\[ P(\\bar{A}) = 1 - P(A) \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'On lance un dé équilibré numéroté de 1 à 6. Soient les événements \\( A \\) « obtenir un résultat pair » et \\( B \\) « obtenir un résultat inférieur ou égal à 4 ».',
        },
        {
          type: 'text',
          content:
            'La probabilité de ne pas obtenir un résultat inférieur ou égal à 4 est :\\[ \\begin{aligned} &P(\\bar{B}) = 1 - P(B) \\\\ &P(\\bar{B}) = 1 - \\frac{4}{6} \\\\ &P(\\bar{B}) = \\frac{2}{6} \\\\ &P(\\bar{B}) = \\frac{1}{3} \\end{aligned} \\]',
        },
      ],
    },
  ],
} as const;
