import { Lesson, PartialDeepKeyOf } from '~/types';

import { NOTIONS } from '../chapters';
import { mathematics as mathematics_10 } from './10';

export const lessons: PartialDeepKeyOf<typeof NOTIONS, Lesson> = {
  france: {
    '2024': {
      '10': { mathematics: mathematics_10 },
    },
  },
};
