/* eslint-disable max-lines */
export const coordinate_system = {
  middle: [
    {
      type: 'simpleText',
      title: 'Calculs dans un repère orhtonormé',
      content: [
        {
          type: 'text',
          content: "Le plan est muni d'un repère orthornormé $(O; I; J)$.",
        },
      ],
    },
    {
      type: 'property',
      subtitle: 'Coordonnées du milieu d’un segment',
      content: [
        {
          type: 'text',
          content:
            'On considère deux points $A(x_A; y_A)$ et $B(x_B; y_B)$ du plan.\nLe milieu $K$ du segment $[AB]$ a pour coordonnées :',
        },
        {
          type: 'text',
          content:
            '\\[ \\left( \\frac{x_A + x_B}{2} ; \\frac{y_A + y_B}{2} \\right) \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaire',
      content: [
        {
          type: 'text',
          content:
            'Le milieu d’un segment peut être considéré comme un point « moyen » :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'son abscisse est la moyenne des abscisses des extrémités.',
            },
            {
              type: 'text',
              content:
                'son ordonnée est la moyenne des ordonnées des extrémités.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Le milieu $K$ du segment $[AB]$ est défini par $\\overrightarrow{AK} = \\frac{1}{2} \\overrightarrow{AB}$. En écrivant l’égalité des coordonnées de ces vecteurs, on a :',
        },
        {
          type: 'text',
          content:
            '\\[ \\begin{aligned} x_K - x_A &= \\frac{1}{2}(x_B - x_A) \\\\ x_K &= x_A + \\frac{1}{2}(x_B - x_A) \\\\ x_K &= \\frac{1}{2}(x_A + x_B) \\end{aligned} \\]',
        },
        {
          type: 'text',
          content:
            'Et avec le même raisonnement :\\[ y_K = \\frac{1}{2}(y_A + y_B) \\]',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Dans le repère orthonormé $(O ; I ; J)$, on donne les points $A(2 ; 1)$ et $B(-1 ; -3)$.',
        },
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-2.2,-4.2) grid (3.2,2.2); \
            \\draw[->, thin] (-2.2,0) -- (3.2,0); \
            \\draw[->, thin] (0,-4.2) -- (0,2.2); \
            \\node [left] at (0,2.2) {$y$}; \
            \\node [below] at (3.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=above right:\\textcolor{black}{$A$}] (A) at (2,1); \
            \\coordinate[label=below left:\\textcolor{black}{$B$}] (B) at (-1,-3); \
            \\coordinate[label=right:\\textcolor{black}{$K$}] (K) at (0.5,-1); \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\fill (K) circle(0.05); \
            \\draw[red] (A) -- (B); \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Le milieu $K$ de $[AB]$ a pour coordonnées : $\\left( \\frac{2+(-1)}{2} ; \\frac{1+(-3)}{2} \\right)$, soit $(0,5 ; -1)$.',
        },
      ],
    },
  ],
  vector_norm: [
    {
      type: 'simpleText',
      title: 'Calculs dans un repère orhtonormé',
      content: [
        {
          type: 'text',
          content: "Le plan est muni d'un repère orthornormé $(O; I; J)$.",
        },
      ],
    },
    {
      type: 'property',
      subtitle: 'Norme d’un vecteur, distance entre deux points',
      content: [
        {
          type: 'text',
          content:
            'La norme d’un vecteur $\\vec{u} \\begin{pmatrix} x \\\\ y \\end{pmatrix}$, notée $\\|\\vec{u}\\|$, est donnée par :\\[ \\|\\vec{u}\\| = \\sqrt{x^2 + y^2} \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Soit un vecteur $\\vec{u} \\begin{pmatrix} x \\\\ y \\end{pmatrix}$ et le point $M$ tel que $\\vec{u} = \\overrightarrow{OM}$.',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
          \\usetikzlibrary{angles} \
            \\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-4.2,-2.2) grid (2.2,5.2); \
            \\draw[->, thin] (-4.2,0) -- (2.2,0); \
            \\draw[->, thin] (0,-2.2) -- (0,5.2); \
            \\node [left] at (0,5.2) {$y$}; \
            \\node [below] at (2.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate[label=above left:\\textcolor{black}{$M$}] (M) at (-3,4); \
            \\coordinate[label=above left:\\textcolor{black}{$H$}] (H) at (-3,0); \
            \\coordinate[label=above left:\\textcolor{black}{$P$}] (P) at (0,4); \
            \\draw pic[draw=black,fill=purple,angle radius=10,angle eccentricity=1.0] {right angle = O--H--M}; \
            \\fill (M) circle(0.05); \
            \\fill (H) circle(0.05); \
            \\fill (P) circle(0.05); \
            \\draw[->, red] (O) -- (M); \
            \\node[red, right] at ($(O)!0.5!(M)$) {$\\vec{u}$}; \
            \\draw[densely dotted, blue] (H) -- (M); \
            \\node [below, blue] at (H) {$x$}; \
            \\draw[<->, blue] (-3,-0.5) -- (0,-0.5); \
            \\node [below, blue] at (-1.5,-0.5) {$|x|$}; \
            \\draw[densely dotted, green!80!black] (P) -- (M); \
            \\node [right, green!80!black] at (P) {$y$}; \
            \\draw[<->, green!80!black] (0.5,0) -- (0.5,4); \
            \\node [right, green!80!black] at (0.5,2) {$|y|$}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Par définition, la norme de $\\vec{u}$ est égale à la longueur $OM$.',
        },
        {
          type: 'text',
          content:
            'En notant $H$ le point de coordonnées $(x ; 0)$, le triangle $OHM$ est rectangle en $H$.',
        },
        {
          type: 'text',
          content:
            'D’après le théorème de Pythagore, on a : $OM^2 = OH^2 + HM^2$.',
        },
        {
          type: 'text',
          content:
            'Or $OH = |x|$ et $MH = |y|$. Donc $OM^2 = |x|^2 + |y|^2$. Ainsi $OM^2 = x^2 + y^2$.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'On considère deux points $A(x_A ; y_A)$ et $B(x_B ; y_B)$ du plan.',
        },
        {
          type: 'text',
          content:
            'La distance $AB$ est donnée par :\\[ AB = \\sqrt{(x_B - x_A)^2 + (y_B - y_A)^2} \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaires',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Cette formule est obtenue en identifiant la longueur $AB$ à la norme du vecteur $\\overrightarrow{AB}$.',
            },
            {
              type: 'text',
              content:
                'Pour alléger les calculs, il est préférable de calculer d’abord le carré de la distance en utilisant $AB^2 = (x_B - x_A)^2 + (y_B - y_A)^2$, puis d’en prendre la racine carrée pour obtenir $AB$.',
            },
            {
              type: 'text',
              content:
                'L’ordre dans lequel on considère les points $A$ et $B$ dans la formule n’a pas d’importance. On a donc aussi :\\[ AB = \\sqrt{(x_A - x_B)^2 + (y_A - y_B)^2} \\]',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Dans le repère orthonormé $(O ; I ; J)$, on donne les points $A(2 ; 1)$ et $B(-1 ; -3)$.',
        },
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
            \\draw[very thin, style=gray] (-2.2,-4.2) grid (3.2,2.2); \
            \\draw[->, thin] (-2.2,0) -- (3.2,0); \
            \\draw[->, thin] (0,-4.2) -- (0,2.2); \
            \\node [left] at (0,2.2) {$y$}; \
            \\node [below] at (3.2, 0) {$x$}; \
            \\coordinate [label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate[label=below:\\textcolor{black}{$I$}] (I) at (1,0); \
            \\coordinate[label=left:\\textcolor{black}{$J$}] (J) at (0,1); \
            \\coordinate[label=above right:\\textcolor{black}{$A$}] (A) at (2,1); \
            \\coordinate[label=below left:\\textcolor{black}{$B$}] (B) at (-1,-3); \
            \\fill (A) circle(0.05); \
            \\fill (B) circle(0.05); \
            \\draw[red] (A) -- (B); \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'La longueur $AB$ est obtenue par :\\[ \\begin{aligned} AB^2 &= ((-1)-2)^2 + ((-3)-1)^2) \\\\ AB^2 &= (-3)^2 + (-4)^2 \\\\ AB^2 &= 25 \\end{aligned} \\]',
        },
        {
          type: 'text',
          content: 'Donc\\[ AB = \\sqrt{25} = 5 \\]',
        },
      ],
    },
  ],
  determinant: [
    {
      type: 'simpleText',
      title: 'Calculs dans un repère orhtonormé',
      content: [
        {
          type: 'text',
          content: "Le plan est muni d'un repère orthornormé $(O; I; J)$.",
        },
      ],
    },
    {
      type: 'definition',
      subtitle: 'Déterminant de deux vecteurs',
      content: [
        {
          type: 'text',
          content:
            "On considère les deux vecteurs $\\vec{u} \\begin{pmatrix} x \\\\ y \\end{pmatrix}$ et $\\vec{v} \\begin{pmatrix} x' \\\\ y' \\end{pmatrix}$.",
        },
        {
          type: 'text',
          content:
            "Le déterminant de $\\vec{u}$ et $\\vec{v}$, noté $\\text{det}(\\vec{u}, \\vec{v})$ ou $\\begin{vmatrix} x & x' \\\\ y & y' \\end{vmatrix}$, est le nombre :\\[ \\text{det}(\\vec{u}, \\vec{v}) = \\begin{vmatrix} x & x' \\\\ y & y' \\end{vmatrix} = x \\times y' - y \\times x' \\]",
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Commentaires',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'L’écriture avec le tableau des coordonnées permet de mémoriser la formule : ',
            },
          ],
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{matrix,decorations.pathreplacing, calc, positioning,fit} \
          \\begin{document} \
            \\begin{tikzpicture} \
            \\matrix (magic) at (0,0) [matrix of math nodes, left delimiter={|},right delimiter={|}] { x & & x' \\\\ & \\quad & \\\\ y & & y' \\\\ }; \
            \\draw[thick,red,->] (magic-1-1) -- (magic-3-3); \
            \\draw[thick,blue,->] (magic-3-1) -- (magic-1-3); \
            \\node at (3, 0) {\\textcolor{black}{$\\quad = \\quad $}\\textcolor{red}{$x \\times y'$}\\textcolor{black}{$\\quad - \\quad $}\\textcolor{blue}{$y \\times x'$}}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Le déterminant est égal à la différence du produit en croix « descendant » et du produit en croix « montant ».',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                "L’ordre dans lequel on considère les vecteurs $\\vec{u}$ et $\\vec{v}$ a de l’importance. En effet : \\[ \\text{det}(\\vec{v}, \\vec{u}) = \\begin{vmatrix} x' & x \\\\ y' & y \\end{vmatrix} = x' \\times y - y' \\times x = -\\text{det}(\\vec{u}, \\vec{v}) \\]",
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Exemple',
      content: [
        {
          type: 'text',
          content:
            'Dans un repère orthonormé, on considère les vecteurs $\\vec{u} \\begin{pmatrix} 2 \\\\ -1 \\end{pmatrix}$ et $\\vec{v} \\begin{pmatrix} -3 \\\\ 4 \\end{pmatrix}$.',
        },
        {
          type: 'text',
          content:
            'Le déterminant de $\\vec{u}$ et $\\vec{v}$ est :\\[ \\begin{aligned} \\text{det}(\\vec{u}, \\vec{v}) &= \\begin{vmatrix} 2 & -3 \\\\ -1 & 4 \\end{vmatrix} \\\\ &= 2 \\times 4 - (-1) \\times (-3) \\\\ &= 8 - 3 \\\\ &= 5 \\end{aligned} \\]',
        },
      ],
    },
  ],
  colinearity_criteria: [
    {
      type: 'simpleText',
      title: 'Calculs dans un repère orhtonormé',
      content: [
        {
          type: 'text',
          content: "Le plan est muni d'un repère orthornormé $(O; I; J)$.",
        },
      ],
    },
    {
      type: 'property',
      subtitle: 'Critère de colinéarité et applications',
      content: [
        {
          type: 'text',
          content:
            'Deux vecteurs $\\vec{u}$ et $\\vec{v}$ sont colinéaires si, et seulement si :\\[ \\det(\\vec{u}, \\vec{v}) = 0 \\]',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            "On considère deux vecteurs $\\vec{u}\\begin{pmatrix} x \\\\ y \\end{pmatrix}$ et $\\vec{v}\\begin{pmatrix} x' \\\\ y' \\end{pmatrix}$.",
        },
        {
          type: 'text',
          content: 'Les vecteurs $\\vec{u}$ et $\\vec{v}$ sont colinéaires :',
        },

        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'si, et seulement si, leurs coordonnées sont proportionnelles.',
            },
            {
              type: 'text',
              content:
                "si, et seulement si, le tableau $\\begin{array}{|cc|} \\hline x & x' \\\\ y & y' \\\\ \\hline \\end{array}$ est un tableau de proportionnalité.",
            },
            {
              type: 'text',
              content:
                "si, et seulement si, les produits en croix $x \\times y'$ et $y \\times x'$ sont égaux.",
            },
            {
              type: 'text',
              content:
                "si, et seulement si, $\\det(\\vec{u}, \\vec{v}) = x \\times y' - y \\times x' = 0$.",
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      header: 'Avantage',
      content: [
        {
          type: 'text',
          content:
            'L’avantage de ce critère est qu’il ne fait pas intervenir le coefficient de proportionnalité éventuel entre les vecteurs $\\vec{u}$ et $\\vec{v}$.',
        },
      ],
    },
    {
      type: 'simpleText',
      content: [
        {
          type: 'text',
          content:
            'Ce critère de colinéarité est utile pour démontrer, dans un repère orthonormé, le parallélisme de droites et l’alignement de points du plan, grâce aux propriétés suivantes :',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Propriété 1',
      content: [
        {
          type: 'text',
          content: 'Deux droites $(AB)$ et $(CD)$ sont parallèles :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'si, et seulement si, les vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{CD}$ sont colinéaires.',
            },
            {
              type: 'text',
              content:
                'si, et seulement si, $\\det(\\overrightarrow{AB}, \\overrightarrow{CD}) = 0$.',
            },
          ],
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Propriété 2',
      content: [
        {
          type: 'text',
          content: 'Trois points $A, B$ et $C$ sont alignés :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'si, et seulement si, les vecteurs $\\overrightarrow{AB}$ et $\\overrightarrow{AC}$ sont colinéaires.',
            },
            {
              type: 'text',
              content:
                'si, et seulement si, $\\det(\\overrightarrow{AB}, \\overrightarrow{AC}) = 0$.',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content:
            'Dans un repère orthonormé, on considère les points $A(-2;-3)$, $B(6;1)$, $C(-4;-1)$, $D(2;4)$ et $E(2;-2)$. ',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'On a $\\overrightarrow{AB}\\begin{pmatrix} 6 - (-2) \\\\ 1 - (-3) \\end{pmatrix} = \\begin{pmatrix} 8 \\\\ 4 \\end{pmatrix}$ et $\\overrightarrow{CD}\\begin{pmatrix} 2 - (-4) \\\\ 4 - (-1) \\end{pmatrix} = \\begin{pmatrix} 6 \\\\ 5 \\end{pmatrix}$.\n$\\det(\\overrightarrow{AB}, \\overrightarrow{CD}) = 8 \\times 3 - 4 \\times 6 = 0$\nDonc les droites $(AB)$ et $(CD)$ sont parallèles.',
            },
            {
              type: 'text',
              content:
                'On a $\\overrightarrow{AB}\\begin{pmatrix} 6 - (-2) \\\\ 1 - (-3) \\end{pmatrix} = \\begin{pmatrix} 8 \\\\ 4 \\end{pmatrix}$ et $\\overrightarrow{AE}\\begin{pmatrix} 2 - (-2) \\\\ -2 - (-3) \\end{pmatrix} = \\begin{pmatrix} 4 \\\\ 1 \\end{pmatrix}$.\n$\\det(\\overrightarrow{AB}, \\overrightarrow{AE}) = 8 \\times 1 - 4 \\times 4 = -8$, différent de $0$.\nDonc les points $A, B$ et $E$ ne sont pas alignés.',
            },
          ],
        },
      ],
    },
  ],
} as const;
