import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';

import { ChapterNotion, NotionLevel, Statement } from '../constants';

type NotionLevelObject = Record<ChapterNotion, NotionLevel>;

const getAllNotionLevels = (statement: Statement): NotionLevelObject[] => {
  if (statement.type === 'question') {
    return [
      Object.entries(statement.notionLevels).reduce(
        (acc, [notion, value]) => ({ ...acc, [notion]: value.level }),
        {} as NotionLevelObject,
      ),
    ];
  }

  if (statement.type === 'statement') {
    return [];
  }

  return flatMap(
    compact(Object.values(statement)).map(childStatement =>
      getAllNotionLevels(childStatement),
    ),
  );
};

export const getNotionLevelObject = (
  statement: Statement,
): Partial<NotionLevelObject> => {
  const allNotionLevels = getAllNotionLevels(statement);

  return allNotionLevels.reduce<Partial<NotionLevelObject>>(
    (finalAcc, notionLevels) => ({
      ...finalAcc,
      ...Object.entries(notionLevels).reduce<Partial<NotionLevelObject>>(
        (temporaryAcc, [notion, level]) => {
          const previousLevel = finalAcc[notion as ChapterNotion];
          const nextLevel =
            previousLevel === undefined
              ? level
              : Math.max(previousLevel, level);

          return {
            ...temporaryAcc,
            [notion]: nextLevel,
          };
        },
        {},
      ),
    }),
    {},
  );
};

export const getMaxNotionLevel = (statement: Statement): NotionLevel => {
  const notionLevelObject = getNotionLevelObject(statement);

  return Math.max(...compact(Object.values(notionLevelObject)));
};
