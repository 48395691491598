import { FormulaSymbol } from '~/components/Session/Formula/constants';

export const getStringFormula = (symbols: FormulaSymbol[]): string => {
  let lastSymbolIsText = true;
  let stringFormula = '';

  const blockExtremity = '$';

  for (const { symbol, isText = false } of symbols) {
    if (lastSymbolIsText) {
      const startingSpace = stringFormula === '' ? '' : ' ';
      const separator = `${startingSpace}${isText ? '' : blockExtremity}`;
      stringFormula = `${stringFormula}${separator}${symbol}`;
      lastSymbolIsText = isText;
    } else {
      const separator = `${isText ? `${blockExtremity} ` : ''}`;
      stringFormula = `${stringFormula}${separator}${symbol}`;
      lastSymbolIsText = isText;
    }
  }

  if (!lastSymbolIsText) {
    stringFormula = `${stringFormula}${blockExtremity}`;
  }

  return stringFormula;
};
