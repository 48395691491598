import { MathJax } from 'better-react-mathjax';
import get from 'lodash/get';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { ChapterAsObject } from '@skloover/shared';

import { Breakpoint } from '~/lib';

import { Button } from '../../ui';
import { Action, Formula, ObjectFormula, Path, TEXT } from './constants';
import { useSymbols } from './hooks';

type SymbolsProps = {
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  path: Path;
  setPath: Dispatch<SetStateAction<Path>>;
  additionalSymbols: string[];
  objectFormula: ObjectFormula;
  breakpoint: Breakpoint;
  chapterAsObject: ChapterAsObject;
};

const SymbolActions = ({
  symbolActions,
}: {
  symbolActions: Action[];
}): ReactElement => (
  <>
    {symbolActions.map(
      (
        {
          symbolAction: { symbol, onClick },
          lineSpread,
          className,
          id,
          disabled = false,
        },
        symbolIndex,
      ) =>
        symbol === undefined ? (
          <div key={symbolIndex} />
        ) : (
          <Button
            key={symbolIndex}
            className={`grow p-1 row-span-${lineSpread?.toString() ?? '1'} ${className}`}
            size="xs"
            variant="outline"
            disabled={disabled}
            onClick={e => {
              e.preventDefault();
              onClick();
            }}
            id={id}
          >
            <MathJax
              dynamic
              // inline={false}
              className="[&_mjx-container]:no-scrollbar"
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            >{`\\( ${symbol} \\)`}</MathJax>
          </Button>
        ),
    )}
  </>
);

export const Symbols = ({
  ...useSymbolsArguments
}: SymbolsProps): ReactElement => {
  const { breakpoint, objectFormula, path } = useSymbolsArguments;
  const currentFormulaOrUndefined = get(
    objectFormula,
    path.slice(0, -1).join(''),
  ) as unknown as Formula | undefined;
  const isText = currentFormulaOrUndefined?.childFormula?.type === TEXT;

  const {
    digitsActions,
    commonActions,
    chapterActions,
    additionalSymbolsActions,
  } = useSymbols(useSymbolsArguments);

  const getGridColsClassName = (nbCol: number) => {
    switch (nbCol) {
      case 1:
        return 'grid-cols-[auto]';
      case 2:
        return 'grid-cols-[auto_auto]';
      case 3:
        return 'grid-cols-[auto_auto_auto]';
      case 4:
        return 'grid-cols-[auto_auto_auto_auto]';
      case 5:
        return 'grid-cols-[auto_auto_auto_auto_auto]';
      default:
        return '';
    }
  };

  return (
    <div
      className="grid grid-cols-[auto_auto_auto] gap-x-2 md:gap-x-4 gap-y-1 max-w-full"
      id={`joyride-keyboard-${breakpoint}`}
    >
      <div className="grid grid-cols-[auto_auto_auto] gap-1">
        <SymbolActions symbolActions={digitsActions} />
      </div>
      <div className="grid grid-cols-[auto_auto_auto] gap-1">
        <SymbolActions symbolActions={commonActions} />
      </div>
      <div className="grid grid-rows-2 gap-1">
        <div
          className={`grid ${getGridColsClassName(Math.min(Math.max(Math.ceil(additionalSymbolsActions.length / 2), 3), 5))} gap-1`}
        >
          <SymbolActions
            symbolActions={additionalSymbolsActions.map(symbolAction => ({
              symbolAction,
              disabled: isText,
            }))}
          />
        </div>
        <div
          className={`grid ${getGridColsClassName(chapterActions.nbCol)} gap-1`}
        >
          <SymbolActions symbolActions={chapterActions.actions} />
        </div>
      </div>
    </div>
  );
};
