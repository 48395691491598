/* eslint-disable max-lines */
export const geometry = {
  pythagore: [
    {
      type: 'property',
      title: 'Problèmes de longueur et d’angle',
      subtitle: 'Calculer des longueurs',
      content: [
        {
          type: 'text',
          content:
            'Dans tout triangle \\( ABC \\) rectangle en \\( A \\) on a la relation de \\( \\textbf{Pythagore} \\) : \\[ BC^2 = AB^2 + AC^2 \\]',
        },
        {
          type: 'text',
          content:
            'Réciproquement, lorsque les côtés d’un triangle \\( ABC \\) vérifient la relation \\( BC^2 = AB^2 + AC^2 \\) alors le triangle \\( ABC \\) est rectangle en \\( A \\).',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Si \\( BC^2 \\neq AB^2 + AC^2 \\) alors le triangle \\( ABC \\) n’est pas rectangle en \\( A \\).',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document} \
            \\begin{tikzpicture} \
              \\draw (0,0) -- (4,0) -- (0,3) -- cycle; \
              \\draw[fill=purple] (0,0) rectangle (0.3,0.3); \
              \\node[below] at (0,0) {E}; \
              \\node[below] at (4,0) {D}; \
              \\node[above] at (0,3) {F}; \
              \\node[below] at (2,0) {4 cm}; \
              \\node[right] at (2,1.6) {5 cm}; \
            \\end{tikzpicture} \
            \\end{document}',
        },
        {
          type: 'text',
          content:
            'Dans le triangle \\( DEF \\) rectangle en \\( E \\), d’après le théorème de Pythagore, on a :\\[ \\begin{aligned} &DF^{2} = ED^{2} + EF^{2} \\\\ &5^{2} = 4^{2} + EF^{2} \\\\ &25 = 16 + EF^{2} \\\\ &25 - 16 = EF^{2} \\\\ &9 = EF^{2} \\end{aligned} \\]Donc \\( EF = \\sqrt{9} = 3 \\text{ cm} \\)',
        },
      ],
    },
  ],
  thales: [
    {
      type: 'property',
      title: 'Problèmes de longueur et d’angle',
      subtitle: 'Calculer des longueurs',
      content: [
        {
          type: 'text',
          content:
            'On considère l’une des configurations ci-dessous dite de Thalès :',
        },
        {
          type: 'tikz',
          content: `\\begin{document} \
            \\begin{tikzpicture} \
              \\coordinate (S) at (0,0) node[above left] at (S) {$S$}; \
              \\coordinate (D) at (9,-3) node[above right] at (D) {$D$}; \
              \\coordinate (C) at (6,-12) node[below] at (C) {$C$}; \
              \\coordinate (B) at (3,-1) node[above] at (B) {$B$}; \
              \\coordinate (A) at (2,-4) node[below left] at (A) {$A$}; \
              \\draw (S) -- (D) -- (C) -- cycle; \
              \\draw (A) -- (B); \
              \\coordinate (S') at (15,-4) node[above right] at (S') {$S$}; \
              \\coordinate (D') at (21,-6) node[right] at (D') {$D$}; \
              \\coordinate (C') at (17,-12) node[below] at (C') {$C$}; \
              \\coordinate (B') at (12,-3) node[left] at (B') {$B$}; \
              \\coordinate (A') at (14,0) node[above] at (A') {$A$}; \
              \\draw (B') -- (A') -- (C') -- (D') -- cycle; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Si les droites \\((AB)\\) et \\((CD)\\) sont parallèles, alors les longueurs des triangles \\(SAB\\) et \\(SDC\\) sont proportionnelles et on a :\\[ \\frac{SA}{SC} = \\frac{SB}{SD} = \\frac{AB}{CD} \\]',
        },
        {
          type: 'text',
          content:
            'Réciproquement, si les côtés des triangles \\(SAB\\) et \\(SDC\\) sont proportionnels, alors les droites \\((AB)\\) et \\((CD)\\) sont parallèles.',
        },
      ],
    },
  ],
  angles: [
    {
      type: 'property',
      title: 'Calculer des angles',
      content: [
        {
          type: 'text',
          content:
            'Dans un triangle \\(ABC\\) rectangle en \\(A\\), les côtés et les angles sont liés par des \\( \\textbf{relations trigonométriques} \\) :\\[ \\cos \\hat{B} = \\frac{\\color{green}AB}{\\color{red}BC} \\quad \\sin \\hat{B} = \\frac{\\color{blue}AC}{\\color{red}BC} \\quad \\tan \\hat{B} = \\frac{\\color{blue}AC}{\\color{green}AB} \\]',
        },
        {
          type: 'tikz',
          content:
            '\\usetikzlibrary{angles} \
            \\begin{document} \
            \\begin{tikzpicture} \
              \\coordinate [label=below left:$A$] (A) at (0,0); \
              \\coordinate [label=above left:$C$] (C) at (0,3); \
              \\coordinate [label=below right:$B$] (B) at (8,0); \
              \\draw[red] (B) -- (C) node [pos=0.5, above right] {Hypoténuse}; \
              \\draw[green!50!black] (A) -- (B) node [pos=0.5, below] {Côté adjacent à $\\hat{B}$}; \
              \\draw[blue] (A) -- (C) node [pos=0.5, left] {Côté opposé à $\\hat{B}$}; \
              \\draw[fill=purple] (0,0) rectangle (0.3,0.3); \
              \\pic [draw=red, fill=orange, angle radius=25] {angle = C--B--A}; \
            \\end{tikzpicture} \
            \\end{document}',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarques',
      content: [
        {
          type: 'text',
          content:
            'La trigonométrie permet de calculer des longueurs ou des angles.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content:
            '\\begin{document} \
            \\begin{tikzpicture} \
              \\draw (0,0) -- (4,0) -- (0,3) -- cycle; \
              \\draw[fill=purple] (0,0) rectangle (0.3,0.3); \
              \\node[below] at (0,0) {E}; \
              \\node[below] at (4,0) {D}; \
              \\node[above] at (0,3) {F}; \
              \\node[below] at (2,0) {4 cm}; \
              \\node[right] at (2,1.6) {5 cm}; \
            \\end{tikzpicture} \
            \\end{document}',
        },
        {
          type: 'text',
          content:
            'Dans le triangle \\( DEF \\) rectangle en \\( E \\) on a :\\[ \\begin{aligned} &\\cos \\hat{D} = \\frac{ED}{FD} \\\\  &\\cos \\hat{D} = \\frac{4}{5} \\\\ &\\hat{D} = \\cos^{-1} {\\left( \\frac{4}{5} \\right) } = 36,9^\\circ \\end{aligned} \\]',
        },
      ],
    },
  ],
  trigonometry_formula: [
    {
      type: 'property',
      title: 'Calculer des angles',
      content: [
        {
          type: 'text',
          content:
            "Pour tout angle aigu \\(\\alpha\\) d'un triangle rectangle, on a la relation trigonométrique suivante.",
        },
        {
          type: 'text',
          content: '\\[ \\cos^2 \\alpha + \\sin^2 \\alpha = 1 \\]',
        },
      ],
    },
  ],
  triangle_angles_sum: [
    {
      type: 'property',
      title: 'Calculer des angles',
      content: [
        {
          type: 'text',
          content:
            'Dans un triangle, la somme des angles fait \\( 180^\\circ \\).',
        },
      ],
    },
  ],
  internal_alternate_angle: [
    {
      type: 'property',
      title: 'Calculer des angles',
      content: [
        {
          type: 'text',
          content:
            'Deux droites parallèles et une sécante engendrent des angles alternes-internes et correspondants, de même mesure :',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{angles} \
            \\begin{document} \
            \\begin{tikzpicture} \
              \\coordinate (A) at (0,0) node[left] at (A) {$D$}; \
              \\coordinate (B) at (10,0); \
              \\coordinate (D) at (0,5) node[left] at (D) {$D'$}; \
              \\coordinate (C) at (10,5); \
              \\coordinate (E) at (3.6,-1); \
              \\coordinate (F) at (4,0); \
              \\coordinate (G) at (6,5); \
              \\coordinate (H) at (6.4,6); \
              \\draw[thick] (A) -- (B); \
              \\draw[thick] (C) -- (D); \
              \\draw[thick, orange] (E) -- (H) node[pos=0.5, text=black, font=\\large, left] {Angles alternes-internes}; \
              \\pic [fill=green!50!black, opacity=0.5, angle radius=20] {angle = D--G--F}; \
              \\pic [fill=green!50!black, opacity=0.5, angle radius=20] {angle = B--F--G}; \
              \\coordinate (I) at (12,0); \\coordinate (J) at (22,0); \
              \\coordinate (L) at (12,5); \
              \\coordinate (K) at (22,5); \
              \\coordinate (M) at (15.6,-1); \
              \\coordinate (N) at (16,0); \
              \\coordinate (O) at (18,5); \
              \\coordinate (P) at (18.4,6); \
              \\draw[thick] (I) -- (J); \
              \\draw[thick] (K) -- (L); \
              \\draw[thick, orange] (M) -- (P) node[pos=0.5, text=black, font=\\large, left] {Angles correspondants}; \
              \\pic [fill=green!50!black, opacity=0.5, angle radius=20] {angle = L--O--N}; \
              \\pic [fill=green!50!black, opacity=0.5, angle radius=20] {angle = I--N--M}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
  ],
  quadrilateral: [
    {
      type: 'simpleText',
      title: 'Configurations usuelles du plan',
      subtitle: 'Quadrilatères particuliers',
      content: [
        {
          type: 'text',
          content:
            'On peut reconnaître les quadrilatères particuliers à l’aide des critères suivants :',
        },
      ],
    },
    {
      type: 'definition',
      header: 'Le parallélogramme',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below left:\\textcolor{black}{A}] (A) at (0,0); \
            \\coordinate[label=below right:\\textcolor{black}{B}] (B) at (5,0); \
            \\coordinate[label=above right:\\textcolor{black}{C}] (C) at (7,3); \
            \\coordinate[label=above left:\\textcolor{black}{D}] (D) at (2,3); \
            \\draw[thick, orange, fill=orange!30] (A) -- (B) -- (C) -- (D) -- cycle; \
            \\coordinate[label=above:\\textcolor{black}{O}] (O) at (3.5,1.5); \
            \\draw[thick] (B) -- (D); \
            \\draw[thick] (A) -- (C); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content: `\\begin{array}{|c|c|c|c|}
          \\hline
            \\text{Les côtés} &
            \\text{Les diagonales} &
            \\text{Les symétries}
          \\\\ \\hline
            \\begin{aligned} &\\bullet \\ (AB)//(DC) \\text{ et } (AD)//(BC) \\\\ &\\bullet \\ (AB)//(DC) \\text{ et } AB = DC \\end{aligned} &
            \\begin{aligned} &\\text{Les diagonales } [AC] \\text{ et } [BD] \\ \\\\ &\\text{ont le même milieu } O. \\end{aligned} &
            O \\text{ est le centre de symétrie.}
          \\\\ \\hline
          \\end{array}`,
        },
      ],
    },
    {
      type: 'definition',
      header: 'Le rectangle',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below left:\\textcolor{black}{A}] (A) at (0,0); \
            \\coordinate[label=below right:\\textcolor{black}{B}] (B) at (5,0); \
            \\coordinate[label=above right:\\textcolor{black}{C}] (C) at (5,3); \
            \\coordinate[label=above left:\\textcolor{black}{D}] (D) at (0,3); \
            \\draw[thick, orange, fill=orange!30] (A) -- (B) -- (C) -- (D) -- cycle; \
            \\coordinate[label=above:\\textcolor{black}{O}] (O) at (2.5,1.5); \
            \\draw[thick] (B) -- (D); \
            \\draw[thick] (A) -- (C); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content: `Le rectangle est un parallélogramme, qui a en plus les propriétés suivantes :`,
        },
        {
          type: 'text',
          content: `\\begin{array}{|c|c|c|c|}
          \\hline
            \\text{Les côtés} &
            \\text{Les diagonales} &
            \\text{Les symétries}
          \\\\ \\hline
            (AB) \\perp (AD) &
            AC = BD &
            \\begin{aligned} 
              &\\text{Les médiatrices de } \\\\ 
              &[AD] \\text{ et de } [AB] \\\\ 
              &\\text{ sont des axes de symétrie.} 
            \\end{aligned}
          \\\\ \\hline
          \\end{array}`,
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{decorations, decorations.markings} \
          \\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below left:\\textcolor{black}{A}] (A) at (0,0); \
            \\coordinate[label=below right:\\textcolor{black}{B}] (B) at (5,0); \
            \\coordinate[label=above right:\\textcolor{black}{C}] (C) at (5,3); \
            \\coordinate[label=above left:\\textcolor{black}{D}] (D) at (0,3); \
            \\draw[thick, orange, fill=orange!30] (A) -- (B) -- (C) -- (D) -- cycle; \
            \\coordinate[label=above:\\textcolor{black}{O}] (O) at (2.5,1.5); \
            \\draw[thick] (B) -- (D); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (O) edge[decorate] (D); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (O) edge[decorate] (C); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (O) edge[decorate] (A); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (O) edge[decorate] (B); \
            \\draw[thick] (A) -- (C); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Le quadrilatère $ABCD$ est un parallélogramme car ses diagonales $[AC]$ et $[BD]$ se coupent en leur milieu. Ses diagonales ont la même longueur donc c’est un rectangle.',
        },
      ],
    },
    {
      type: 'definition',
      header: 'Le losange',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below:\\textcolor{black}{A}] (A) at (0,0); \
            \\coordinate[label=right:\\textcolor{black}{B}] (B) at (5,3); \
            \\coordinate[label=above:\\textcolor{black}{C}] (C) at (0,6); \
            \\coordinate[label=left:\\textcolor{black}{D}] (D) at (-5,3); \
            \\draw[thick, orange, fill=orange!30] (A) -- (B) -- (C) -- (D) -- cycle; \
            \\coordinate[label=above right:\\textcolor{black}{O}] (O) at (0,3); \
            \\draw[thick] (B) -- (D); \
            \\draw[thick] (A) -- (C); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content: `Le losange est un parallélogramme, qui a en plus les propriétés suivantes :`,
        },
        {
          type: 'text',
          content: `\\begin{array}{|c|c|c|c|}
          \\hline
            \\text{Les côtés} &
            \\text{Les diagonales} &
            \\text{Les symétries}
          \\\\ \\hline
            AB = AD &
            (AC) \\perp (BD) &
            \\begin{aligned} 
              &\\text{Les diagonales } \\\\ 
              &\\text{sont des axes de} \\\\ 
              &\\text{symétrie.} 
            \\end{aligned}
          \\\\ \\hline
          \\end{array}`,
        },
      ],
    },
    {
      type: 'definition',
      header: 'Le carré',
      content: [
        {
          type: 'tikz',
          content: `\\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below left:\\textcolor{black}{A}] (A) at (0,0); \
            \\coordinate[label=below right:\\textcolor{black}{B}] (B) at (5,0); \
            \\coordinate[label=above right:\\textcolor{black}{C}] (C) at (5,5); \
            \\coordinate[label=above left:\\textcolor{black}{D}] (D) at (0,5); \
            \\draw[thick, orange, fill=orange!30] (A) -- (B) -- (C) -- (D) -- cycle; \
            \\coordinate[label=above:\\textcolor{black}{O}] (O) at (2.5,2.5); \
            \\draw[thick] (B) -- (D); \
            \\draw[thick] (A) -- (C); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content: `Le carré est un parallélogramme, qui a en plus les propriétés suivantes :`,
        },
        {
          type: 'text',
          content: `\\begin{array}{|c|c|c|c|}
          \\hline
            \\text{Les côtés} &
            \\text{Les diagonales} &
            \\text{Les symétries}
          \\\\ \\hline
            (AB) \\perp (AD) \\text{ et } AB = AD &
            AC = BD \\text{ et } (AC) \\perp (BD) &
            \\begin{aligned} 
              &\\text{Les médiatrices de [AD] et [AB]} \\\\ 
              &\\text{ainsi que les diagonales} \\\\ 
              &\\text{sont des axes desymétrie.} 
            \\end{aligned}
          \\\\ \\hline
          \\end{array}`,
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{decorations, decorations.markings} \
          \\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below left:\\textcolor{black}{A}] (A) at (0,0); \
            \\coordinate[label=below right:\\textcolor{black}{B}] (B) at (5,0); \
            \\coordinate[label=above right:\\textcolor{black}{C}] (C) at (5,5); \
            \\coordinate[label=above left:\\textcolor{black}{D}] (D) at (0,5); \
            \\draw[thick, orange, fill=orange!30] (A) -- (B) -- (C) -- (D) -- cycle; \
            \\coordinate[label=above:\\textcolor{black}{O}] (O) at (2.5,2.5); \
            \\draw[fill=purple,rotate around={-45:(2.5,2.5)}] (2.5,2.5) rectangle (3,3); \
            \\draw[fill=purple] (0,5) rectangle (0.5,4.5); \
            \\draw[thick] (B) -- (D); \
            \\draw[thick] (A) -- (C); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (O) edge[decorate] (D); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (O) edge[decorate] (C); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (O) edge[decorate] (A); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (O) edge[decorate] (B); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (A) edge[decorate] (B); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (B) edge[decorate] (C); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (C) edge[decorate] (D); \
            \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$\\parallel$};}}] (D) edge[decorate] (A); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content: 'Un carré est à la fois un rectangle et un losange.',
        },
      ],
    },
  ],
  circles: [
    {
      type: 'definition',
      title: 'Configurations usuelles du plan',
      subtitle: 'Cercles et angles',
      content: [
        {
          type: 'text',
          content:
            '$O$ est un point et $r$ un nombre réel strictement positif.\nL’ensemble des points $M$ du plan vérifiant $OM = r$ est le cercle de centre $O$ et de rayon $r$.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Vocabulaire',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{angles} \
          \\begin{document} \
          \\begin{tikzpicture} \
            \\coordinate [label=below:\\textcolor{black}{$O$}] (O) at (0,0); \
            \\coordinate[label=right:\\textcolor{black}{$B$}] (B) at (5,0); \
            \\coordinate[label=left:\\textcolor{black}{$B'$}] (B') at (-5,0); \
            \\coordinate[label=above right:\\textcolor{black}{$A$}] (A) at (60:5); \
            \\pic [draw=red, fill=orange, angle radius=25] {angle = B--B'--A}; \
            \\pic [draw=red, fill=orange, angle radius=25] {angle = B--O--A}; \
            \\draw[draw=red, thick] (0,0) circle (5); \
            \\draw (B') -- (B); \
            \\draw (O) -- (A); \
            \\draw (A) -- (B); \
            \\draw (B') -- (A); \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content: '$[OA]$ est un rayon',
            },
            {
              type: 'text',
              content: '$[BB’]$ est un diamètre',
            },
            {
              type: 'text',
              content: '$\\widehat{BB’A}$ est un angle inscrit',
            },
            {
              type: 'text',
              content: '$\\widehat{BOA}$ est un angle au centre',
            },
            {
              type: 'text',
              content: '$[AB]$ est une corde',
            },
            {
              type: 'text',
              content: '$\\overset{\\frown}{AB}$ est un arc',
            },
          ],
        },
      ],
    },
  ],
  inscribed_angle: [
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Lorsqu’un angle inscrit $\\alpha$ intercepte le même arc qu’un angle au centre $\\beta$ alors :\\[\\beta = 2 \\alpha \\]',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{angles,quotes} \
            \\begin{document} \
            \\begin{tikzpicture} \
              \\draw[thick] (0,0) circle (2); \
              \\draw[thick,color=red] (2,0) arc[start angle=0,end angle=30,radius=2]; \
              \\coordinate [label=below:$O$] (O) at (0,0); \
              \\coordinate (A) at (2,0); \
              \\coordinate (B) at (30:2); \
              \\coordinate (C) at (120:2); \
              \\draw pic["$\\alpha$"{below=3, right=1},draw=red,fill=orange,angle radius=20,angle eccentricity=1] {angle = A--C--B}; \
              \\draw pic["$\\beta$"{above=2, right=1},draw=red,fill=orange,angle radius=20,angle eccentricity=1] {angle = A--O--B}; \
              \\draw (O) -- (A); \
              \\draw (O) -- (B); \
              \\draw (C) -- (A); \
              \\draw (C) -- (B); \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{angles,quotes} \
            \\begin{document} \
            \\begin{tikzpicture} \
              \\draw[thick] (0,0) circle (2); \
              \\coordinate[label=below left:\\textcolor{black}{$O$}] (O) at (0,0); \
              \\coordinate[label=above right:\\textcolor{black}{$C$}] (C) at (60:2); \
              \\coordinate[label=right:\\textcolor{black}{$B$}] (B) at (0:2); \
              \\coordinate[label=below right:\\textcolor{black}{$D$}] (D) at (-70:2); \
              \\draw pic ["$60^{\\circ}$",draw=red, fill=orange, angle radius=20,angle eccentricity=1.5] {angle=B--O--C}; \
              \\draw pic [draw=red, fill=orange, angle radius=20] {angle=B--D--C}; \
              \\draw (O) -- (C); \
              \\draw (O) -- (B); \
              \\draw (D) -- (C); \
              \\draw (D) -- (B); \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Puisque l’angle en centre $\\widehat{BOC}$ intercepte le même arc $\\overset{\\frown}{CB}$ que l’angle inscrit $\\widehat{BDC}$ alors :',
        },
        {
          type: 'text',
          content: '\\[ \\widehat{BDC} = \\frac{1}{2} \\widehat{BOC} = 30° \\]',
        },
      ],
    },
  ],
  tangent: [
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'La tangente à un cercle $C$ de centre $O$ en un point $M$ est la droite passant par $M$ et perpendiculaire au rayon $[OM]$.\nElle coupe le cercle $C$ en l’unique point $M$.',
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
          \\usetikzlibrary{math} \
          \\usetikzlibrary{angles,quotes} \
          \\begin{document} \
          \\begin{tikzpicture} \
            \\def\\r{1.5} \
            \\def\\ang{25} \
            \\def\\q{\\r/sin(\\ang)} \
            \\coordinate (O) at (0,0); \
            \\coordinate (Q) at ({\\q},0); \
            \\coordinate (M) at (90-\\ang:\\r); \
            \\draw pic[draw=black,fill=purple,angle radius=10,angle eccentricity=1.0] {right angle = O--M--Q}; \
            \\draw[thick] (O) circle(\\r); \
            \\draw[red,thick] ($(Q)!-0.2!(M)$) -- ($(Q)!1.3!(M)$); \
            \\draw ($(O)!0!(M)$) -- ($(O)!1!(M)$); \
            \\fill (O) circle(0.05) node[below right] {O}; \
            \\fill (M) circle(0.05) node[above=3,right=4] {M}; \
          \\end{tikzpicture} \
          \\end{document}`,
        },
        {
          type: 'text',
          content: 'Tangeante au cercle en $M$',
        },
      ],
    },
  ],
  mediator: [
    {
      type: 'definition',
      title: 'Droites remarquables du triangle',
      subtitle: 'Médiatrices',
      content: [
        {
          type: 'text',
          content:
            'La médiatrice d’un segment est l’ensemble des points du plan équidistants des extrémités de ce segment.',
        },
        {
          type: 'text',
          content:
            'C’est la droite passant par le milieu et perpendiculaire à ce segment.',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Les trois médiatrices d’un triangle sont concourantes en un point \\( O \\) appelé centre du \\( \\textbf{cercle circonscrit au triangle} \\).',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\usetikzlibrary{decorations, decorations.markings} \
            \\usetikzlibrary{angles,quotes} \
            \\begin{document} \
            \\begin{tikzpicture} \
                \\draw[thick] (0,0) circle [radius=3]; \
                \\coordinate [label=left:$A$] (A) at (210:3); \
                \\coordinate [label=right:$B$] (B) at (330:3); \
                \\coordinate [label=above:$C$] (C) at (110:3); \
                \\coordinate [label=below:$O$] (O) at (0,0); \
                \\coordinate (C') at ($(A)!0.5!(B)$); \
                \\coordinate (B') at ($(A)!0.5!(C)$); \
                \\coordinate (A') at ($(C)!0.5!(B)$); \
                \\draw[green!80!black] ($(O)!-4!(A')$) -- ($(O)!4!(A')$); \
                \\node[left,green!80!black] at ($(O)!-4!(A')$) {Médiatrice de {[BC]}}; \
                \\draw[green!80!black] ($(O)!-2!(B')$) -- ($(O)!2!(B')$); \
                \\node[left,green!80!black] at ($(O)!2!(B')$) {Médiatrice de {[AC]}}; \
                \\draw[green!80!black] ($(O)!-3!(C')$) -- ($(O)!3!(C')$); \
                \\node[right,green!80!black] at ($(O)!3!(C')$) {Médiatrice de {[AB]}}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = O--A'--C}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = O--C'--B}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = O--B'--A}; \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$||$};}}] (A') edge[decorate] (B); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$||$};}}] (A') edge[decorate] (C); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (B') edge[decorate] (C); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (B') edge[decorate] (A); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|||$};}}] (C') edge[decorate] (A); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|||$};}}] (C') edge[decorate] (B); \
                \\fill (O) circle(0.05); \
                \\draw (A) -- (B) -- (C) -- cycle; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Remarque',
      content: [
        {
          type: 'text',
          content:
            'Lorsque le triangle est rectangle, le centre du cercle circonscrit est le milieu de l’hypoténuse.',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\usetikzlibrary{decorations, decorations.markings} \
            \\usetikzlibrary{angles,quotes} \
            \\begin{document} \
            \\begin{tikzpicture} \
                \\draw[thick] (0,0) circle [radius=3]; \
                \\coordinate [label=left:$A$] (A) at (180:3); \
                \\coordinate [label=right:$B$] (B) at (360:3); \
                \\coordinate [label=above:$C$] (C) at (110:3); \
                \\coordinate [label=above left:$O$] (O) at (0,0); \
                \\coordinate (C') at (0,-1); \
                \\coordinate (B') at ($(A)!0.5!(C)$); \
                \\coordinate (A') at ($(C)!0.5!(B)$); \
                \\draw[green!80!black] ($(O)!-2!(A')$) -- ($(O)!2!(A')$); \
                \\node[left,green!80!black] at ($(O)!-2!(A')$) {Médiatrice de {[BC]}}; \
                \\draw[green!80!black] ($(O)!-2!(B')$) -- ($(O)!2!(B')$); \
                \\node[left,green!80!black] at ($(O)!2!(B')$) {Médiatrice de {[AC]}}; \
                \\draw[green!80!black] ($(O)!-4!(C')$) -- ($(O)!4!(C')$); \
                \\node[right,green!80!black] at ($(O)!4!(C')$) {Médiatrice de {[AB]}}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = O--A'--C}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = C'--O--B}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = O--B'--A}; \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$||$};}}] (A') edge[decorate] (B); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$||$};}}] (A') edge[decorate] (C); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (B') edge[decorate] (C); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (B') edge[decorate] (A); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|||$};}}] (O) edge[decorate] (A); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|||$};}}] (O) edge[decorate] (B); \
                \\fill (O) circle(0.05); \
                \\draw (A) -- (B) -- (C) -- cycle; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
      ],
    },
  ],
  triangle_lines: [
    {
      type: 'definition',
      title: 'Droites remarquables du triangle',
      subtitle: 'Autres droites remarquables du triangle',
      content: [
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'Une hauteur est une droite passant par un sommet du triangle et perpendiculaire au côté opposé à ce sommet.',
            },
            {
              type: 'text',
              content:
                'Une médiane est une droite passant par un sommet du triangle et par le milieu du côté opposé à ce sommet.',
            },
            {
              type: 'text',
              content:
                'La bissectrice d’un angle est la demi-droite passant par le sommet de cet angle et qui le coupe en deux angles égaux.',
            },
          ],
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content: 'Dans un triangle :',
        },
        {
          type: 'list',
          content: [
            {
              type: 'text',
              content:
                'les trois hauteurs sont concourantes en un point appelé $\\textbf{orthocentre du triangle}$',
            },
            {
              type: 'text',
              content:
                'les trois médianes en un point appelé $\\textbf{centre de gravité du triangle}$',
            },
            {
              type: 'text',
              content:
                'les trois bissectrices en un point appelé $\\textbf{centre du cercle inscrit au triangle}$',
            },
          ],
        },
      ],
    },
    {
      type: 'example',
      content: [
        {
          type: 'text',
          content: 'Hauteur :',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\usetikzlibrary{decorations, decorations.markings} \
            \\usetikzlibrary{angles,quotes} \
            \\begin{document} \
            \\begin{tikzpicture} \
                \\coordinate [label=above left:$A$] (A) at (0,0); \
                \\coordinate [label=above right:$B$] (B) at (4,0); \
                \\coordinate [label=above left:$C$] (C) at (1,3); \
                \\coordinate (C') at ($(A)!(C)!(B)$); \
                \\coordinate (B') at ($(A)!(B)!(C)$); \
                \\coordinate (A') at ($(B)!(A)!(C)$); \
                \\draw[green!80!black] ($(C)!-0.5!(C')$) -- ($(C)!1.5!(C')$); \
                \\draw[green!80!black] ($(A)!-0.5!(A')$) -- ($(A)!1.5!(A')$); \
                \\draw[green!80!black] ($(B)!-0.5!(B')$) -- ($(B)!1.5!(B')$); \
                \\node[left,green!80!black] at ($(C)!-0.5!(C')$) {Hauteur issue de $C$}; \
                \\node[left,green!80!black] at ($(A)!-0.5!(A')$) {Hauteur issue de $A$}; \
                \\node[below,green!80!black] at ($(B)!-0.5!(B')$) {Hauteur issue de $B$}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = C--C'--B}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = A--A'--B}; \
                \\draw pic[draw=black,fill=purple,angle radius=5,angle eccentricity=1.0] {right angle = B--B'--A}; \
                \\coordinate (H) at (intersection of C--C' and A--A'); \
                \\node [red, below right] at (H) {$H$}; \
                \\fill [red] (H) circle(0.05); \
                \\draw (A) -- (B) -- (C) -- cycle; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content: 'Médiane :',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc} \
            \\usetikzlibrary{decorations, decorations.markings} \
            \\usetikzlibrary{angles,quotes} \
            \\begin{document} \
            \\begin{tikzpicture} \
                \\coordinate [label=above left:$A$] (A) at (0,0); \
                \\coordinate [label=above right:$B$] (B) at (4,0); \
                \\coordinate [label=above right:$C$] (C) at (1,3); \
                \\coordinate (C') at ($(A)!0.5!(B)$); \
                \\coordinate (B') at ($(A)!0.5!(C)$); \
                \\coordinate (A') at ($(B)!0.5!(C)$); \
                \\draw[green!80!black] ($(C)!-0.5!(C')$) -- ($(C)!1.5!(C')$); \
                \\draw[green!80!black] ($(A)!-0.5!(A')$) -- ($(A)!1.5!(A')$); \
                \\draw[green!80!black] ($(B)!-0.5!(B')$) -- ($(B)!1.5!(B')$); \
                \\node[left,green!80!black] at ($(C)!-0.5!(C')$) {Médiane issue de $C$}; \
                \\node[left,green!80!black] at ($(A)!-0.5!(A')$) {Médiane issue de $A$}; \
                \\node[below,green!80!black] at ($(B)!-0.5!(B')$) {Médiane issue de $B$}; \
                \\coordinate (G) at (intersection of C--C' and A--A'); \
                \\node [red, below right] at (G) {$G$}; \
                \\fill [red] (G) circle(0.05); \
                \\fill (C') circle(0.05); \
                \\fill (A') circle(0.05); \
                \\fill (B') circle(0.05); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$||$};}}] (A') edge[decorate] (B); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$||$};}}] (A') edge[decorate] (C); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (B') edge[decorate] (C); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|$};}}] (B') edge[decorate] (A); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|||$};}}] (C') edge[decorate] (A); \
                \\draw[decoration={markings, mark=at position .5  with {\\node[transform shape, red] {$|||$};}}] (C') edge[decorate] (B); \
                \\draw (A) -- (B) -- (C) -- cycle; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content: 'Bissectrice :',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{calc,intersections} \
            \\begin{document} \
            \\begin{tikzpicture} \
              \\coordinate (I) at (0,0) node[above right, red] at (I) {$I$}; \
              \\coordinate (E) at (-2.5,-1); \
              \\coordinate (F) at (3.5,-1); \
              \\path[name path=EF] (E)--(F); \
              \\coordinate (A') at (30:1); \
              \\coordinate (B') at (160:1); \
              \\coordinate (C') at (280:1); \
              \\draw (I) circle (1); \
              \\path[name path=A'I] (A')--($(A')!4!(I)$); \
              \\path[name path=B'I] (B')--($(B')!4!(I)$); \
              \\path [name intersections={of=EF and A'I,by=A}] node[below] at (A) {$A$}; \
              \\path [name intersections={of=EF and B'I,by=B}] node[below] at (B) {$B$}; \
              \\path[name path=BG] (B) -- +(140:5); \
              \\path[name path=AH] (A) -- +(60:5); \
              \\path [name intersections={of=BG and AH,by=C}] node[above right] at (C) {$C$}; \
              \\draw (A)--(B)--(C)--cycle; \
              \\draw[green!80!black] ($(I)!-1!(C)$) -- ($(I)!1.5!(C)$); \
              \\draw[green!80!black] ($(I)!-1!(A)$) -- ($(I)!1.5!(A)$); \
              \\draw[green!80!black] ($(I)!-1!(B)$) -- ($(I)!1.5!(B)$); \
              \\fill[red] (I) circle(0.05); \
              \\node[left,green!80!black] at ($(I)!-1!(B)$) {Bissectrice de $\\hat{B}$}; \
              \\node[right,green!80!black] at ($(I)!1.5!(C)$) {Bissectrice de $\\hat{C}$}; \
              \\node[right,green!80!black] at ($(I)!-1!(A)$) {Bissectrice de $\\hat{A}$}; \
              \\end{tikzpicture} \
              \\end{document}`,
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'Dans \\(ABC\\) isocèle en \\(A\\), la médiane et la hauteur issues de \\(A\\), la bissectrice de \\(\\hat{A}\\) et la médiatrice de \\([BC]\\) sont confondues.',
        },
      ],
    },
  ],
  orthogonal_projection: [
    {
      type: 'simpleText',
      title: 'Droites remarquables du triangle',
      subtitle: 'Projeté orthogonal d’un point sur une droite',
      content: [
        {
          type: 'text',
          content: 'Soit \\( D \\) une droite du plan et \\( A \\) un point.',
        },
      ],
    },
    {
      type: 'definition',
      content: [
        {
          type: 'text',
          content:
            'On appelle projeté orthogonal de \\( A \\) sur \\( D \\) le point d’intersection de la droite \\( D \\) avec la perpendiculaire à \\( D \\) passant par \\( A \\).',
        },
      ],
    },
    {
      type: 'property',
      content: [
        {
          type: 'text',
          content:
            'La distance du point \\( A \\) à la droite \\( D \\) est la plus petite distance séparant un point de \\( D \\) avec \\( A \\).\nElle est égale à \\( AH \\) où \\( H \\) est le projeté orthogonal du point \\( A \\) sur \\( D \\).',
        },
        {
          type: 'tikz',
          content: `\\usetikzlibrary{angles,quotes,calc,intersections} \
            \\begin{document} \
            \\begin{tikzpicture} \
              \\coordinate (A) at (0,3) node[above] at (A) {$A$}; \
              \\coordinate (H) at (0,0) node[below] at (H) {$H$}; \
              \\coordinate (M) at (2,0) node[below] at (M) {$M$}; \
              \\coordinate (D) at (6,0) node[below right] at (D) {$D$}; \
              \\coordinate (E) at (-3,0); \
              \\draw[name path=ED] (E)--(D); \
              \\draw[name path=AH, dashed] (A)--(H); \
              \\draw[name path=AM] (A)--(M); \
              \\draw pic[draw=black,fill=purple,angle radius=10,angle eccentricity=1.0] {right angle = A--H--M}; \
            \\end{tikzpicture} \
            \\end{document}`,
        },
        {
          type: 'text',
          content:
            'Sur le graphique ci-dessus, $H$ est le projeté orthogonal de $A$ sur $D$.',
        },
      ],
    },
    {
      type: 'additionalNote',
      header: 'Démonstration',
      content: [
        {
          type: 'text',
          content:
            'Notons \\( d \\) la distance entre \\( A \\) et \\( D \\). Soit \\( M \\) un point de \\( D \\), distinct de \\( H \\).\nLe triangle \\( AMH \\) est rectangle en \\( H \\).\nGrâce au théorème de Pythagore, on peut affirmer que l’hypoténuse \\([AM]\\) est le plus grand des côtés du triangle \\( AMH \\). Donc \\( AM > AH \\).\nAinsi, la plus petite distance séparant \\( A \\) d’un point de \\( D \\) est égale à \\( AH \\).\nOn en déduit que \\( AH = d \\).',
        },
      ],
    },
  ],
} as const;
