import { Step } from 'react-joyride';

import { useBreakpoint } from '../hooks';
import { useIntl } from '../utils';

export const useSteps = (): Step[] => {
  const t = useIntl();
  const breakpoint = useBreakpoint();

  return [
    { step: 'chapters', spotlightClicks: false },
    { step: `problem-trigger-${breakpoint}`, spotlightClicks: true },
    { step: `problem-${breakpoint}`, spotlightClicks: true },
    { step: 'chapter', spotlightClicks: false },
    { step: `hints-trigger-${breakpoint}`, spotlightClicks: true },
    { step: `hints-${breakpoint}`, spotlightClicks: true },
    {
      step: `chat-${breakpoint}`,
      spotlightClicks: true,
    },
    { step: `formula-${breakpoint}`, spotlightClicks: true },
    { step: `keyboard-${breakpoint}`, spotlightClicks: true },
    { step: `new-line-${breakpoint}`, spotlightClicks: false },
    { step: `text-key-${breakpoint}`, spotlightClicks: true },
    { step: `next-${breakpoint}`, spotlightClicks: false },
  ].map(({ step, spotlightClicks }) => ({
    title: t.formatMessage({ id: `joyride.steps.${step}.title` }),
    content: t.formatMessage({ id: `joyride.steps.${step}.content` }),
    target: `#joyride-${step}`,
    disableBeacon: true,
    spotlightClicks,
    disableOverlayClose: true,
  }));
};
