import { useState } from 'react';
import { CallBackProps, Status, STATUS } from 'react-joyride';

export const useJoyride = (): {
  startTutorial: () => void;
  runTutorial: boolean;
  handleJoyrideCallback: (data: CallBackProps) => void;
} => {
  const [runTutorial, setRunTutorial] = useState(false);
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as Status[]).includes(status)) {
      setRunTutorial(false);
    }
  };
  const startTutorial = () => setRunTutorial(true);

  return { startTutorial, runTutorial, handleJoyrideCallback };
};
