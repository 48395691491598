import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getCurrentQuestionStatement, ProblemSolving } from '@skloover/shared';

import { Chat, Layout, NotionBadges, Problem } from '~/components';
import { SessionLayout } from '~/components/Session/SessionLayout';
import { AppRoute, trpc } from '~/lib';

export const SessionContent = ({
  sessionId,
  problemId,
  problemSolving,
  isRefetching,
}: {
  sessionId: string;
  problemId: string;
  problemSolving: ProblemSolving;
  isRefetching: boolean;
}): ReactElement => {
  const currentQuestionStatement = getCurrentQuestionStatement(problemSolving);

  const [problemTab, setProblemTab] = useState('statement');
  const onProblemTabChange = (value: string) => {
    setProblemTab(value);
  };

  const [blurredHintIndex, setBlurredHintIndex] = useState(0);
  const incrementBlurredHintIndex = () =>
    setBlurredHintIndex(blurredHintIndex + 1);
  const [showAnswer, setShowAnswer] = useState(false);
  const resetBlurred = () => {
    setBlurredHintIndex(0);
    setShowAnswer(false);
  };

  const isLastQuestion = currentQuestionStatement.next === undefined;
  const chatHistory = useMemo(
    () =>
      isRefetching
        ? []
        : problemSolving.chats[problemSolving.currentQuestionPath],
    [isRefetching, problemSolving.chats, problemSolving.currentQuestionPath],
  );

  const [chat, setChat] = useState(chatHistory);
  useEffect(() => {
    setChat(chatHistory);
  }, [chatHistory]);

  return (
    <div className="h-full flex flex-col space-y-4">
      <NotionBadges problemSolving={problemSolving} />
      <SessionLayout
        problem={
          <Problem
            problemStatement={problemSolving.problem.problemStatement}
            statement={problemSolving.problem.problemStatement.statement}
            currentQuestionStatement={currentQuestionStatement}
            currentQuestionPath={problemSolving.currentQuestionPath}
            tab={problemTab}
            onTabChange={onProblemTabChange}
            blurredIndex={blurredHintIndex}
            incrementBlurredIndex={incrementBlurredHintIndex}
            showAnswer={showAnswer}
            setShowAnswer={setShowAnswer}
            enableShowAnswer={(chat ?? []).length >= 2}
            breakpoint="sm"
          />
        }
        chat={
          <Chat
            problemId={problemId}
            sessionId={sessionId}
            chapterAsObject={problemSolving.chapterAsObject}
            isLastQuestion={isLastQuestion}
            questionStatement={currentQuestionStatement}
            onProblemTabChange={onProblemTabChange}
            resetBlurred={resetBlurred}
            breakpoint="sm"
            chat={chat}
            setChat={setChat}
          />
        }
      />
    </div>
  );
};

export const Session = (): ReactElement => {
  const { sessionId } = useParams() as { sessionId: string };
  const navigate = useNavigate();
  const { data, error, isRefetching } =
    trpc.problemSolvingGet.useQuery(sessionId);

  useEffect(() => {
    if (error !== null) {
      navigate(AppRoute.Error);
    }
  }, [error, navigate, isRefetching]);

  return (
    <Layout showTutorial>
      {data === undefined ? (
        <div className="h-full flex flex-col">
          <SessionLayout problem={<></>} chat={<></>} />
        </div>
      ) : (
        // @ts-ignore type infinite
        <SessionContent
          sessionId={sessionId}
          problemId={data.problemId}
          problemSolving={data.problemSolving}
          isRefetching={isRefetching}
        />
      )}
    </Layout>
  );
};
