import { MathJax } from 'better-react-mathjax';
import get from 'lodash/get';
import { Check } from 'lucide-react';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import { LeftParenthesis, RightParenthesis } from '~/components/icons';
import { Button } from '~/components/ui';

import {
  ObjectFormula,
  ParenthesisFormula,
  Path,
  selectedElementClassName,
} from './constants';
import { Formula } from './Formula';
import { validateFormula } from './validateFormula';

interface BracketsProps {
  objectFormula: ObjectFormula;
  path: Path;
  functionPath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  functionSymbol: string;
}

export const Function = ({
  objectFormula,
  functionPath,
  path,
  setPath,
  setObjectFormula,
  functionSymbol,
}: BracketsProps): ReactElement => {
  const { inFormula } = get(
    objectFormula,
    `${functionPath}.childFormula`,
  ) as unknown as ParenthesisFormula;
  const editing = functionPath === path.slice(0, -1).join('.');
  const validateVisibility =
    inFormula.parentFormula.mainFormula.symbols.length === 0
      ? 'invisible'
      : 'visible';
  const selected = editing;
  const dynamicClassName = `${
    editing && 'hover:cursor-pointer hover:border-primary bg-gray-100'
  } ${selected ? 'border-primary' : 'border-transparent'}`;

  const [height, setHeight] = useState<number>(0);
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      setHeight(node.getBoundingClientRect().height);
    });
    resizeObserver.observe(node);
  }, []);

  return (
    <div className="flex items-center gap-1">
      <div className="flex items-center">
        <div className="text-primary">
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <MathJax dynamic>{`\\( ${functionSymbol} \\)`}</MathJax>
        </div>
        <div className="flex items-center gap-1">
          <LeftParenthesis
            // Safari does not support aspect-ratio
            height={height}
          />
          <div
            className={`${selectedElementClassName} ${dynamicClassName}`}
            role="presentation"
            ref={ref}
          >
            <Formula
              objectFormula={objectFormula}
              path={path}
              setPath={setPath}
              formulaPath={`${functionPath}.childFormula.inFormula`}
              mathJaxContentPlaceholder="abc"
              setObjectFormula={setObjectFormula}
            />
          </div>
          <RightParenthesis height={height} />
        </div>
      </div>
      {editing && (
        <Button
          variant="ghost"
          className={`text-green-700 hover:text-green-700 ${validateVisibility}`}
          size="icon"
          onClick={() => validateFormula({ setPath, path, setObjectFormula })}
        >
          <Check className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
