import { arrayOfAll } from '../types/arrayOfAll';

export const PROBLEM_LEVELS = ['easy', 'medium', 'hard'] as const;

export type ProblemLevel = (typeof PROBLEM_LEVELS)[number];

export const ORDERED_PROBLEM_LEVELS = arrayOfAll<ProblemLevel>()([
  'hard',
  'medium',
  'easy',
]);

export enum NotionLevel {
  Easy = 1,
  Medium,
  Hard,
}
