import get from 'lodash/get';
import { Check } from 'lucide-react';
import { Dispatch, ReactElement, SetStateAction } from 'react';

import { Button } from '~/components/ui';

import {
  ObjectFormula,
  OverlineFormula,
  Path,
  selectedElementClassName,
} from './constants';
import { Formula } from './Formula';
import { validateFormula } from './validateFormula';

interface OverlineProps {
  objectFormula: ObjectFormula;
  path: Path;
  overlinePath: string;
  setPath: Dispatch<SetStateAction<Path>>;
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
}

export const Overline = ({
  objectFormula,
  overlinePath,
  path,
  setPath,
  setObjectFormula,
}: OverlineProps): ReactElement => {
  const { inFormula } = get(
    objectFormula,
    `${overlinePath}.childFormula`,
  ) as unknown as OverlineFormula;
  const editing = overlinePath === path.slice(0, -1).join('.');
  const validateVisibility =
    inFormula.parentFormula.mainFormula.symbols.length === 0
      ? 'invisible'
      : 'visible';
  const selected = editing;
  const dynamicClassName = `${
    editing && 'hover:cursor-pointer hover:border-primary bg-gray-100'
  } ${selected ? 'border-primary' : 'border-transparent'}`;

  return (
    <div className="flex items-center gap-1">
      <div className="pt-1 border-solid border-primary border-t">
        <div
          className={`${selectedElementClassName} ${dynamicClassName}`}
          role="presentation"
        >
          <Formula
            objectFormula={objectFormula}
            path={path}
            setPath={setPath}
            formulaPath={`${overlinePath}.childFormula.inFormula`}
            mathJaxContentPlaceholder="abc"
            setObjectFormula={setObjectFormula}
          />
        </div>
      </div>
      {editing && (
        <Button
          variant="ghost"
          className={`text-green-700 hover:text-green-700 ${validateVisibility}`}
          size="icon"
          onClick={() => validateFormula({ setPath, path, setObjectFormula })}
        >
          <Check className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
