/* eslint-disable complexity */
import { MathJax } from 'better-react-mathjax';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { ReactElement, useCallback } from 'react';

import { ProblemStatement, Statement as StatementType } from '@skloover/shared';

import { Breakpoint } from '~/lib';

import { Answer } from './Answer';
import { Tikz } from './Tikz';

const MAX_QUESTIONS_LOOP = 50;

export const Statement = ({
  problemStatement,
  statement,
  parentStep,
  step,
  currentQuestionPath,
  breakpoint,
}: {
  problemStatement: ProblemStatement;
  statement: StatementType;
  parentStep: string;
  step: string;
  currentQuestionPath: string;
  breakpoint: Breakpoint;
}): ReactElement => {
  const getShowAnswerPaths = useCallback((): string[] => {
    const paths = [];
    let currentLoopPath = problemStatement.first;
    let index = 0;

    while (
      currentLoopPath !== currentQuestionPath &&
      index <= MAX_QUESTIONS_LOOP
    ) {
      paths.push(currentLoopPath);

      const currentLoopQuestion = get(
        problemStatement.statement,
        currentLoopPath,
      );

      if (
        currentLoopQuestion === undefined ||
        currentLoopQuestion.type !== 'question' ||
        currentLoopQuestion.next === undefined
      ) {
        return paths;
      }

      currentLoopPath = currentLoopQuestion.next;

      index++;
    }

    return paths;
  }, [problemStatement, currentQuestionPath]);

  const showAnswerPaths = getShowAnswerPaths();

  if (statement.type === 'statement') {
    const prefix = parentStep !== '' ? `${parentStep}. ` : '';

    return (
      <>
        {statement.text
          .filter(
            ({ hideStatement }) =>
              hideStatement === undefined || !hideStatement,
          )
          .map((content, index) => {
            const { type, value } = content;

            switch (type) {
              case 'text':
                return (
                  <div
                    className="mr-4 text-justify whitespace-pre-wrap"
                    key={index}
                  >
                    <MathJax dynamic>
                      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                      {`${index === 0 ? prefix : ''}${value}`}
                    </MathJax>
                  </div>
                );
              case 'image': {
                return (
                  <div
                    className="flex items-center justify-around mb-2"
                    key={index}
                  >
                    <img
                      className="mr-4 max-w-1/2 shadow rounded"
                      src={value}
                      alt={value}
                    />
                  </div>
                );
              }
              case 'tikz': {
                const { packages } = content;

                return <Tikz tikz={value} key={index} packages={packages} />;
              }
            }
          })}
      </>
    );
  }

  if (statement.type === 'question') {
    const path = parentStep === '' ? `${step}` : `${parentStep}.${step}`;

    const boldClassName = currentQuestionPath === path ? 'font-bold' : '';

    const question = get(problemStatement.statement, path);

    return (
      <div
        className={`mr-4 ml-4 text-justify whitespace-pre-wrap ${boldClassName} flex items-center justify-between space-x-2`}
      >
        <div className="max-w-full overflow-auto">
          {statement.text
            .filter(
              ({ hideStatement }) =>
                hideStatement === undefined || !hideStatement,
            )
            .map(({ type, value }, index) => {
              const prefix = index === 0 ? `${step}. ` : '';

              if (type === 'text') {
                return (
                  <MathJax
                    dynamic
                    key={index}
                    // className="flex"
                    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                  >{`${prefix}${value}`}</MathJax>
                );
              }

              return (
                <div
                  className="flex items-center justify-around mb-2"
                  key={index}
                >
                  <img
                    className="mr-4 max-w-1/2 shadow rounded"
                    src={value}
                    alt={value}
                  />
                </div>
              );
            })}
        </div>
        {showAnswerPaths.includes(path) && question?.type === 'question' && (
          <Answer content={question.solutions[0].answers[0]} />
        )}
      </div>
    );
  }

  return (
    <div
      className="ml-4 flex flex-col gap-2"
      id={`joyride-problem-${breakpoint}`}
    >
      {compact(Object.values(statement)).find(
        ({ type }) => type === 'statement',
      ) === undefined && (
        <div className="mr-4 text-justify whitespace-pre-wrap">
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {`${step !== '' ? `${step}. ` : ''}`}
        </div>
      )}
      {Object.entries(statement).map(
        ([childKey, childStatement]) =>
          childStatement !== undefined && (
            <Statement
              step={childKey}
              statement={childStatement}
              parentStep={step}
              key={childKey}
              currentQuestionPath={currentQuestionPath}
              problemStatement={problemStatement}
              breakpoint={breakpoint}
            />
          ),
      )}
    </div>
  );
};
