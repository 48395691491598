/* eslint-disable max-lines */
export const variations_extremums = {
  growth: [],
  variation_table: [],
  min_max: [],
  growth_rate: [],
  variation: [],
  square_function: [],
  inverse_function: [],
  cubic_function: [],
  square_root_function: [],
} as const;
