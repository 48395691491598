import { MathJax } from 'better-react-mathjax';
import { ReactElement } from 'react';

import { getStringFormula } from '~/lib/utils/getStringFormula';

import { FormulaSymbol } from './constants';

type BasicFormulaProps = {
  symbols: FormulaSymbol[];
  mathJaxContentPlaceholder?: string;
};

export const BasicFormula = ({
  symbols,
  mathJaxContentPlaceholder,
}: BasicFormulaProps): ReactElement => {
  const displayedSymbols =
    mathJaxContentPlaceholder !== undefined
      ? [{ symbol: mathJaxContentPlaceholder }]
      : symbols;

  return displayedSymbols.length === 0 ? (
    <></>
  ) : (
    <MathJax
      dynamic
      className={`${symbols.length === 0 && 'text-muted-foreground'} whitespace-nowrap`}
    >
      {getStringFormula(displayedSymbols)}
    </MathJax>
  );
};
