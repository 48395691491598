import get from 'lodash/get';

import { QuestionStatement } from '../constants';
import { ProblemSolving } from '../types';

export const getCurrentQuestionStatement = (
  problemSolving: ProblemSolving,
): QuestionStatement => {
  const currentQuestionStatement = get(
    problemSolving.problem.problemStatement.statement,
    problemSolving.currentQuestionPath,
  );

  if (currentQuestionStatement?.type !== 'question') {
    throw new Error('Can not find current question');
  }

  return currentQuestionStatement;
};
