/* eslint-disable complexity */

/* eslint-disable max-lines */
import get from 'lodash/get';
import set from 'lodash/set';
import { Dispatch, SetStateAction } from 'react';

import { Chapter, ChapterAsObject } from '@skloover/shared';

import { Breakpoint } from '~/lib';

import {
  Action,
  BRACKETS,
  COORDINATES,
  COSINUS,
  DETERMINANT,
  EMPTY_BRACKETS_FORMULA,
  EMPTY_COORDINATES_FORMULA,
  EMPTY_DETERMINANT_FORMULA,
  EMPTY_EXPOSANT_FORMULA,
  EMPTY_FRACTION_FORMULA,
  EMPTY_FUNCTION_FORMULA,
  EMPTY_INDEX_FORMULA,
  EMPTY_OVERLINE_FORMULA,
  EMPTY_PARENTHESIS_FORMULA,
  EMPTY_SQUARE_ROOT_FORMULA,
  EMPTY_TEXT_FORMULA,
  EMPTY_VECTOR_FORMULA,
  EXPONENTIAL,
  EXPOSANT,
  Formula,
  FormulaSymbol,
  FormulaType,
  FRACTION,
  INDEX,
  LOGARITHM,
  ObjectFormula,
  OVERLINE,
  PARENTHESIS,
  Path,
  SINUS,
  SQUARE_ROOT,
  SymbolAction,
  TANGENT,
  TEXT,
  VECTOR,
} from './constants';

type Argument = {
  setObjectFormula: Dispatch<SetStateAction<ObjectFormula>>;
  path: Path;
  setPath: Dispatch<SetStateAction<Path>>;
  additionalSymbols: string[];
  objectFormula: ObjectFormula;
  chapterAsObject: ChapterAsObject;
  breakpoint: Breakpoint;
};

const getEmptyFormula = ({
  currentFormula,
  formulaType,
}: {
  currentFormula: Formula;
  formulaType: FormulaType;
}) => {
  if (currentFormula.childFormula !== undefined) {
    return { pathSuffix: undefined, emptyFormula: undefined };
  }

  switch (formulaType) {
    case undefined:
      return { pathSuffix: undefined, emptyFormula: undefined };
    case FRACTION:
      return { pathSuffix: 'upFormula', emptyFormula: EMPTY_FRACTION_FORMULA };
    case INDEX:
      return {
        pathSuffix: 'leftFormula',
        emptyFormula: EMPTY_INDEX_FORMULA,
      };
    case EXPOSANT:
      return {
        pathSuffix: 'leftFormula',
        emptyFormula: EMPTY_EXPOSANT_FORMULA,
      };
    case COSINUS:
    case SINUS:
    case TANGENT:
    case LOGARITHM:
    case EXPONENTIAL:
      return {
        pathSuffix: 'inFormula',
        emptyFormula: EMPTY_FUNCTION_FORMULA(formulaType),
      };
    case SQUARE_ROOT:
      return {
        pathSuffix: 'inFormula',
        emptyFormula: EMPTY_SQUARE_ROOT_FORMULA,
      };
    case PARENTHESIS:
      return {
        pathSuffix: 'inFormula',
        emptyFormula: EMPTY_PARENTHESIS_FORMULA,
      };
    case BRACKETS:
      return {
        pathSuffix: 'inFormula',
        emptyFormula: EMPTY_BRACKETS_FORMULA,
      };
    case OVERLINE:
      return { pathSuffix: 'inFormula', emptyFormula: EMPTY_OVERLINE_FORMULA };
    case TEXT:
      return { pathSuffix: 'inFormula', emptyFormula: EMPTY_TEXT_FORMULA };
    case COORDINATES:
      return {
        pathSuffix: 'upFormula',
        emptyFormula: EMPTY_COORDINATES_FORMULA,
      };
    case VECTOR:
      return {
        pathSuffix: 'inFormula',
        emptyFormula: EMPTY_VECTOR_FORMULA,
      };
    case DETERMINANT:
      return {
        pathSuffix: 'inFormula',
        emptyFormula: EMPTY_DETERMINANT_FORMULA,
      };
  }
};

export const useSymbols = ({
  setObjectFormula,
  path,
  setPath,
  additionalSymbols,
  objectFormula,
  chapterAsObject,
  breakpoint,
}: Argument): {
  digitsActions: Action[];
  commonActions: Action[];
  chapterActions: { actions: Action[]; nbCol: number };
  additionalSymbolsActions: SymbolAction[];
} => {
  const appendSymbol = (symbol?: string): void => {
    if (symbol === undefined) {
      return;
    }

    setObjectFormula(prevObjectFormula => {
      const cloneObjectFormula = JSON.parse(
        JSON.stringify(prevObjectFormula),
      ) as ObjectFormula;

      const symbols = get(
        prevObjectFormula,
        `${path.join('.')}.parentFormula.mainFormula.symbols`,
      ) as unknown as FormulaSymbol[];

      set(
        cloneObjectFormula,
        `${path.join('.')}.parentFormula.mainFormula.symbols`,
        symbols.concat({ symbol }),
      );

      return cloneObjectFormula;
    });
  };

  const appendChildFormula = (formulaType: FormulaType): void => {
    const currentFormula = get(
      objectFormula,
      `${path.join('.')}`,
    ) as unknown as Formula;

    const { pathSuffix, emptyFormula } = getEmptyFormula({
      currentFormula,
      formulaType,
    });

    setObjectFormula(prevObjectFormula => {
      const cloneObjectFormula = JSON.parse(
        JSON.stringify(prevObjectFormula),
      ) as ObjectFormula;
      set(
        cloneObjectFormula,
        `${path.join('.')}.childFormula`,
        emptyFormula === undefined
          ? undefined
          : JSON.parse(JSON.stringify(emptyFormula)),
      );

      return cloneObjectFormula;
    });
    setPath(prevPath =>
      pathSuffix === undefined
        ? prevPath
        : prevPath.concat(`childFormula.${pathSuffix}`),
    );
  };

  const removeLastItem = (): void => {
    const currentFormula = get(
      objectFormula,
      `${path.join('.')}`,
    ) as unknown as Formula;

    // Remove last symbol
    if (currentFormula.parentFormula.mainFormula.symbols.length > 0) {
      setObjectFormula(prevObjectFormula => {
        const cloneObjectFormula = JSON.parse(
          JSON.stringify(prevObjectFormula),
        ) as ObjectFormula;
        set(
          cloneObjectFormula,
          `${path.join('.')}.parentFormula.mainFormula.symbols`,
          currentFormula.parentFormula.mainFormula.symbols.slice(0, -1),
        );

        return cloneObjectFormula;
      });

      return;
    }

    // Remove child formula
    if (path.length > 1) {
      const parentPath = path.slice(0, -1).join('.');
      setObjectFormula(prevObjectFormula => {
        const cloneObjectFormula = JSON.parse(
          JSON.stringify(prevObjectFormula),
        ) as ObjectFormula;
        set(cloneObjectFormula, `${parentPath}.childFormula`, undefined);

        return cloneObjectFormula;
      });
      setPath(prevPath => prevPath.slice(0, -1));

      return;
    }

    // Remove empty main formula
    if (path.length === 1 && objectFormula.formulas.length > 1) {
      setObjectFormula(prevObjectFormula => {
        const cloneObjectFormula = JSON.parse(
          JSON.stringify(prevObjectFormula),
        ) as ObjectFormula;
        set(
          cloneObjectFormula,
          'formulas',
          objectFormula.formulas.slice(0, -1),
        );

        return cloneObjectFormula;
      });

      setPath([`formulas[${objectFormula.formulas.length - 2}]`]);

      return;
    }
  };

  const currentFormulaOrUndefined = get(
    objectFormula,
    path.slice(0, -1).join(''),
  ) as unknown as Formula | undefined;

  const isText = currentFormulaOrUndefined?.childFormula?.type === TEXT;
  const isNested = path.length > 1;

  const digitsActions = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ',',
    '0',
    ';',
  ].map(symbol => ({
    symbolAction: {
      symbol,
      onClick: () => appendSymbol(symbol),
    },
    disabled: isText,
  }));

  const additionalSymbolsActions = additionalSymbols.map(symbol => ({
    symbol,
    onClick: () => appendSymbol(symbol),
    disabled: isText,
  }));

  const getOperationAction = (symbol: string): SymbolAction => ({
    symbol,
    onClick: () => appendSymbol(symbol),
  });
  const getGroupAction = ({
    symbol,
    formulaType,
  }: {
    symbol: string;
    formulaType: FormulaType;
  }): SymbolAction => ({
    symbol,
    onClick: () => appendChildFormula(formulaType),
  });
  const backAction = { symbol: '\\leftarrow', onClick: removeLastItem };
  const textAction = {
    symbol: '\\text{"T"}',
    onClick: () => appendChildFormula(TEXT),
  };

  const commonActions = [
    {
      symbolAction: textAction,
      id: `joyride-text-key-${breakpoint}`,
      disabled: isText || isNested,
    },
    { symbolAction: getOperationAction('+') },
    {
      symbolAction: getGroupAction({
        symbol: '(abc)',
        formulaType: PARENTHESIS,
      }),
      disabled: isText,
    },
    {
      symbolAction: backAction,
      className: 'bg-destructive',
    },
    { symbolAction: getOperationAction('-'), disabled: isText },
    {
      symbolAction: getGroupAction({
        symbol: '\\left\\{ abc \\right\\}',
        formulaType: BRACKETS,
      }),
      disabled: isText,
    },
    {
      symbolAction: getGroupAction({
        symbol: '\\dfrac{a}{b}',
        formulaType: FRACTION,
      }),
      className: 'h-full',
      lineSpread: 2,
      disabled: isText,
    },
    { symbolAction: getOperationAction(' \\times ') },
    {
      symbolAction: getGroupAction({
        symbol: '(x)^{y}',
        formulaType: EXPOSANT,
      }),
      disabled: isText,
    },
    { symbolAction: getOperationAction('='), disabled: isText },
    {
      symbolAction: getGroupAction({
        symbol: '\\sqrt{x}',
        formulaType: SQUARE_ROOT,
      }),
      disabled: isText,
    },
  ];

  const ACTIONS_BY_CHAPTER: Record<
    Chapter,
    { actions: Action[]; nbCol: number }
  > = {
    real_numbers: {
      actions: [
        { symbolAction: getOperationAction(' \\neq '), disabled: isText },
        {
          symbolAction: getOperationAction(' +\\infty '),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\leq '), disabled: isText },
        { symbolAction: getOperationAction(' < '), disabled: isText },
        {
          symbolAction: getOperationAction(' \\approx '),
          disabled: isText,
        },
        {
          symbolAction: getOperationAction(' -\\infty '),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\geq '), disabled: isText },
        { symbolAction: getOperationAction(' > '), disabled: isText },
      ],
      nbCol: 4,
    },
    integers: {
      actions: [
        { symbolAction: getOperationAction(' \\neq '), disabled: isText },
        { symbolAction: getOperationAction(' \\leq '), disabled: isText },
        { symbolAction: getOperationAction(' < '), disabled: isText },
        {
          symbolAction: getOperationAction(' \\approx '),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\geq '), disabled: isText },
        { symbolAction: getOperationAction(' > '), disabled: isText },
      ],
      nbCol: 3,
    },
    calculation: {
      actions: [
        { symbolAction: getOperationAction(' \\neq '), disabled: isText },
        { symbolAction: getOperationAction(' \\leq '), disabled: isText },
        { symbolAction: getOperationAction(' < '), disabled: isText },
        {
          symbolAction: getOperationAction(' \\approx '),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\geq '), disabled: isText },
        { symbolAction: getOperationAction(' > '), disabled: isText },
      ],
      nbCol: 3,
    },
    geometry: {
      actions: [
        { symbolAction: getOperationAction(' \\pi '), disabled: isText },
        {
          symbolAction: getGroupAction({
            symbol: '\\cos',
            formulaType: COSINUS,
          }),
          disabled: isText,
        },
        {
          symbolAction: getGroupAction({
            symbol: '\\tan',
            formulaType: TANGENT,
          }),
          disabled: isText,
        },
        { symbolAction: getOperationAction('^{\\circ}'), disabled: isText },
        {
          symbolAction: getGroupAction({
            symbol: '\\sin',
            formulaType: SINUS,
          }),
          disabled: isText,
        },
      ],
      nbCol: 3,
    },
    vectors: {
      actions: [
        {
          symbolAction: getGroupAction({
            symbol: '\\begin{pmatrix} x \\\\ y \\end{pmatrix}',
            formulaType: COORDINATES,
          }),
          lineSpread: 2,
          className: 'h-full',
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\vec{0} '), disabled: isText },
        // {
        //   symbolAction: getGroupAction({
        //     symbol: '\\overrightarrow{AB}',
        //     formulaType: VECTOR,
        //   }),
        //   lineSpread: 2,
        //   className: 'h-full',
        //   disabled: isText,
        // },
      ],
      nbCol: 3,
    },
    coordinate_system: {
      actions: [
        {
          symbolAction: getGroupAction({
            symbol: '\\begin{pmatrix} x \\\\ y \\end{pmatrix}',
            formulaType: COORDINATES,
          }),
          lineSpread: 2,
          className: 'h-full',
          disabled: isText,
        },
        {
          symbolAction: getGroupAction({
            symbol: '\\det',
            formulaType: DETERMINANT,
          }),
          disabled: isText,
        },
      ],
      nbCol: 3,
    },
    plan_line_and_system: {
      actions: [
        {
          symbolAction: getGroupAction({
            symbol: '\\begin{pmatrix} x \\\\ y \\end{pmatrix}',
            formulaType: COORDINATES,
          }),
          lineSpread: 2,
          className: 'h-full',
          disabled: isText,
        },
        {
          symbolAction: getGroupAction({
            symbol: '\\det',
            formulaType: DETERMINANT,
          }),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\vec{0} '), disabled: isText },
      ],
      nbCol: 2,
    },
    probability: {
      actions: [
        { symbolAction: getOperationAction(' \\cup '), disabled: isText },
        { symbolAction: getOperationAction(' \\subset '), disabled: isText },
        {
          symbolAction: getGroupAction({
            symbol: 'x_{i}',
            formulaType: INDEX,
          }),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\cap '), disabled: isText },
        { symbolAction: getOperationAction(' \\in '), disabled: isText },
        {
          symbolAction: getGroupAction({
            symbol: '\\overline{abc}',
            formulaType: OVERLINE,
          }),
          disabled: isText,
        },
      ],
      nbCol: 3,
    },
    statistics: {
      actions: [
        { symbolAction: getOperationAction(' \\% '), disabled: isText },
        { symbolAction: getOperationAction(' \\cup '), disabled: isText },
        {
          symbolAction: getGroupAction({
            symbol: 'x_{i}',
            formulaType: INDEX,
          }),
          disabled: isText,
        },
        { symbolAction: getOperationAction(' \\subset '), disabled: isText },
        { symbolAction: getOperationAction(' \\in '), disabled: isText },
        { symbolAction: getOperationAction(' \\cap '), disabled: isText },
        {
          symbolAction: getGroupAction({
            symbol: '\\overline{abc}',
            formulaType: OVERLINE,
          }),
          disabled: isText,
        },
      ],
      nbCol: 4,
    },
  };

  // const exponentialAction = {
  //   symbolAction: getGroupAction({
  //     symbol: '\\exp',
  //     formulaType: EXPONENTIAL,
  //   }),
  // };
  // const logarithmAction = {
  //   symbolAction: getGroupAction({
  //     symbol: '\\ln',
  //     formulaType: LOGARITHM,
  //   }),
  // };

  const chapterActions = ACTIONS_BY_CHAPTER[chapterAsObject.chapter];

  return {
    digitsActions,
    commonActions,
    chapterActions,
    additionalSymbolsActions,
  };
};
